<template>
  <div :class="headerClasses" id="header">
    <button
      class="mx-6 my-3"
      v-html="getToggleIcon()"
      v-on:click="handleUpdateStatusSideBar"
    ></button>
    <div class="flex items-center justify-center gap-3 mx-6 my-3">
      <button v-on:click="handleUpdateLanguage" :class="`flex justify-center items-center gap-[5px] ${loadLanguage && 'cursor-wait'}`">
        <span v-html="ChangeLanguageIcon" ></span>
        <span class="flex justify-center items-center" v-if="loadLanguage">
          <a-spin :indicator="indicator" />
        </span>
      </button>
      <img
        id="imgAcc"
        alt=""
        class="w-9 h-9 rounded-full object-cover cursor-pointer"
        v-on:click="openMenu"
        :src="getAvatarShopByName(selectedStore.store_avatar)"
        @error="(e) => handleImageError(e, 'shop')"
      />
    </div>
    <div class="popup" v-if="menu">
      <button class="btn" v-on:click="handleNavigateSetting">
        <span v-html="SettingIcon"></span>
        <span>{{ $t("common.setting") }}</span>
      </button>
      <span class="line"></span>
      <button class="btn" v-on:click="handleNavigateLogout">
        <span v-html="BackLoginIcon"></span>
        <span>{{ $t("common.logout") }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { computed, h, onMounted, onUnmounted, ref } from "vue";
import VueCookies from "vue-cookies";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  BackLoginIcon,
  ChangeLanguageIcon,
  SettingIcon,
  ToggleCloseIcon,
  ToggleOpenIcon,
} from "../../assets/constant/svg_exports";
import { getAvatarShopByName, handleImageError } from "../../helpers";
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '25px',
    color: 'rgba(239, 104, 32, 1)'
  },
  spin: true,
});
const loadLanguage = ref(false);
const router = useRouter();
const store = useStore();
const statusCollapse = computed(() => store.state.collapseSidebar);
const statusHide = computed(() => store.state.hideSidebar);
const menu = ref(false);
const selectedStore = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const handleUpdateStatusSideBar = () => {
  const hide_sidebar_action_open = localStorage.getItem("hide_sidebar_action_open");
  if (statusHide.value || hide_sidebar_action_open === "true") {
    localStorage.setItem(
      "hide_sidebar_action_open",
      hide_sidebar_action_open === "true" ? "false" : "true"
    );
    store.dispatch("setStatusHideSidebar");
  } else {
    store.dispatch("setStatusCollapseSidebar");
  }
};
const handleUpdateLanguage = () => {
  if(!loadLanguage.value){
    loadLanguage.value = true;
    const nowLanguage = localStorage.getItem("lang") || "ja";
    localStorage.setItem("lang", nowLanguage === "en" ? "ja" : "en");
    setTimeout(() => {
      loadLanguage.value = false;
      window.location.reload();
    }, 2000);
  }
};

const handleNavigateSetting = () => {
  router.push(`/setting`);
};

const handleNavigateLogout = () => {
  localStorage.clear();
  VueCookies.remove("access_token");
  router.replace(`/login`);
};

const openMenu = () => {
  menu.value = !menu.value;
};

const getToggleIcon = () => {
  if (
    statusHide.value === false &&
    localStorage.getItem("hide_sidebar_action_open") === "true"
  ) {
    return ToggleCloseIcon;
  }
  return statusCollapse.value ? ToggleOpenIcon : ToggleCloseIcon;
};

const headerClasses = computed(() => {
  return [
    "fixed top-0 flex items-center justify-between h-15 bg-white z-20",
    statusHide.value
      ? "w-[100%] left-[0]"
      : statusCollapse.value
      ? "left-20 w-[calc(100%-80px)] max-w-[calc(100%-80px)]"
      : "left-60 w-[calc(100%-240px)] max-w-[calc(100%-240px)]",
  ];
});

function handleClickOutside(event) {
  const element = document.getElementById("imgAcc");
  if (element && !element.contains(event.target)) {
    menu.value = false;
  }
}

onMounted(() => {
  window.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  window.removeEventListener("click", handleClickOutside);
});
</script>

<style lang="scss">
#header {
  border-bottom: 1px solid #d0d5dd;
  #imgAcc {
    border: 1px solid #d0d5dd;
  }
  .popup {
    width: 250px;
    height: 105px;
    padding: 16px;
    position: fixed;
    top: 54px;
    right: 24px;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .line {
      position: relative;
      width: 100%;
      height: 1px;
      background-color: rgba(234, 236, 240, 1);
    }
    .btn {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-family: "NotoSansJP_400";
      span {
        path {
          fill: var(--color-text-gray) !important;
        }
      }
      &:hover {
        span {
          color: var(--primary-color-dark);
          path {
            fill: var(--primary-color-dark) !important;
          }
        }
      }
    }
  }
}
</style>
