<template>
  <div class="info-user">
    <div class="title-action h-[44px] flex justify-between items-center">
      <h3 class="title notosans600 text-[20px]">
        {{ userDetails?.nickName }}
      </h3>
      <div class="action flex gap-[8px]">
        <button
          class="change-pwd h-[44px] fill-primary-text-white"
          v-on:click="handleTurnOnPasswordForm"
        >
          <span v-html="LockIcon"></span>
          {{ $t("common.change_pwd") }}
        </button>
        <button
          class="remove-account h-[44px] outline-red"
          v-on:click="handleDeleteAccount"
        >
          <span v-html="RemoveIcon" />
          {{ $t("common.account_deletion") }}
        </button>
      </div>
    </div>
    <div v-if="!passwordFormTurnOn" class="inputs mt-[20px]">
      <div class="input-box-only-df flex justify-start items-center">
        <label
          class="w-[200px] notosans600"
          style="color: rgba(102, 112, 133, 1)"
          for=""
          >{{ $t("common.logo") }}</label
        >
        <div
          class="groups-avatar-button flex justify-center items-center gap-[16px] pb-[20px]"
        >
          <div
            class="box-avatar relative cursor-pointer"
            v-on:click="handleUploadAvatar"
            :style="{
              pointerEvents: loadingUploadImage ? 'none' : 'auto',
              userSelect: loadingUploadImage ? 'none' : 'auto',
              opacity: loadingUploadImage ? '.5' : '1',
            }"
          >
            <img
              id="input-avatar-fake"
              class="avatar w-[80px] h-[80px] rounded-full object-cover"
              :src="getAvatarUserByName(avatar)"
              @error="handleImageError"
              alt=""
              style="
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
              "
            />
            <span
              v-if="!loadingUploadImage"
              class="camera-icon"
              v-html="CameraIcon"
            ></span>
            <a-spin v-if="loadingUploadImage" class="camera-icon !bg-white" />
            <input
              type="file"
              class="hidden"
              id="avatar_detail"
              accept="image/*"
              v-on:change="handleFileInputChange"
            />
          </div>
          <button
            class="button outline-red cursor-pointer bg-white h-[42px] flex justify-center items-center gap-[6px]"
            v-on:click="handleDeleteImage"
          >
            <span v-html="TrashFilledIcon"></span>
            <span>{{ $t("common.delete_image") }}</span>
          </button>
        </div>
      </div>
      <div class="input-box">
        <label for="">{{ $t("common.user_id") }}</label>
        <input
          :class="{ error: !inputCheck.user_id }"
          type="text"
          id="detail_user_id"
          v-model="user_id"
        />
      </div>
      <div class="input-box">
        <label for="">{{ $t("common.name") }}</label>
        <input
          :class="{ error: !inputCheck.name }"
          type="text"
          id="detail_name"
          v-model="name"
        />
      </div>
      <div class="w-full ml-[208px] flex items-center">
        <button
          :class="`fill-primary-text-white flex justify-center items-center mt-[20px] gap-[5px] min-w-[150px] min-h-[48px] ${
            processUpdateUser && `disabled`
          }`"
          v-on:click="handleSubmit"
        >
          <span v-html="SaveIcon"></span>
          <span>{{ $t("common.save") }}</span>
          <span class="flex justify-center items-center" v-if="processUpdateUser">
            <a-spin :indicator="indicator" />
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="gift-statistic w-1/4 flex justify-start items-start flex-col gap-[12px]">
    <div class="gift-item">
      <div class="statistics">
        <div class="data">0</div>
        <div class="name-statistic">{{ $t("common.followers") }}</div>
      </div>
      <div class="icon" style="background-color: rgba(254, 246, 238, 1)">
        <span v-html="Followers"></span>
      </div>
    </div>
    <div class="gift-item">
      <div class="statistics">
        <div class="data">0</div>
        <div class="name-statistic">
          {{ $t("common.number_of_giftings") }}
        </div>
      </div>
      <div class="icon" style="background-color: rgba(253, 242, 250, 1)">
        <span v-html="BoxGift"></span>
      </div>
    </div>
    <div class="gift-item">
      <div class="statistics">
        <div class="data">0</div>
        <div class="name-statistic">{{ $t("common.coin") }}</div>
      </div>
      <div class="icon" style="background-color: rgba(254, 251, 232, 1)">
        <span v-html="CoinGift"></span>
      </div>
    </div>
    <div class="gift-item">
      <div class="statistics">
        <div class="data">0</div>
        <div class="name-statistic">
          {{ $t("common.live_broadcast_times") }}
        </div>
      </div>
      <div class="icon" style="background-color: rgba(236, 253, 243, 1)">
        <span v-html="TimeLive"></span>
      </div>
    </div>
    <div class="gift-item">
      <div class="statistics">
        <div class="data">0</div>
        <div class="name-statistic">{{ $t("common.views") }}</div>
      </div>
      <div class="icon" style="background-color: rgba(249, 245, 255, 1)">
        <span v-html="EyeViews"></span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import "dayjs/locale/zh-cn";
import { computed, h, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  BoxGift,
  CameraIcon,
  CoinGift,
  EyeViews,
  Followers,
  LockIcon,
  RemoveIcon,
  SaveIcon,
  TimeLive,
  TrashFilledIcon
} from "../../../assets/constant/svg_exports";
import {
  addConfirmModal,
  addModal,
  clearCharactersNotSupported,
  containsNonWhitespaceCharacters,
  getAvatarUserByName,
  handleImageError,
  showAlert,
  useDebounce,
  validateEmail,
  validatePhoneNumber,
} from "../../../helpers";
import ConfirmModal from "../../master/ConfirmModal.vue";
import FormPasswordUserUpdate from "../components/FormPasswordUserUpdate.vue";
const route = useRoute();
const store = useStore();
const userId = ref("");
const name = ref("");
const user_id = ref("");
const avatar = ref("");
const loading = ref(true);
const loadingUploadImage = ref(false);
const { t } = useI18n();
const inputCheck = ref({
  name: true,
  user_id: true,
  avatar: true,
});
const processUpdateUser = ref(false);
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "20px",
    color: "rgba(239, 104, 32, 1)",
  },
  spin: true,
});
const selectedStore = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const userDetails = computed(() => {
  return store.state.userDetails;
});
watch(userId, () => {
  store.dispatch("getUserDetails", userId.value);
});
watch(userDetails, () => {
  user_id.value = userDetails?.value?.userName || "";
  name.value = userDetails?.value?.nickName || "";
  avatar.value = userDetails?.value?.avatar || "";
});
const handleUploadAvatar = () => {
  if (!loadingUploadImage.value) {
    document.getElementById("avatar_detail").click();
  }
};
const handleDeleteAccount = () => {
  addConfirmModal(ConfirmModal, {}, () => {
    store.dispatch("deleteUser", userId.value);
  });
};
const handleFileInputChange = (event) => {
  try {
    loadingUploadImage.value = true;
    const selectedFile = event.target.files[0];
    avatar.value = selectedFile;
    const imageUrl = URL.createObjectURL(selectedFile);
    inputCheck.value.avatar = true;
    handleUpdateImage();
    setTimeout(() => {
      if (document.getElementById("input-avatar-fake")) {
        document.getElementById("input-avatar-fake").src = imageUrl;
      }
      loadingUploadImage.value = false;
    }, 2000);
  } catch (e) {
    throw new Error(e.message);
  } finally {
    setTimeout(() => {
      loadingUploadImage.value = false;
    }, 2000);
  }
};
const handleDeleteImage = () => {
  if (avatar.value !== "") {
    addConfirmModal(
      ConfirmModal,
      {
        title: t("common.delete_photo"),
      },
      () => {
        try {
          loadingUploadImage.value = true;
          store.dispatch("deleteUserAvatar", {
            userId: userId.value,
            userInfo: {
              deleteAvatar: 1,
            },
          });
        } catch (error) {
        } finally {
          setTimeout(() => {
            loadingUploadImage.value = false;
          }, 2000);
        }
      }
    );
  } else {
    showAlert(
      "warning",
      localStorage.getItem("lang") === "en"
        ? "Profile picture is empty"
        : "プロフィール写真が空です",
      "top-center"
    );
  }
};
const validateValueInForm = (ref, id, typeEmail = false, typePhoneNumber = false) => {
  if (typeEmail) {
    inputCheck.value[id] = validateEmail(ref);
  } else if (typePhoneNumber) {
    inputCheck.value[id] = validatePhoneNumber(ref);
  } else {
    inputCheck.value[id] =
      containsNonWhitespaceCharacters(ref) &&
      ref !== "" &&
      ref !== null &&
      ref !== undefined;
  }
};
const arrDebounce = Object.keys(inputCheck.value)?.map(() => {
  return useDebounce(validateValueInForm, 500);
});
watch(
  [name, user_id, avatar],
  ([newName, newUser_id, newAvatar], [oldName, oldUser_id, oldAvatar]) => {
    if (newName !== oldName) {
      name.value = newName?.trimStart();
      arrDebounce[0](newName?.trimStart(), "name");
    } else if (newUser_id !== oldUser_id) {
      user_id.value = clearCharactersNotSupported(newUser_id);
      arrDebounce[1](clearCharactersNotSupported(newUser_id), "user_id");
    } else if (newAvatar !== oldAvatar) {
      arrDebounce[2](newAvatar, "avatar");
    }
  }
);
const validateBeforeSubmit = () => {
  let messageError = "";
  const language = localStorage.getItem("lang");
  if (name.value === "" || user_id.value === "") {
    validateValueInForm(name.value, "name");
    validateValueInForm(user_id.value, "user_id");
    validateValueInForm(avatar.value, "input-upload-fake");
    messageError =
      language === "en"
        ? "Please fill out all information fields completely"
        : "全ての情報の項目を入力してください。";
  }
  if (messageError !== "") {
    showAlert("error", messageError, "top-center");
  }
  return messageError === "";
};
const handleSubmit = () => {
  if (validateBeforeSubmit() && !processUpdateUser.value) {
    processUpdateUser.value = true;
    const userData = {
      userId: userId.value,
      userInfo: {
        nickName: name.value,
        userName: user_id.value,
        shop: selectedStore.value.store_id,
      },
      onTurnOffLoad: () => {
        setTimeout(() => {
          processUpdateUser.value = false;
        }, 1000);
      },
    };
    store.dispatch("updateUserInfo", userData);
  }
};
const handleUpdateImage = () => {
  if (avatar.value) {
    const formData = new FormData();
    formData.append("avatar", avatar.value);
    store.dispatch("updateUserAvatar", {
      userId: userId.value,
      userInfo: formData,
    });
  }
};

const handleTurnOnPasswordForm = () => {
  addModal(FormPasswordUserUpdate);
};
onMounted(() => {
  userId.value = route.query.user_id;
  setTimeout(() => {
    loading.value = false;
  }, 3000);
});
</script>
<style lang="scss" scoped>
.info-user {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  width: 74%;
  background-color: #ffffff;
  .box-avatar {
    position: relative;
    .camera-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0px;
      right: 0px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--primary-color);
      position: absolute;
    }
  }
  .inputs {
    .input-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 64px;
      gap: 8px;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--border-color);
      label {
        width: 200px;
        font-family: "NotoSansJP_600";
        font-size: 16px;
        color: rgba(102, 112, 133, 1);
      }
      input,
      .input {
        width: 100%;
        flex: 1;
        border-radius: 8px;
        height: 44px;
        outline: none;
        padding: 10px 14px;
        border: 1px solid rgba(208, 213, 221, 1);
        &:focus {
          background-color: #f2f4f7;
        }
      }
    }
    .input-box-only-df {
      border-bottom: 1px solid var(--border-color);
    }
  }
}
.gift-statistic {
  flex: 1;
  min-width: 235px;
  .gift-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 108px;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    background-color: #ffffff;
    .statistics {
      min-width: 66%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;
      .data,
      .name-statistic {
        line-height: 30px;
        height: 30px;
      }
      .data {
        font-family: "NotoSansJP_600";
        font-size: 20px;
        color: rgba(24, 34, 48, 1);
      }
      .name-statistic {
        font-family: "NotoSansJP_400";
        font-size: 16px;
        color: rgba(102, 112, 133, 1);
      }
    }
    .icon {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 68px;
      height: 68px;
      border-radius: 8px;
      padding: 18px;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .info-user {
    width: 100% !important;
    button {
      font-size: 13px;
    }
  }
}
@media only screen and (max-width: 700px) {
  .title-action {
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
    .action {
      width: 100%;
      justify-content: space-between;
      button {
        width: 48%;
        font-size: 13px;
      }
    }
  }
  .input-box-only-df {
    button {
      width: 170px;
      font-size: 12px;
    }
    .avatar {
      width: 80px;
      height: 70px;
    }
    .camera-icon {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
@media only screen and (max-width: 500px) {
  .avatar {
    width: 80px !important;
    height: 60px;
    object-fit: cover;
  }
  button {
    min-width: 100px !important;
  }
}
button.disabled {
  background-color: rgb(214 159 125) !important;
}
</style>
