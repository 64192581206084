<template>
  <div class="table-container mx-6 h-[calc(100vh-200px)]">
    <!-- Table Header -->
    <div class="overflow-hidden h-[50px]">
      <table class="user-point-detail-table w-full">
        <thead class="sticky top-0 bg-white">
          <tr>
            <th class="p-[14px]">
              {{ $t('common.date') }}
            </th>
            <th class="p-[14px]">
              {{ $t('common.point') }}
            </th>
            <th class="p-[14px]"></th>
          </tr>
        </thead>
      </table>
    </div>
    <!-- Table Body -->
    <div class="overflow-y-auto h-[calc(100vh-240px)] scroll-bar">
      <table class="user-point-detail-table !rounded-t-none w-full">
        <tbody>
          <tr v-for="(i, index) in dataRender" :key="i">
            <td :key="index">
              {{ i.month }}
            </td>
            <td>{{ i.point }}</td>
            <td class="w-[20px] ">
              <button
                v-html="DetailIcon1"
                @click="() => handleChangeYearToMonth(i?.dayString)"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DetailIcon1 } from '../../../assets/constant/svg_exports';
import {
  formatDateForEnYYYYMM,
  formatDateForJapaneseYYYYMM,
  formatDateFromDateYYYYMM,
  getCurrentDateYYYY,
} from "../../../helpers";
const props = defineProps({
  onNaviSeeOfMonth: {
    type: Function,
    required: true,
  },
  onChangePoint: {
    type: Function,
    required: true,
  },
});
const handleChangeYearToMonth = (date) => {
  props.onNaviSeeOfMonth(date);
};
const handleChangePoint = (point) => {
  props.onChangePoint(point);
};
const store = useStore();
const router = useRouter();
const route = useRoute();
const dataRender = ref([]);
const selectedShop = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const pointYearList = computed(() => store.state.pointYears);
watch([pointYearList], () => {
  const y = router.currentRoute.value?.query?.year;
  dataRender.value = pointYearList.value?.map((item) => {
    const m = item.month;
    return {
      ...item,
      dayString: formatDateFromDateYYYYMM(new Date(y, m - 1, 1)),
      month:
        localStorage.getItem('lang') === 'en'
          ? formatDateForEnYYYYMM(new Date(y, m - 1, 1))
          : formatDateForJapaneseYYYYMM(new Date(y, m - 1, 1)),
      point: item.totalGiftPoint || 0,
    };
  });
});
watch(dataRender, () => {
  const totalPoint = dataRender.value.reduce((acc, curr) => acc + curr.point, 0);
  handleChangePoint(totalPoint)
});
const handleDateUndefined = () => {
  const date = localStorage.getItem('datePoint');
  const [y, m, d] = date?.split('-');
  router.push(`/point?year=${`${y}` || getCurrentDateYYYY()}`);
};
const handleFetchingData = async () => {
  await router.isReady();
  const year = router.currentRoute.value?.query?.year;
  if (year) {
    store.dispatch('getPointYearList', {
      shopId: selectedShop.value?.store_id,
      year: year+'-01-01',
    });
  } else {
    handleDateUndefined();
  }
};
watch(
  () => route.query.year,
  () => {
    handleFetchingData();
  }
);
onMounted(() => {
  handleFetchingData();
});
</script>
<style scoped lang="scss">
.table-container {
  display: grid;
  grid-template-rows: auto 1fr;
}
.user-point-detail-table th,
.user-point-detail-table td {
  width: calc(100% / 3);
}
.user-point-detail-table th:last-child,
.user-point-detail-table td:last-child {
  width: 5%;
}
</style>
