<template>
  <div :class="{'user-item relative p-[20px] rounded-[12px]': true, '!bg-[#EAECF0]': props?.user?.status === 'pending'}" v-on:click="handleNaviDetailsUser">
    <span
      v-if="props?.user?.status === 'active'"
      class="w-[56px] h-[24px] rounded-[8px] border-solid leading-[22px]
      border-[1px] border-[#F38744] bg-white text-[#F38744] 
      notosans500 text-[12px] text-center absolute right-[10px] top-[10px]"
      >{{ $t("common.under_contract") }}
    </span>
    <div class="img w-[100px] h-[100px] relative">
      <img
        :class="{'w-[100px] h-[100px] rounded-full object-cover': true, 'grayscale-[0.5]': props?.user?.status === 'pending'}"
        :src="getAvatarUserByName(props?.user?.avatar)"
        alt=""
        @load="handleImageLoad"
        @error="handleImageError"
        style="
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        "
      />
      <a-spin
        v-if="loading"
        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
      />
    </div>
    <div
      class="name--account-name flex justify-center items-center flex-col pt-[12px] w-full"
      v-on:click="handleClickSeeDetails"
    >
      <h3 :class="{'name w-full text-center text-[16px] notosans600 pb-[4px]': true, '!text-[#98A2B3]': props?.user?.status === 'pending'}">
        {{ props?.user?.nickName }}
      </h3>
      <span :class="{'account-name w-full text-center': true, '!text-[#98A2B3]': props?.user?.status === 'pending'}">{{ props?.user?.userName }}</span>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getAvatarUserByName, handleImageError } from "../../../helpers";
const router = useRouter();
const loading = ref(true);
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
});
const handleNaviDetailsUser = () => {
  if(props.user.status !== 'pending'){
    router.push(`/user/details/active?user_id=${props?.user?._id}`);
  }else{
    router.push(`/user/details/pending?user_id=${props?.user?._id}`);
  }
};
const handleImageLoad = () => {
  loading.value = false;
};
</script>
<style scoped lang="scss">
$number_item: 5;
$gap: 24px;
.user-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 202px;
  width: calc(
    (100% / #{$number_item}) - (#{$gap} * (#{$number_item} - 1) / #{$number_item})
  );
  border: 1px solid var(--border-color);
  min-width: 230px;
  cursor: pointer;
  background-color: var(--brg-light-color);
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  &:active {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  }
  .name,
  .account-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 180px;
  }
}
</style>
