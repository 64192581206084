<template>
  <div
    :class="{
      'account-item transition-all p-3 flex items-center justify-between border-[1px] border-[#D0D5DD] rounded-lg cursor-pointer': true,
      ticked:
        selectedUser === accountInfo?._id ||
        accountInfo?.packageUser?.length > 0,
      unTick: unselectedUser === accountInfo?._id,
      expired: isExpired
    }"
    @click="
      isExpired
        ? () => {}
        : toggleSelection(accountInfo?.id, accountInfo?.packageUser[0]?._id)
    "
  >
    <div class="account-info-left flex gap-2">
      <div class="relative">
        <img
          :src="getAvatarUserByName(accountInfo?.avatar)"
          class="w-[50px] h-[50px] rounded-full object-cover"
          alt="avatar"
          @error="handleImageError"
        />
        <span
          v-html="isExpired ? expiredStatusDot : statusDot"
          class="absolute right-1 bottom-[2px]"
        ></span>
      </div>
      <div class="account-info">
        <div
          :class="{
            'notosans700 text-[16px] text-[#182230]': true,
            'expired-nick-name': isExpired
          }"
        >
          {{ accountInfo?.nickName }}
        </div>
        <div
          :class="{
            'notosans400 text-[14px] text-[#667085]': true,
            'expired-username': isExpired
          }"
        >
          {{ accountInfo?.userName }}
        </div>
      </div>
    </div>
    <div class="account-info-right transition-all">
      <!-- not expired -->
      <span
        v-if="
          !isExpired &&
          accountInfo?.packageUser &&
          accountInfo?.packageUser?.length == 0
        "
        v-html="selectedUser !== accountInfo?._id ? inactiveTick : activeTick"
      ></span>
      <span
        v-if="
          !isExpired &&
          accountInfo?.packageUser &&
          accountInfo?.packageUser?.length > 0
        "
        v-html="unselectedUser !== accountInfo?._id ? activeTick : inactiveTick"
      ></span>
      <!-- expired -->
      <span
        v-if="isExpired"
        v-html="
          !accountInfo?.packageUser || accountInfo?.packageUser?.length == 0
            ? inactiveTick
            : expiredActiveTick
        "
      ></span>
    </div>
  </div>
</template>
  <script>
import { computed, ref } from 'vue';
import {
  activeTick,
  expiredActiveTick,
  expiredStatusDot,
  inactiveTick,
  statusDot
} from '../../../assets/constant/constant';
import { getAvatarUserByName, handleImageError } from '../../../helpers';

export default {
  name: 'AccountRenewItemV1',
  props: {
    accountInfo: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isExpired: {
      type: Boolean,
      required: true
    },
    isFirstDayOfNextMonth: {
      type: Boolean,
      required: true
    },
    onPushSelectedUser: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const userChange = computed(() =>
      localStorage.getItem('userChange') == 'true' ? true : false
    );
    const selectedUser = ref(userChange.value ? null : props.accountInfo?._id);
    const unselectedUser = ref(null);
    const toggleSelection = (userId, packageId) => {
      const haspackageUser = props.accountInfo?.packageUser?.length > 0;
      if (haspackageUser) {
        if (selectedUser.value === null && unselectedUser.value === null) {
          selectedUser.value = null;
          unselectedUser.value = userId;
          props.onPushSelectedUser(userId, packageId, false);
        } else if (selectedUser.value === userId) {
          selectedUser.value = null;
          unselectedUser.value = userId;
          props.onPushSelectedUser(userId, packageId, false);
        } else {
          unselectedUser.value = null;
          selectedUser.value = userId;
          props.onPushSelectedUser(userId, packageId, true);
        }
      } else {
        if (selectedUser.value === userId) {
          selectedUser.value = null;
          unselectedUser.value = userId;
          props.onPushSelectedUser(userId, packageId, false);
        } else {
          unselectedUser.value = null;
          selectedUser.value = userId;
          props.onPushSelectedUser(userId, packageId, true);
        }
      }
    };

    //get latest purchase pack from packageUser
    const latestPurchasePackage = computed(() => {
      return props.accountInfo?.packageUser?.reduce((max, current) => {
        return current.createAt > max.createAt ? current : max;
      }, props.accountInfo.packageUser[0]);
    });
    return {
      //define
      unselectedUser,
      selectedUser,
      //icon
      statusDot,
      expiredStatusDot,
      activeTick,
      expiredActiveTick,
      inactiveTick,
      //helpers
      handleImageError,
      getAvatarUserByName,
      //custom function
      toggleSelection
    };
  }
};
</script>
  <style lang="scss" scoped>
$four_item_on_row: 4;
$three_item_on_row: 3;
$two_item_on_row: 2;
$gap: 20px;
.account-item {
  min-width: 200px;
  width: calc(
    (100% / #{$four_item_on_row}) -
      (#{$gap} * (#{$four_item_on_row} - 1) / #{$four_item_on_row})
  );
  @media screen and (max-width: 1185px) {
    width: calc(
      (100% / #{$three_item_on_row}) -
        (#{$gap} * (#{$three_item_on_row} - 1) / #{$three_item_on_row})
    );
  }
  @media screen and (max-width: 815px) {
    width: calc(
      (100% / #{$two_item_on_row}) -
        (#{$gap} * (#{$two_item_on_row} - 1) / #{$two_item_on_row})
    );
  }
  @media screen and (max-width: 595px) {
    width: 100%;
  }
}
.ticked {
  border-color: #f38744;
  background-color: #fef6ee;
}
.unTick {
  border-color: #d0d5dd !important;
  background-color: white !important;
}
.expired {
  border-color: #eaecf0;
  cursor: not-allowed;
  background-color: #f2f4f7 !important;
}
.expired-nick-name {
  color: #747a83;
}
.expired-username {
  color: #a3a9b6;
}
</style>