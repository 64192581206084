<template>
  <div
    class="dashboard-livestream-table h-[280px] overflow-y-auto overflow-x-hidden scroll-bar"
  >
    <div class="flex justify-center items-center h-full" v-if="!dataLength">
      {{ $t('common.no_data') }}
    </div>
    <table
      v-else
      class="min-w-full"
      v-for="(item, index) in data"
      :key="item?.userOfShop._id"
    >
      <tr
        :class="index % 2 == 0 ? 'bg-[#FEFAF5]' : ''"
        class="flex justify-between items-center px-6 py-3 xs:px-2 xs:py-2"
      >
        <td class="flex items-center w-2/3">
          <div class="text-black font-bold text-[16px] leading-[18px]">
            {{ index + 1 }}
          </div>
          <img
            :src="item?.avatar"
            alt="avatar"
            class="w-[32px] h-[32px] rounded-full ml-4 mr-2"
          />
          <div class="text-name-text">{{ item?.userOfShop.nickName }}</div>
        </td>
        <td class="flex items-center justify-end w-1/3 text-right">
          <span class="text-name-text mr-3">{{ item?.totalPoints }}</span>
          <span class="inline-block" v-html="item?.icon"></span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'LiveTableItem',
  props: {
    data: {
      type: Array
    }
  },
  setup(props) {
    const dataLength = computed(() => props.data?.length);
    return {
      dataLength
    };
  }
};
</script>
<style>
</style>