// Xu ly ham trong state

export const userGetters = {
  userListByPageGetters: (state) => (payload) => {
    const startIndex = payload.pageSize * (payload.page - 1);
    const endIndex = payload.pageSize * payload.page;
    return state.userList?.slice(startIndex, endIndex);
  },

  userDeletedListByPageGetters: (state) => (payload) => {
    const startIndex = payload.pageSize * (payload.page - 1);
    const endIndex = payload.pageSize * payload.page;
    return state.userDeletedList?.slice(startIndex, endIndex);
  },

  userListLen: (state) => () => {
    return state.userList?.filter((user) => user?.status === 'active').length;
  },

  userDetailGetters: (state) => (payload) => {
    return state.userList?.find((item) => item._id === payload._id);
  },

  userInfoGetters: (state) => {
    return state.userInfo;
  },

  pricingListByMonth: (state) => () => {
    return state?.pricingList?.filter(
      (item) => item.type === 'month' && item.title
    );
  },

  pricingListByYear: (state) => () => {
    return state?.pricingList?.filter(
      (item) => item.type === 'year' && item.title
    );
  },

  coinListGetters(state) {
    return state.coinList;
  },

  //Dashboard
  giftingNumberSortedGetters(state) {
    return state.numberOfGifting.sort((a, b) => b.totalPoints - a.totalPoints);
  },
  newUserSortedGetters(state) {
    return [...state.userList].reverse();
  },
  //User gift point
  userGiftPointByMonthGetters(state) {
    return state.userGiftPointByMonth.sort((a, b) => a.day - b.day);
  },
  userGiftPointByYearGetters(state) {
    return state.userGiftPointByYear.sort((a, b) => a.day - b.day);
  },
  //fiter active account
  filterActiveAccounts(state) {
    return state.userList?.filter((item) => item.status === 'active');
  }
};
