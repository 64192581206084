<template>
  <div
    class="login-form-container h-screen flex flex-col justify-center items-center w-[100vw]"
  >
    <div class="login-form-logo">
      <div class="logo" v-html="LogoIcon"></div>
    </div>
    <div class="login-form flex mt-[40px] xs:mt-[20px]">
      <a-form
        :model="formState"
        autocomplete="off"
        @finish="onFinish"
        @onFinishFailed="onFinishFailed"
      >
        <div class="email-input mb-1">
          <div class="label text-black text-[14px] font-bold leading-5">
            {{ $t('common.email') }}
          </div>
          <a-form-item name="userName" class="mb-0 mt-2">
            <a-input
              placeholder="user@mail.com"
              v-model:value="formState.userName"
              class="text-input w-[480px] py-3 px-4 rounded-xl xs:w-[300px] hover:border-[#D0D5DD] focus:border-[#D0D5DD] focus:border-1"
            />
          </a-form-item>
        </div>
        <div class="passoword-input mt-6">
          <div class="label text-black text-[14px] font-bold leading-5">
            {{ $t('common.password') }}
          </div>
          <a-form-item
            name="password"
            :rules="rules.password"
            class="mb-0 mt-2"
          >
            <a-input
              :type="isShow ? 'text' : 'password'"
              v-model:value="formState.password"
              autocomplete="on"
              :placeholder="$t('common.password')"
              class="text-input w-[480px] py-3 px-4 rounded-xl xs:w-[300px] hover:border-[#D0D5DD] focus:border-[#D0D5DD] focus:border-1"
            />
            <!-- <div
              :class="!isShow ? 'icon' : 'icon::before'"
              v-html="PasswordShowIcon"
              @click="handleShow"
            ></div> -->
            <!-- <div class="icon-container">
              <div
                :class="['icon', { 'icon::before': isShow }]"
                v-html="PasswordShowIcon"
                @click="handleShow"
              ></div>
            </div> -->
            <div class="icon-container" @click="handleShow">
              <span
                :class="['icon', { 'icon-active': isShow }]"
                v-html="PasswordShowIcon"
              ></span>
            </div>
          </a-form-item>
          <div
            class="forgot-password-button text-xs text-[#667085] flex justify-between items-center mt-2"
          >
            <a-form-item
              class="remeber-button !mb-0 flex items-center"
              name="rememberMe"
            >
              <label class="flex items-center gap-2 cursor-pointer">
                <input type="checkbox" v-model="formState.rememberMe" />
                {{ $t('common.save_password') }}
              </label>
            </a-form-item>

            <!-- <a @click="router.push('/login/change_password')"> -->
            <a>
              {{ $t('common.forgotPassword') }}
            </a>
          </div>
        </div>
        <div class="submit-button mt-4">
          <a-form-item>
            <button
              :disabled="loading ? true : false"
              html-type="submit"
              :class="{
                'w-full h-[47.6px] rounded-xl bg-[#EF6820] mt-2 notosans600 text-white': true,
                '!bg-[#ffb486]': loading
              }"
            >
              <!-- <a-spin
                v-if="loading"
                class="absolute top-[16px] right-[45%]"
                size="large"
              /> -->
              <!-- <span v-if="!loading"> {{ $t('common.login') }}</span> -->
              <span> {{ $t('common.login') }}</span>
            </button>
          </a-form-item>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { LogoIcon, PasswordShowIcon } from '../../assets/constant/svg_exports';
import { getToken } from '../../helpers/token';
import router from '../../router/router';
export default {
  name: 'Login',
  setup() {
    const store = useStore();
    const loading = computed(() => store.state.loading);
    const checkLogin = async () => {
      const selectedShop = localStorage.getItem('selectedShop');
      if (getToken()) {
        if (selectedShop == 'null' || !selectedShop) {
          router.replace('/stores');
        }
      }
    };

    onMounted(() => {
      checkLogin();
      store.dispatch('removeStoreList');
    });

    const isShow = ref(false);
    const formState = reactive({
      userName: '',
      password: '',
      rememberMe: false
    });
    const { t } = useI18n();

    const onFinish = (values) => {
      const data = ref({});
      if (values.userName.includes('@')) {
        data.value = {
          email: values.userName,
          password: values.password,
          rememberMe: values.rememberMe
        };
      } else {
        data.value = {
          userName: values.userName,
          password: values.password,
          rememberMe: values.rememberMe
        };
      }
      store.dispatch('setLoading', true);
      if (values.userName === '' || values.password === '') {
        console.log('Email or Password is Empty');
      } else {
        setTimeout(
          () =>
            store.dispatch('login', {
              data: data.value,
              onFirstLogin: () => {
                router.push(`/login/change_password`);
              },
              onLoginSuccess: () => {
                store.dispatch('setSelectedShop', null);
                router.push('/stores');
              }
            }),
          1000
        );
      }
    };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed', errorInfo);
    };

    const handleShow = () => {
      isShow.value = !isShow.value;
    };
    return {
      formState,
      onFinish,
      onFinishFailed,
      isShow,
      handleShow,
      LogoIcon,
      PasswordShowIcon,
      router,
      loading,
      rules: {
        email: [
          {
            required: true,
            message: t('common.input_login_email_alert'),
            trigger: 'blur'
          },
          {
            type: 'email',
            message: t('common.input_email_password_alert'),
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: t('common.input_login_password_alert') }
          // {
          //   pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
          //   message: 'Password must be 6 to 20 characters',
          //   trigger: 'blur'
          // }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/responsive/media-queries.scss';
.text-input {
  position: relative;
}
.ant-input:focus {
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 0 black !important;
  box-shadow: 0 0 0 0 black !important;
}
.text-input {
  position: relative;
  input:focus {
    background: 2px solid black;
  }
}
.icon-container {
  position: absolute;
  top: 26%;
  right: 3%;
  cursor: pointer;
  .icon {
    position: relative;
    display: inline-block;
  }
  .icon::before {
    opacity: 0;
    content: '';
    position: absolute;
    @include xs {
      top: 50%;
      left: 50%;
      width: 100%;
    }
    top: 48%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: var(--orange-color);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
  }
  .icon-active::before {
    opacity: 1;
  }
}

.logo {
  @include xs {
    transform: scale(0.8);
  }
}
.forgot-password-button a:hover {
  color: #667085;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #ef6820;
}
input[type='checkbox']:checked {
  background-color: #ef6820;
  border: 2px solid #ef6820;
}
</style>
