<template>
  <div class="store-detail-setting-container">
    <div class="store-detail-title notosans700 text-header-text text-[20px]">
      {{ $t('common.store_information') }}
    </div>
    <div class="user-detail-form">
      <a-form :model="formState" @finish="onFinish" autocomplete="off">
        <div class="row-store-logo">
          <div class="col-store-logo pt-[33px]">
            {{ $t('common.store_logo') }}
          </div>
          <div class="col-75">
            <a-form-item name="storeAvatar">
              <div class="logo-section flex items-center xs:flex-col">
                <div class="avatar-container xs:mb-[10px]">
                  <div class="store-logo relative">
                    <img
                      class="w-[80px] h-[80px] object-cover rounded-full"
                      :src="formState?.storeAvatar"
                      alt="Logo"
                      @error="(e) => handleImageError(e, 'shop')"
                      @load="(e) => handleImageLoad(e, index)"
                    />
                    <a-spin
                      class="absolute top-[25px] left-[25px]"
                      v-if="loadingImage"
                      size="large"
                    />
                  </div>
                  <label
                    for="store-logo"
                    class="camera-icon bg-orage-text w-fit p-2 rounded-full cursor-pointer"
                  >
                    <div v-html="CameraIcon"></div>
                  </label>
                  <input
                    type="file"
                    id="store-logo"
                    accept="image/*"
                    @change="handleStoreAvatar"
                  />
                </div>
                <div
                  class="delete-btn flex items-center gap-[6px] px-4 py-2 border-[1px] border-delete-text rounded-lg ml-4 xs:ml-0 cursor-pointer"
                  @click="handleDeleteStoreLogoClick"
                >
                  <div v-html="TrashFilledIcon"></div>
                  <div class="text-delete-text">
                    {{ $t('common.delete_image') }}
                  </div>
                </div>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-25">
            {{ $t('common.store_name') }}
          </div>
          <div class="col-75">
            <a-form-item name="storeName" :rules="rules.storeName">
              <a-input v-model:value="formState.storeName" />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-25">{{ $t('common.store_address') }}</div>
          <div class="col-75">
            <a-form-item name="storeAddress" :rules="rules.address">
              <a-input v-model:value="formState.storeAddress" />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-25">
            {{ $t('common.store_telephone_number') }}
          </div>
          <div class="col-75">
            <a-form-item
              name="storeTelephoneNum"
              :rules="rules.telephoneNumber"
            >
              <!-- <a-input
                v-model:value="formState.storeTelephoneNum"
                @input="formatTelephone"
              /> -->
              <a-input v-model:value="formState.storeTelephoneNum" />
            </a-form-item>
          </div>
        </div>
        <!-- // -->
        <!-- <div class="row-photo">
          <div class="col-25 flex items-center !pt-0">
            {{ $t('common.store_photo') }}
          </div>
          <div class="col-75-photo flex items-center gap-4">
            <a-form-item name="storePhoto" class="mb-[8px]">
              <label for="photo-up" class="custom-photo-up whitespace-nowrap">
                <div v-html="UploadDocumentIcon"></div>
                {{ $t('common.photo_up') }}
              </label> -->
        <!-- // -->
        <!-- <input
                type="file"
                id="photo-up"
                :v-model="formState.storePhoto"
                multiple="multiple"
                accept="image/*"
                @change="handleUploadStorePhoto"
              /> -->
        <!-- // -->
        <!-- <input
                type="file"
                id="photo-up"
                @change="handleUploadStorePhoto"
              />
            </a-form-item>
            <div class="store-photo-list flex flex-wrap gap-3 mb-[10px]"> -->
        <!-- // -->
        <!-- <div
                class="store-photo-item"
                v-for="(item, index) in formState.storePhoto"
                :key="index"
              > -->
        <!-- // -->
        <!-- <div class="store-photo-item" v-if="formState.storePhoto">
                <img
                  class="store-photo w-[130px] h-[80px] object-cover rounded-lg"
                  :src="formState.storePhoto"
                  alt="store-photo"
                />

                <div
                  class="delete-photo-icon bg-delete-text border-[2px] border-white flex items-center justify-center rounded-lg w-[30px] h-[30px] cursor-pointer"
                  v-html="TrashOulinedIcon"
                  @click="handleDeleteStorePhoto()"
                ></div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- // -->
        <div class="row-submit-button">
          <div class="col-25"></div>
          <div class="col-75">
            <a-form-item>
              <button
                type="submit"
                class="border-[1px] px-4 py-3 rounded-lg bg-[#F38744] text-white flex items-center"
              >
                <label v-html="SaveSettingFilled" class="mr-[6px]"></label>
                {{ $t('common.save') }}
              </button>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {
  CameraIcon,
  SaveSettingFilled,
  TrashFilledIcon,
  TrashOulinedIcon,
  UploadDocumentIcon
} from '../../assets/constant/constant';
import {
  addSpinner,
  getAvatarShopByName,
  handleImageError,
  showAlert,
  validatePhoneNumber
} from '../../helpers';
import LoadingOverlay from './components/LoadingOverlay.vue';
export default {
  name: 'StoreDetailSettingTab',
  setup() {
    // const defaultImage = require('../../assets/images/other/host_collection_logo.png');
    const defaultImage = require('../../assets/images/other/default-store-logo.jpg');
    const { t } = useI18n();
    const store = useStore();
    //FILE HANDLE
    // const currentAvatar = `http://192.168.0.159:3000/public/uploads/shops/small/${store.state.selectedShop.store_avatar}`;
    const currentAvatar = getAvatarShopByName(
      store?.state?.selectedShop?.store_avatar
    );
    const loadingImage = ref(true);
    const handleImageLoad = (e, index) => {
      loadingImage.value = false;
    };
    const storeAvatarFile = ref(null);
    const convertUrlToFile = async (url, filename) => {
      try {
        if (url.includes('undefined') || url.includes('null')) {
          return;
        } else {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], filename, { type: blob.type });
          storeAvatarFile.value = file;
          return file;
        }
      } catch (error) {
        console.error('Error converting URL to file:', error);
      }
    };
    onMounted(() => {
      if (!store?.state?.selectedShop?.store_avatar) {
        convertUrlToFile(currentAvatar, store?.state?.selectedShop?.store_avatar);
      } else {
        console.log('default image');
      }
    });
    //END HANDLE FILE
    const formState = reactive({
      // storeAvatar: store.state.selectedShop.store_avatar
      //   ? currentAvatar
      //   : defaultImage,
      storeAvatar:
        store?.state?.selectedShop?.store_avatar !== null ? currentAvatar : '',
      storeName: store?.state?.selectedShop?.store_name,
      storeAddress: store?.state?.selectedShop?.store_address,
      storeTelephoneNum: store?.state?.selectedShop?.store_phone,
      storePhoto: ''
    });
    const loading = computed(() => store.state.loading);
    const isImageUploaded = reactive({ value: false });
    // const formatTelephone = () => {
    //   let value = formState.storeTelephoneNum;
    //   value = value.replace(/\D/g, '');
    //   if (value.length > 3 && value.length <= 7) {
    //     value = value.replace(/(\d{3})(\d+)/, '$1-$2');
    //   } else if (value.length > 7) {
    //     value = value.replace(/(\d{3})(\d{4})(\d+)/, '$1-$2-$3');
    //   }
    //   formState.storeTelephoneNum = value;
    // };
    // const removeFormat = (value) => {
    //   return value.replace(/[^0-9]/g, '');
    // };
    //SUBMIT FORM
    const onFinish = (values) => {
      store.dispatch('setLoading', true);
      const data = {
        id: store?.state?.selectedShop?.store_id,
        avatar: values?.storeAvatar ? storeAvatarFile?.value : '',
        name: values?.storeName,
        address: values?.storeAddress,
        phone: values?.storeTelephoneNum
      };
      addSpinner(LoadingOverlay);
      setTimeout(() => store.dispatch('updateStoreSetting', data), 1000);
    };
    //END SUBMIT FORM

    //watch loading
    watch(loading, (newLoading) => {
      if (newLoading === false) {
        const modalElement = document.querySelector(
          '.container-spinner-model-custom'
        );
        if (modalElement) {
          document.body.removeChild(modalElement);
        }
      }
    });
    //CHANGE STORE AVATAR
    // const handleStoreAvatar = (event) => {
    //   const file = event.target.files[0];
    //   console.log('store avatar:', file);
    //   formState.storeAvatar = URL.createObjectURL(file);
    //   storeAvatarFile.value = file;
    // };
    const handleStoreAvatar = (event) => {
      const file = event.target.files[0];
      formState.storeAvatar = URL.createObjectURL(file);
      storeAvatarFile.value = file;
      event.target.value = null;
    };
    //UPLOAD STORE PHOTO
    const handleUploadStorePhoto = (event) => {
      const files = event.target.files[0];
      // if (files) {
      //   Array.from(files).forEach((item) => {
      //     const photoUrl = URL.createObjectURL(item);
      //     formState.storePhoto.push(photoUrl);
      //   });
      // }
      formState.storePhoto = URL.createObjectURL(files);
      isImageUploaded.value = true;
      event.target.value = '';
    };
    //DELETE STORE PHOTO
    const handleDeleteStorePhoto = () => {
      // formState.storePhoto.splice(index, 1);
      if (formState.storePhoto) {
        URL.revokeObjectURL(formState.storePhoto);
        formState.storePhoto = null;
      }
      isImageUploaded.value = false;
    };
    //CHANGE STORE AVATAR
    const handleDeleteStoreLogoClick = () => {
      if (
        formState.storeAvatar.includes('undefined') ||
        formState.storeAvatar.includes('null') ||
        formState.storeAvatar == ''
      ) {
        showAlert('warning', t('common.store_avatar_empty'), 'top-center');
      } else {
        loadingImage.value = true;
        setTimeout(() => (formState.storeAvatar = ''), 1000);
      }
    };
    //FORM RULES
    const rules = {
      storeName: [
        {
          required: true,
          message: t('common.input_store_name_alert')
        }
      ],
      address: [
        {
          required: true,
          message: t('common.input_store_address_alert')
        }
      ],
      telephoneNumber: [
        {
          required: true,
          message: t('common.input_store_telephone_alert')
        }
      ]
    };
    return {
      formState,
      onFinish,
      loading,
      handleStoreAvatar,
      handleDeleteStoreLogoClick,
      handleUploadStorePhoto,
      handleDeleteStorePhoto,
      validatePhoneNumber,
      TrashOulinedIcon,
      CameraIcon,
      SaveSettingFilled,
      UploadDocumentIcon,
      TrashFilledIcon,
      // formatTelephone,
      rules,
      handleImageError,
      handleImageLoad,
      loadingImage
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/views/store_detail_tab';
</style>
