<template>
  <div
    class="user-ranking-item px-[4.2%] md:px-[3%] xs:px-[6%] flex flex-col items-center max-w-[200px]"
    v-for="item in rankingUser"
    :key="item"
  >
    <div class="w-[100px] sm:w-[70px] xs:w-[50px] flex justify-center">
      <img
        :src="item?.avatar"
        class="rounded-full object-cover"
        alt="avatar-ranking"
      />
    </div>
    <div
      class="user-ranking-name text-black font-bold text-[16px] leading-6 text-center pt-2 pb-1"
    >
      {{ item?.nickName }}
    </div>
    <div
      class="user-ranking-nickname text-[#667085] text-[14px] font-normal leading-[22px] text-center"
    >
      {{ item?.nickName }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rankingUser: Array
  },
  name: 'NewUserCarouselItem',
  // setup(props) {
  //   const sortedUser = computed(() => {
  //     return props.rankingUser.reverse();
  //   });
  //   return {
  //     sortedUser
  //   };
  // }
};
</script>
<style lang="scss">
.user-ranking-name,
.user-ranking-nickname {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
</style>