<template>
  <div
    :class="{
      'report-data-container px-6 py-[10px] h-[91vh]': true,
      '!w-screen': isHideSidebar,
      collapseSideBar: isCollaspseSideBar,
    }"
  >
    <div class="report-data-breadcrumb">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div
      class="report-data-header flex justify-between items-center flex-col md:flex-row sm:gap-2"
    >
      <div
        class="report-header-left text-[#101828] text-[24px] leading-[36px] whitespace-nowrap font-bold"
      >
        {{ $t('common.data_analysis') }}
        {{ toggleDateMonth ? monthValueHeader : yearValueHeader }}
      </div>
      <div class="report-header-right flex flex-col gap-2 md:flex-row">
        <div
          class="report-picker flex border-[1px] border-[#D0D5DD] rounded-lg"
        >
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('decrease')"
          >
            <span v-html="previousIcon"></span>
          </button>

          <div v-if="toggleDateMonth" class="label-time-picker cursor-pointer">
            <a-date-picker
              :format="monthFormat"
              :allowClear="false"
              picker="month"
              v-model:value="monthValue"
              @change="handleMonthPickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <div v-else class="cursor-pointer">
            <a-date-picker
              :format="yearFormat"
              picker="year"
              :allowClear="false"
              v-model:value="yearValue"
              @change="handleYearPickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('increase')"
          >
            <span v-html="nextIcon"></span>
          </button>
        </div>
        <div
          class="time-button flex bg-white rounded-lg border-[1px] border-solid border-[#D0D5DD]"
        >
          <button
            class="day px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] whitespace-nowrap notosans400"
            :class="toggleDateMonth ? 'isDayActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.month') }}
          </button>
          <button
            class="month px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] whitespace-nowrap notosans400"
            :class="!toggleDateMonth ? 'isMonthActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.year') }}
          </button>
        </div>
      </div>
    </div>
    <div class="report-data-body scroll-bar rounded-lg overflow-x-auto mt-4">
      <DataTable
        v-if="toggleDateMonth"
        :thead="reportDataTableTheadByDay"
        :date_type="$t('common.number_of_date')"
        :table_type="'Day'"
        :handleClickFunction="handleReportDetailClick"
      />
      <DataTable
        v-else
        :thead="reportDataTableTheadByMonth"
        :date_type="$t('common.number_of_month')"
        :table_type="'Month'"
      />
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  nextIcon,
  previousIcon,
  ReportDetail
} from '../../assets/constant/constant.js';
import { CalendarFilledIcon } from '../../assets/constant/svg_exports.js';
import { getCurrentDate, getCurrentMonth } from '../../helpers/index.js';
import DataTable from './components/DataTable.vue';
import 'dayjs/locale/ja';
dayjs.locale('ja');
export default {
  name: 'ReportData',
  components: { DataTable },
  setup() {
    const router = useRouter();
    const store = useStore();
    const toggleDateMonth = ref(true);
    const languageToggle = localStorage.getItem('lang') || 'ja';
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const monthFormat = 'YYYY年M月';
    const yearFormat = 'YYYY年';
    const currentMonth = ref(dayjs(getCurrentMonth(), 'YYYY-MM'));
    const currentYear = ref(dayjs(getCurrentDate(), 'YYYY'));
    const currentDay = ref(dayjs(getCurrentDate(), 'YYYY-MM-DD'));
    const yearValue = ref(currentYear);
    const monthValue = ref(currentMonth);
    let dayInMonthState = ref(
      moment(monthValue.value.$d, 'YYYY-MM').daysInMonth()
    );
    let monthValueHeader = ref(
      languageToggle === 'ja'
        ? `(${moment(monthValue.value.$d).format('YYYY年M月')})`
        : `(${months[moment(monthValue).format('M') - 1]})`
    );
    let yearValueHeader = ref(`(${moment(yearValue).format('YYYY年')})`);
    const { t } = useI18n();
    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
    };
    const isCollaspseSideBar = computed(() => store.state.collapseSidebar);

    const routes = ref([
      {
        path: '/',
        breadcrumbName: t('common.home')
      },
      {
        path: '/data_analysis',
        breadcrumbName: t('common.data_analysis')
      }
    ]);
    const reportDataByDay = [];
    const handleMonthPickerChange = () => {
      languageToggle === 'ja'
        ? ((monthValueHeader.value = `(${moment(monthValue.value.$d).format(
            'YYYY年M月'
          )})`),
          (dayInMonthState.value = moment(
            monthValue.value.$d,
            'YYYY-MM'
          ).daysInMonth()))
        : (monthValueHeader.value = `(${
            months[moment(monthValue.value.$d).format('M') - 1]
          })`);
      console.log(dayInMonthState.value, reportDataByDay);
    };
    const handleYearPickerChange = () => {
      yearValueHeader.value = `(${moment(yearValue.value.$d).format(
        'YYYY年'
      )})`;
    };
    watch(dayInMonthState, (newValue) => {
      for (let i = 0; i < newValue; i++) {
        reportDataByDay.push({
          streaming_times: '',
          livestream_duration: '',
          gifting: '',
          number_of_viewers: '',
          comment: '',
          new_follower: '',
          share: '',
          icon: ReportDetail
        });
      }
    });
    for (let i = 0; i < dayInMonthState.value; i++) {
      reportDataByDay.push({
        streaming_times: '',
        livestream_duration: '',
        gifting: '',
        number_of_viewers: '',
        comment: '',
        new_follower: '',
        share: '',
        icon: ReportDetail
      });
    }
    const reportDataByMonth = [];
    for (let i = 0; i < 12; i++) {
      reportDataByMonth.push({
        streaming_times: '',
        livestream_duration: '',
        gifting: '',
        number_of_viewers: '',
        comment: '',
        new_follower: '',
        share: ''
      });
    }
    const handleReportDetailClick = () => {
      // console.log(formatDayValue(dayValue.value.$d));
      // const id = formatDayValue(dayValue.value.$d);
      router.push(`/data_analysis_detail/${currentDay.value}`);
    };
    const reportDataTableTheadByDay = ref([
      t('common.date'),
      t('common.streaming_times'),
      t('common.live_time'),
      t('common.gifting_number'),
      t('common.number_of_viewers'),
      t('common.comment'),
      t('common.new_follower'),
      t('common.number_of_shares'),
      ''
    ]);
    const reportDataTableTheadByMonth = ref([
      t('common.date'),
      t('common.streaming_times'),
      t('common.live_time'),
      t('common.gifting_number'),
      t('common.number_of_viewers'),
      t('common.comment'),
      t('common.new_follower'),
      t('common.number_of_shares')
    ]);
    //custom function
    const handleIncreaseOrDecreaseDateTime = (type) => {
      switch (toggleDateMonth.value) {
        case true:
          type == 'increase'
            ? (monthValue.value = monthValue.value.add(1, 'month'))
            : (monthValue.value = monthValue.value.subtract(1, 'month'));
          handleMonthPickerChange();
          break;
        case false:
          monthValue.value =
            type == 'increase'
              ? (yearValue.value = monthValue.value.add(1, 'year'))
              : (yearValue.value = monthValue.value.subtract(1, 'year'));
          handleYearPickerChange();
          break;
      }
    };
    return {
      routes,
      isCollaspseSideBar,
      isHideSidebar: computed(() => store.state?.hideSidebar),
      yearValueHeader,
      reportDataTableTheadByDay,
      reportDataTableTheadByMonth,
      toggleDateMonth,
      CalendarFilledIcon,
      languageToggle,
      monthValue,
      monthValueHeader,
      yearValue,
      monthFormat,
      yearFormat,
      ReportDetail,
      reportDataByDay,
      reportDataByMonth,
      handleToggleDateMonthClick,
      handleReportDetailClick,
      handleMonthPickerChange,
      handleYearPickerChange,
      //icon
      previousIcon,
      nextIcon,
      //custom function
      handleIncreaseOrDecreaseDateTime,
      //locale
      locale
    };
  }
};
</script>
<style lang="scss">
@import '../../assets/css/views/_ant_custom.scss';
@import '../../assets/css/responsive/media-queries.scss';
.link-focus {
  color: var(--primary-color) !important;
}
.report-data-container {
  font-family: 'NotoSansJP_700';
  width: calc(100vw - 240px);
}
.collapseSideBar {
  width: calc(100vw - 80px) !important;
}
.hideSidebar {
  width: 100vw !important;
}
.isDayActive {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  background-color: #f38744;
  color: white !important;
}
.isMonthActive {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #f38744;
  color: white !important;
}
.report-data-header {
  @media (max-width: 600px) {
    display: block;
    .report-header-left {
      text-align: center;
    }
    .report-header-right {
      margin-top: 10px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>
