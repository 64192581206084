<template>
  <div class="setting pt-[10px] pb-[35px] px-[24px] min-h-[91vh]">
    <div class="link notosans400 h-[20px]">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <h2 class="title text-[24px] notosans600 mt-[20px] h-[36px]">
      {{ t('common.setting') }}
    </h2>
    <div class="tab flex justify-left h-[36px] gap-[4px] mt-[16px]">
      <div
        :class="{ 'tab-item': true, 'tab-item-focus': tab === 1 }"
        v-on:click="handleUpdateTab(1)"
      >
        {{ $t('common.store_information') }}
      </div>
      <div
        :class="{ 'tab-item': true, 'tab-item-focus': tab === 2 }"
        v-on:click="handleUpdateTab(2)"
      >
        {{ $t('common.password') }}
      </div>
      <div
        :class="{ 'tab-item': true, 'tab-item-focus': tab === 3 }"
        v-on:click="handleUpdateTab(3)"
      >
        {{ $t('common.bank_account') }}
      </div>
    </div>
    <div
      v-if="tab === 1"
      class="content mt-[16px] w-full rounded-lg overflow-hidden"
      style="border: 1px solid var(--border-color)"
    >
      <StoreDetailSettingTab />
    </div>
    <div
      v-if="tab === 2"
      class="content mt-[16px] w-full rounded-lg overflow-hidden"
      style="border: 1px solid var(--border-color)"
    >
      <StoreChangePasswordTab />
    </div>
    <div
      v-if="tab === 3"
      class="content mt-[16px] w-full rounded-lg overflow-hidden"
      style="border: 1px solid var(--border-color)"
    >
      <StoreAddBankAccountTab />
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import StoreChangePasswordTab from './StoreChangePasswordTab.vue';
import StoreDetailSettingTab from './StoreDetailSettingTab.vue';
import StoreAddBankAccountTab from './StoreAddBankAccountTab.vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const { t } = useI18n();
const pageCurrent = ref(6);
const totalItem = ref(300);
const tab = ref(
  Number(localStorage.getItem("settingTab")) || 1
);
const handleUpdateTab = (tabNumber) => {
  tab.value = tabNumber;
};
const routes = ref([
  {
    path: '/',
    breadcrumbName: t('common.home')
  },
  {
    path: '/user',
    breadcrumbName: t('common.setting')
  }
]);
const styleElement = document.createElement('style');
styleElement.innerHTML = `
    body {
      overflow-y: hidden;
      @media (max-width: 599px) {
        overflow-y: auto;
      }
      @media (max-height: 740px) {
        overflow-y: auto;
      }
    }
  `;

const handleCurrentTab = async () => {
  await router.isReady();
  if (tab.value == 1) {
    localStorage.setItem('settingTab', tab.value);
    router.push(`?tab=store_info`);
  } else if (tab.value == 2) {
    localStorage.setItem('settingTab', tab.value);
    router.push(`?tab=change_password`);
  } else {
    localStorage.setItem('settingTab', tab.value);
    router.push(`?tab=bank_info`);
  }
};
watch(tab, () => {
  handleCurrentTab();
});
onMounted(() => {
  document.head.appendChild(styleElement);
  handleCurrentTab();
});
onUnmounted(() => {
  document.head.removeChild(styleElement);
  localStorage.removeItem('settingTab');
});
</script>
<style lang="scss" scoped>
.setting {
  .tab {
    .tab-item {
      height: 36px;
      padding: 8px 12px;
      border: none;
      border-radius: 8px;
      font-family: 'NotoSansJP_400';
      font-size: 14px;
      color: rgba(102, 112, 133, 1);
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
        background-color: rgb(244, 240, 236);
      }
      &:active {
        font-family: 'NotoSansJP_600';
        font-size: 14px;
        background-color: rgba(253, 234, 215, 1);
      }
    }
    .tab-item-focus {
      font-family: 'NotoSansJP_600';
      color: var(--primary-color-dark) !important;
      background-color: rgba(253, 234, 215, 1) !important;
    }
  }
}
</style>
