<template>
  <div class="store-change-password-container overflow-auto">
    <div class="store-change-header notosans600 text-[20px] leading-[30px]">
      {{ $t('common.change_password') }}
    </div>
    <div class="store-change-body">
      <a-form :model="formState" @finish="onFinish">
        <div class="row">
          <div class="col-25">
            {{ $t('common.old_password') }}
          </div>
          <div class="col-75">
            <a-form-item name="oldPassword" :rules="rules.oldPassword">
              <a-input
                :type="isShowOldPassword ? 'text' : 'password'"
                :placeholder="$t('common.old_password_placeholder')"
                v-model:value="formState.oldPassword"
              />
              <div class="icon-container" @click="handleShowOldPassword">
                <span
                  :class="['icon', { 'icon-active': isShowOldPassword }]"
                  v-html="PasswordShowIcon"
                ></span>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-25">
            {{ $t('common.new_password') }}
          </div>
          <div class="col-75">
            <a-form-item name="newPassword" :rules="rules.newPassword">
              <a-input
                :type="isShowNewPassword ? 'text' : 'password'"
                :placeholder="$t('common.new_password_placeholder')"
                v-model:value="formState.newPassword"
              />
              <div class="icon-container" @click="handleShowNewPassword">
                <span
                  :class="['icon', { 'icon-active': isShowNewPassword }]"
                  v-html="PasswordShowIcon"
                ></span>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-25">
            {{ $t('common.new_password_confirm') }}
          </div>
          <div class="col-75">
            <a-form-item
              name="newPasswordConfirm"
              :rules="rules.newPasswordConfirm"
            >
              <a-input
                :type="isShowNewPasswordConfirm ? 'text' : 'password'"
                :placeholder="$t('common.new_password_placeholder')"
                v-model:value="formState.newPasswordConfirm"
              />
              <div class="icon-container" @click="handleShowNewPasswordConfirm">
                <span
                  :class="['icon', { 'icon-active': isShowNewPasswordConfirm }]"
                  v-html="PasswordShowIcon"
                ></span>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="row-link pb-[1.5%]">
          <div class="col-25"></div>
          <div class="col-75">
            <a
              class="no-underline hover:text-orage-text text-orage-text notosans500 text-[16px] leading-6"
              href="#"
              >{{ $t('common.for_user_forgot_password') }}</a
            >
          </div>
        </div>
        <div class="row-submit-button">
          <div class="col-25"></div>
          <div class="col-75 flex sm:justify-center xs:justify-center">
            <a-form-item>
              <button
                type="submit"
                class="bg-[#EF6820] text-white notosans500 text-[13px] leading-[24px] px-4 py-2 rounded-lg"
              >
                {{ $t('common.change_password') }}
              </button>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { computed, reactive, ref, watch } from 'vue';
import { PasswordShowIcon } from '../../assets/constant/svg_exports';
import ResetModalVue from './components/ResetModal.vue';
import { addSpinner, passwordModal } from '../../helpers';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import LoadingOverlay from './components/LoadingOverlay.vue';

export default {
  name: 'StoreChangePasswordTab',
  components: { ResetModalVue },
  setup() {
    const { t } = useI18n();
    const isShowOldPassword = ref(false);
    const isShowNewPassword = ref(false);
    const isShowNewPasswordConfirm = ref(false);
    const loading = computed(() => store.state.loading);
    const updatePasswordSetting = computed(
      () => store.state.updatePasswordSetting
    );
    const store = useStore();
    const handleShowOldPassword = () => {
      isShowOldPassword.value = !isShowOldPassword.value;
    };
    const handleShowNewPassword = () => {
      isShowNewPassword.value = !isShowNewPassword.value;
    };
    const handleShowNewPasswordConfirm = () => {
      isShowNewPasswordConfirm.value = !isShowNewPasswordConfirm.value;
    };
    const formState = reactive({
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    });
    const resetForm = () => {
      formState.newPassword = '';
      formState.newPasswordConfirm = '';
      formState.oldPassword = '';
    };
    const onFinish = (values) => {
      store.dispatch('setLoading', true);
      const dataPassword = {
        password: values.newPassword,
        passwordCurrent: values.oldPassword
      };
      addSpinner(LoadingOverlay);
      setTimeout(() => store.dispatch('setLoading', false), 1000);
      setTimeout(
        () =>
          store.dispatch('userChangePasswordSetting', {
            dataPassword: dataPassword
          }),
        1100
      );
      setTimeout(() => {
        // if (updatePasswordSetting.value != true) {
        //   resetForm();
        // } else if (updatePasswordSetting.value == true) {
        //   showNotifyDialog();
        //   resetForm();
        // }
      }, 1100);
    };
    //WATCH LOGIN IF UPDATE SUCCES OR FAILED
    watch(loading, (newLoading) => {
      if (newLoading === false) {
        const modalElement = document.querySelector(
          '.container-spinner-model-custom'
        );
        if (modalElement) {
          document.body.removeChild(modalElement);
        }
      }
    });
    watch(updatePasswordSetting, (newUpdatePassword) => {
      if (newUpdatePassword != true) {
        resetForm();
      } else {
        showNotifyDialog();
        resetForm();
      }
    });
    const showNotifyDialog = () => {
      passwordModal(ResetModalVue);
    };
    const confirmPasswordValidator = (rule, value) => {
      if (value !== formState.newPassword) {
        return Promise.reject(t('common.input_new_password_alert_confirm'));
      } else {
        return Promise.resolve();
      }
    };
    return {
      formState,
      isShowOldPassword,
      PasswordShowIcon,
      onFinish,
      rules: {
        oldPassword: [
          { required: true, message: t('common.input_old_password_alert') }
        ],
        newPassword: [
          { required: true, message: t('common.input_new_password_alert') }
        ],
        newPasswordConfirm: [
          {
            required: true,
            message: t('common.input_new_password_alert')
          },
          { validator: confirmPasswordValidator }
        ]
      },
      showNotifyDialog,
      handleShowOldPassword,
      handleShowNewPassword,
      handleShowNewPasswordConfirm,
      isShowNewPassword,
      isShowNewPasswordConfirm
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/views/store_password_tab';
</style>