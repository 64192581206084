<template>
  <div
    class="user-details pt-[10px] pb-[50px] px-[24px]"
    v-if="!isObjectEmpty(userDetails)"
  >
    <div class="link notosans400 h-[20px]">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes.length - 1"
            >{{ route?.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">{{
            route?.breadcrumbName
          }}</router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div class="tab flex justify-left h-[36px] gap-[4px] mt-[16px]">
      <div
        :class="{ 'tab-item': true, 'tab-item-focus': tab === 1 }"
        v-on:click="handleUpdateTab(1)"
      >
        {{ $t('common.personal_info') }}
      </div>
      <div
        :class="{ 'tab-item': true, 'tab-item-focus': tab === 2 }"
        v-on:click="handleUpdateTab(2)"
      >
        {{ $t('common.reports') }}
      </div>
      <div
        :class="{ 'tab-item': true, 'tab-item-focus': tab === 3 }"
        v-on:click="handleUpdateTab(3)"
      >
        {{ $t('common.point') }}
      </div>
    </div>
    <div class="content mt-[16px]">
      <TabUserInfo v-if="tab === 1" />
      <div
        v-if="tab !== 1"
        style="border: 1px solid var(--border-color)"
        class="report-tab h-screen w-full rounded-lg"
      >
        <UserReportTab v-if="tab === 2" />
        <UserGiftTab v-if="tab === 3" />
      </div>
    </div>
  </div>

  <div class="w-full h-[80vh] p-[50px] flex justify-center items-center" v-else>
    <div
      class="w-full h-[80vh] flex justify-center items-center"
      v-if="loading === false"
    >
      <EmptyData />
    </div>
    <a-skeleton avatar :paragraph="{ rows: 4 }" v-if="loading === true" />
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { isObjectEmpty } from '../../helpers';
import EmptyData from '../master/EmptyData.vue';
import TabUserInfo from './UserDetailComponent/TabUserInfo.vue';
import UserGiftTab from './UserDetailComponent/UserGiftTab.vue';
import UserReportTab from './UserDetailComponent/UserReportTab.vue';
const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useI18n();
const tab = ref(Number(localStorage.getItem('userDetailTab')) || 1);
const userId = ref('');
const loading = ref(true);
const userDetails = computed(() => {
  return store.state.userDetails;
});

const routes = ref([
  {
    path: '/',
    breadcrumbName: t('common.home')
  },
  {
    path: '/user',
    breadcrumbName: t('common.user')
  },
  {
    path: `/details?user_id=${userId.value}`,
    breadcrumbName: userDetails.value?.nickName || '-'
  }
]);

watch(userId, () => {
  if (userId.value) {
    store.dispatch('getUserDetails', userId.value);
  }
});

const updateBreadcrumb = () => {
  routes.value.forEach((item, index) => {
    if (index === 2) {
      item.path = `/details?user_id=${userId.value}`;
      item.breadcrumbName = userDetails.value?.nickName || '-';
    }
  });
};

watch(userDetails, () => {
  updateBreadcrumb();
});

const handleUpdateTab = (tabNumber) => {
  tab.value = tabNumber;
};

const updateCurrentTab = async () => {
  await router.isReady();
  if (tab.value == 1) {
    localStorage.setItem('userDetailTab', tab.value);
  } else if (tab.value == 2) {
    localStorage.setItem('userDetailTab', tab.value);
  } else {
    localStorage.setItem('userDetailTab', tab.value);
  }
};
watch(tab, () => {
  updateCurrentTab();
});
onMounted(() => {
  userId.value = route.query.user_id;
  updateCurrentTab();
  setTimeout(() => {
    loading.value = false;
  }, 3000);
});
</script>
<style lang="scss" scoped>
.user-details {
  width: 100%;
  min-height: 100vh;
  background-color: #f9fafb;
  .link {
    .link-focus {
      color: var(--primary-color) !important;
    }
  }
  .tab {
    .tab-item {
      height: 36px;
      padding: 8px 12px;
      border: none;
      border-radius: 8px;
      font-family: 'NotoSansJP_400';
      font-size: 14px;
      color: rgba(102, 112, 133, 1);
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
        background-color: rgb(244, 240, 236);
      }
      &:active {
        font-family: 'NotoSansJP_600';
        font-size: 14px;
        background-color: rgba(253, 234, 215, 1);
      }
    }
    .tab-item-focus {
      font-family: 'NotoSansJP_600';
      color: var(--primary-color-dark) !important;
      background-color: rgba(253, 234, 215, 1) !important;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    .box-avatar {
      position: relative;
      .camera-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0px;
        right: 0px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: var(--primary-color);
        position: absolute;
      }
    }
  }
}
.report-tab {
  max-height: calc(100vh - 190px);
  overflow: hidden;
}
@media only screen and (max-width: 1300px) {
  .content {
    justify-content: flex-center !important;
    .info-user {
      width: 100% !important;
      button {
        font-size: 13px;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .title-action {
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
    .action {
      width: 100%;
      justify-content: space-between;
      button {
        width: 48%;
        font-size: 13px;
      }
    }
  }
  .input-box-only-df {
    button {
      width: 170px;
      font-size: 12px;
    }
    .avatar {
      width: 80px;
      height: 70px;
    }
    .camera-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
@media only screen and (max-width: 500px) {
  .avatar {
    width: 80px !important;
    height: 60px;
    object-fit: cover;
  }
  button {
    min-width: 100px !important;
  }
}
</style>
