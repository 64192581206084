<template>
  <div class="welcome-store relative">
    <button
      class="btn-log-out outline-red absolute"
      v-on:click="handleLogout"
    >
      <span v-html="BackLoginIcon"></span>
      <span>{{ $t('common.logout') }}</span>
    </button>
    <div class="logo">
      <img src="../../assets/images/svg/Logo.svg" alt="logo" />
    </div>

    <div class="shops scroll-bar mb-[100px]">
      <div class="scroll-shops">
        <div
          class="w-full flex justify-center items-center flex-wrap gap-5"
          v-if="!shops || shops.length <= 0"
        >
          <a-skeleton-button
            v-for="index in 8"
            :key="index"
            :active="active"
            :size="200"
            :shape="buttonShape"
            :block="block"
          />
        </div>
        <div
          class="store relative"
          v-for="(shop, index) in shops"
          :key="index"
          v-on:click="handleSelectedShop(shop)"
        >
          <img 
            class="store-logo object-cover" 
            :src="getAvatarShopByName(shop.avatar)" 
            @error="(e) => handleImageError(e, 'shop')" 
            @load="(e) => handleImageLoad(e, index)"
            style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;"
            alt="" />
            <h4 class="store-name">{{ shop.name }}</h4>
            <a-spin class="absolute top-[35%] left-[45%]" v-if="loading[index]"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import VueCookies from 'vue-cookies';
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { BackLoginIcon } from "../../assets/constant/svg_exports";
import { formatDate, getAvatarShopByName, handleImageError } from "../../helpers";
const store = useStore();
const router = useRouter();
const loading = ref([]);
const shops = computed(() => store.state.shopList);
const handleSelectedShop = (shopSelected) => {
  store.dispatch('setSelectedShop', {
    store_id: shopSelected.id,
    store_name: shopSelected.name,
    store_avatar: shopSelected.avatar,
    store_address: shopSelected.address,
    store_phone: shopSelected.phone
  });
  store.dispatch('setSidebarFocus', 1);
  router.replace('/dashboard');
  store.dispatch("getUserList", shopSelected.id);
  const currentDate = new Date(); 
  currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  store.dispatch("getTotalUserCanCreate", {
    time: formatDate(currentDate),
    shopId: shopSelected.id,
  });
};
const handleLogout = () => {
  VueCookies.remove('access_token')
  router.replace('/login');
};
const handleImageLoad = (e, index) => {
  loading.value[index] = false;
}
onMounted(() => {
  loading.value = new Array(shops.value.length).fill(true);
  store.dispatch('getShopList');
});
</script>
<style scoped lang="scss">
* {
  font-family: 'NotoSansJP';
}
body {
  overflow: hidden;
}
.welcome-store {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  width: 100vw;
  height: max-content;
  min-height: 100vh;
  background-color: var(--brg-gray-color);
  .btn-log-out {
    top: 30px;
    right: 10%;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-top: 90px;
    img {
      width: 388px;
      height: 80px;
    }
  }
  .shops {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden;
    height: auto;
    overflow-y: hidden;
    //overflow-y: auto;
    //height: calc(156px * 2 + 24px);
    .scroll-shops {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;
      width: calc(100% - (100% - (215px * 4 + 24px * 3)));
      overflow: hidden;
      .store {
        width: calc(25% - (24px * 3 / 4));
        min-width: 215px;
        height: 156px;
        cursor: pointer;
        &:hover {
          .store-name {
            color: var(--primary-color);
          }
          .store-logo {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          }
        }
        .store-logo {
          width: 215px;
          height: 120px;
          border-radius: 12px;
          transition: all 0.2s;
        }
        .store-name {
          margin-top: 12px;
          font-weight: 700;
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          transition: all 0.2s;
        }
      }
    }
  }
}
</style>
