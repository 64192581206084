<template>
  <div
    class="login-form-container h-screen flex flex-col justify-center items-center w-[100vw]"
  >
    <div class="login-form-logo">
      <div class="logo" v-html="LogoIcon"></div>
    </div>
    <div class="login-form flex mt-[40px] xs:mt-[20px]">
      <a-form
        :model="formState"
        autocomplete="off"
        @finish="onFinish"
        @onFinishFailed="onFinishFailed"
      >
        <div class="email-input mb-1">
          <div class="label text-black text-[14px] font-bold leading-5">
            {{ $t("common.old_password") }}
          </div>
          <a-form-item name="oldPassword" class="mb-0 mt-2" :rules="rules.oldPassword">
            <a-input
              :type="isShowOldPassword ? 'text' : 'password'"
              :placeholder="$t('common.old_password_placeholder')"
              v-model:value="formState.oldPassword"
              class="text-input w-[480px] py-3 px-4 rounded-xl xs:w-[300px] hover:border-[#D0D5DD] focus:border-[#D0D5DD] focus:border-1"
            />
            <div class="icon-container" @click="handleShowOldPassword">
              <span
                :class="['icon', { 'icon-active': isShowOldPassword }]"
                v-html="PasswordShowIcon"
              ></span>
            </div>
          </a-form-item>
        </div>
        <div class="passoword-input mt-6">
          <div class="label text-black text-[14px] font-bold leading-5">
            {{ $t("common.new_password") }}
          </div>
          <a-form-item name="newPassword" class="mb-0 mt-2" :rules="rules.newPassword">
            <a-input
              :type="isShowNewPassword ? 'text' : 'password'"
              v-model:value="formState.newPassword"
              autocomplete="on"
              :placeholder="$t('common.new_password_placeholder')"
              class="text-input w-[480px] py-3 px-4 rounded-xl xs:w-[300px] hover:border-[#D0D5DD] focus:border-[#D0D5DD] focus:border-1"
            />
            <div class="icon-container" @click="handleShowNewPassword">
              <span
                :class="['icon', { 'icon-active': isShowNewPassword }]"
                v-html="PasswordShowIcon"
              ></span>
            </div>
          </a-form-item>
        </div>
        <div class="re-passoword-input mt-6">
          <div class="label text-black text-[14px] font-bold leading-5">
            {{ $t("common.new_password_confirm") }}
          </div>
          <a-form-item
            name="newPasswordConfirm"
            class="mb-0 mt-2"
            :rules="rules.newPasswordConfirm"
          >
            <a-input
              :type="isShowNewPasswordConfirm ? 'text' : 'password'"
              v-model:value="formState.newPasswordConfirm"
              autocomplete="on"
              :placeholder="$t('common.new_password_placeholder')"
              class="text-input w-[480px] py-3 px-4 rounded-xl xs:w-[300px] hover:border-[#D0D5DD] focus:border-[#D0D5DD] focus:border-1"
            />
            <div class="icon-container" @click="handleShowNewPasswordConfirm">
              <span
                :class="['icon', { 'icon-active': isShowNewPasswordConfirm }]"
                v-html="PasswordShowIcon"
              ></span>
            </div>
          </a-form-item>
        </div>
        <div class="submit-button mt-4">
          <a-form-item>
            <button
              :disabled="loading ? true : false"
              html-type="submit"
              :class="{
                'w-full h-[47.6px] rounded-xl bg-[#EF6820] mt-2 notosans600 text-white': true,
                '!bg-[#ffb486]': loading,
              }"
            >
              {{ $t("common.change_pwd") }}
            </button>
          </a-form-item>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { computed, reactive, ref } from "vue";
import VueCookies from "vue-cookies";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { LogoIcon, PasswordShowIcon } from "../../assets/constant/svg_exports";
export default {
  name: "ChangePassLogin",
  setup() {
    //define
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    //define
    const loading = computed(() => store.state.loading);
    //ref
    const isShowOldPassword = ref(false);
    const isShowNewPassword = ref(false);
    const isShowNewPasswordConfirm = ref(false);
    //form state
    const formState = reactive({
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    });
    //custom function
    const onFinish = async (value) => {
      const dataPassword = {
        password: value.newPassword,
        passwordCurrent: value.oldPassword,
      };
      store.dispatch("changePasswordFirstLogin", {
        dataPassword: dataPassword,
        onSuccessUpdate: () => {
          VueCookies.remove("access_token");
          router.replace("/login");
        },
      });
    };
    const onFinishFailed = (errorInfo) => {
      console.log(errorInfo);
    };
    const handleShowOldPassword = () => {
      isShowOldPassword.value = !isShowOldPassword.value;
    };
    const handleShowNewPassword = () => {
      isShowNewPassword.value = !isShowNewPassword.value;
    };
    const handleShowNewPasswordConfirm = () => {
      isShowNewPasswordConfirm.value = !isShowNewPasswordConfirm.value;
    };
    const confirmPasswordValidator = (rule, value) => {
      if (value !== formState.newPassword) {
        return Promise.reject(t("common.input_new_password_alert_confirm"));
      } else {
        return Promise.resolve();
      }
    };
    return {
      //define
      loading,
      formState,
      isShowOldPassword,
      isShowNewPassword,
      isShowNewPasswordConfirm,
      //function
      onFinish,
      onFinishFailed,
      handleShowOldPassword,
      handleShowNewPassword,
      handleShowNewPasswordConfirm,
      confirmPasswordValidator,
      //Icon
      LogoIcon,
      PasswordShowIcon,
      //rules
      rules: {
        oldPassword: [{ required: true, message: t("common.input_old_password_alert") }],
        newPassword: [{ required: true, message: t("common.input_new_password_alert") }],
        newPasswordConfirm: [
          {
            required: true,
            message: t("common.input_new_password_alert"),
          },
          { validator: confirmPasswordValidator },
        ],
      },
    };
  },
};
</script>
<style lang="scss">
@import "../../assets/css/responsive/media-queries.scss";
.text-input {
  position: relative;
}
.ant-input:focus {
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 0 black !important;
  box-shadow: 0 0 0 0 black !important;
}
.text-input {
  position: relative;
  input:focus {
    background: 2px solid black;
  }
}
.icon-container {
  position: absolute;
  top: 26%;
  right: 3%;
  cursor: pointer;
  .icon {
    position: relative;
    display: inline-block;
  }
  .icon::before {
    opacity: 0;
    content: "";
    position: absolute;
    @include xs {
      top: 50%;
      left: 50%;
      width: 100%;
    }
    top: 48%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: var(--orange-color);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
  }
  .icon-active::before {
    opacity: 1;
  }
}

.logo {
  @include xs {
    transform: scale(0.8);
  }
}
.forgot-password-button a:hover {
  color: #667085;
}
</style>
