import axiosConfig from '../../config/axiosConfig';
import { getToken } from '../../helpers/token';

export const storesAction = {
  async getShopList(context) {
    try {
      const token = getToken();
      if (token) {
        const res = await axiosConfig.get('/shop?type=admin', { token });
        context.commit('GET_SHOP_LIST', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async setSelectedShop(context, data) {
    localStorage.setItem('selectedShop', JSON.stringify(data));
    context.commit('SET_SELECTED_SHOP', data);
  },

  async registerStore(context, data) {
    try {
      const res = await axiosConfig.post('/shop', data);
      context.commit('REGISTER_SHOP', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getAreaList(context) {
    try {
      const res = await axiosConfig.get(`/location`);
      context.commit('GET_AREA_LIST', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  removeStoreList(context){
    try {
      context.commit('REMOVE_STORE_LIST');
    } catch (error) {
      throw new Error(error);
    }
  }

};
