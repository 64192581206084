<template>
  <div
    :class="{
      'user-detail-point-container rounded-lg bg-white pb-5': true,
      isCollapseSideBarUserDetailPoint: isCollaspseSideBar,
      isHideUserDetailPoint: isHideSidebar
    }"
  >
    <div class="user-detail-point-breadcrumb px-6 mt-[10px]">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div class="table-header flex justify-between items-center px-[20px] py-4">
      <div
        class="table-header-left text-title-info-text font-bold text-[24px] leading-[36px] notosans700 flex"
      >
        <button
          class="mr-3"
          v-html="arrorwLeftIcon"
          @click="handleGoBackToPreviousPage"
        ></button>
        {{ $t('common.point_detail') }}
        {{ monthValueHeader }}
      </div>
      <div class="table-header-right flex gap-2">
        <div
          class="point bg-[#EF6820] px-5 py-3 w-[180px] text-center text-white rounded-lg"
        >
          <span class="font-[400]">{{ $t('common.point') }}</span>
          <span class="font-[700]">: {{ calculateTotalPoint }}</span>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-body scroll-bar overflow-y-auto px-6">
        <table class="user-point-detail-table">
          <tr class="sticky top-0">
            <th>
              {{ $t('common.gifting') }}
            </th>
            <th>
              {{ $t('common.quantity') }}
            </th>
            <th>{{ $t('common.point') }}</th>
            <th></th>
          </tr>
          <tr v-for="(i, index) in getUserPointDetailFilter" :key="i">
            <td :key="index">
              <div class="flex-thead">
                <img
                  :src="getGiftIcon(i.giftNames[0]?.image)"
                  @error="imageError($event)"
                  v-if="isImageVisible"
                />
                {{ i.giftNames[0]?.name || '' }}
              </div>
            </td>
            <td>{{ i.countGift || '0' }}</td>
            <td>{{ i.totalPoints || '0' }}</td>
            <td class="w-[20px] !pr-6" v-if="toggleDateMonth">
              <button
                v-html="DetailIcon1"
                @click="handlePushDetailPointByCategory(i.giftId)"
              ></button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import moment from 'moment';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { arrorwLeftIcon } from '../../../../assets/constant/constant';
import {
  CalendarFilledIcon,
  DetailIcon1
} from '../../../../assets/constant/svg_exports';
import {
  getCurrentDate,
  getCurrentMonth,
  getGiftIcon
} from '../../../../helpers';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'UserPointDetailTab',
  setup() {
    const store = useStore();
    const route = useRoute();
    const userDetail = computed(() => {
      return store.state.userDetails;
    });
    const userPointDetail = computed(() => {
      return store.state.userGiftPointDetail;
    });
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const userId = ref('');
    const { t } = useI18n();
    const toggleDateMonth = ref(true);
    const currentDay = ref(dayjs(getCurrentDate(), 'YYYY-MM-DD'));
    const currentMonth = ref(dayjs(getCurrentMonth(), 'YYYY-MM'));
    const dayValue = ref(currentDay);
    const monthValue = ref(currentMonth);
    const isImageVisible = ref(true);
    const monthFormat = 'YYYY/MM';
    const yearFormat = 'YYYY';
    const router = useRouter();
    const isCollaspseSideBar = computed(() => store.state.collapseSidebar);
    const languageToggle = localStorage.getItem('lang') || 'ja';
    let monthValueHeader = ref(
      languageToggle === 'ja'
        ? `(${userDetail.value?.nickName || '-'} - ${moment(
            route.query.date
          ).format('YYYY年M月D日')})`
        : `(${userDetail.value?.nickName || '-'} - ${moment(
            route.query.date
          ).format('YYYY/M/D')})`
    );

    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
    };

    //custom function
    const imageError = () => {
      isImageVisible.value = false;
    };
    const handleGoBackToPreviousPage = () => {
      router.back();
    };
    const handlePushDetailPointByCategory = (giftId) => {
      router.push(
        `/user/user_point_detail/category?user_id=${userId.value}&shop=${route.query?.shop}&type_get=user&type=date&date=${route.query?.date}&gift=${giftId}`
      );
    };
    const updateBreadcrumb = () => {
      routes.value.forEach((item, index) => {
        if (index === 2) {
          item.path = `/user/details?user_id=${userId.value}`;
          item.breadcrumbName = userDetail.value?.nickName || '-';
        }
      });
    };
    const updateTitle = () => {
      monthValueHeader.value =
        languageToggle === 'ja'
          ? `(${userDetail.value?.nickName || '-'} - ${moment(
              route.query.date
            ).format('YYYY年M月D日')})`
          : `(${userDetail.value?.nickName || '-'} - ${moment(
              route.query.date
            ).format('YYYY/M/D')})`;
    };
    const data = {
      type: route.query.type,
      shop: route.query.shop,
      type_get: route.query.type_get,
      date: route.query.date,
      userReceive: route.query.user_id
    };
    //onMounted
    onMounted(() => {
      userId.value = route.query.user_id;
      store.dispatch('getUserPointDetail', data);
    });
    //onUnmounted
    onUnmounted(() => {
      store.commit('RESET_USER_POINT_DETAIL');
    });
    //watch
    watch(userId, () => {
      store.dispatch('getUserDetails', userId.value);
    });
    //breadcrumb
    const routes = ref([
      {
        path: '/',
        breadcrumbName: t('common.home')
      },
      {
        path: '/user',
        breadcrumbName: t('common.user')
      },
      {
        path: `/details?user_id=${userId.value}`,
        breadcrumbName: userDetail.value?.nickName || '-'
      },
      {
        path: `/user_point_detail?user_id=${userId.value}`,
        breadcrumbName: t('common.point_detail')
      }
    ]);
    watch(userDetail, () => {
      updateBreadcrumb();
      updateTitle();
    });

    //custom function
    const getUserPointDetailFilter = computed(() => {
      return userPointDetail?.value?.filter((item) => item.giftId !== null);
    });
    const calculateTotalPoint = computed(() =>
      getUserPointDetailFilter?.value?.reduce(
        (acc, item) => acc + item.totalPoints,
        0
      )
    );
    return {
      isImageVisible,
      months,
      toggleDateMonth,
      monthValueHeader,
      CalendarFilledIcon,
      languageToggle,
      monthValue,
      yearFormat,
      monthFormat,
      isCollaspseSideBar,
      isHideSidebar: computed(() => store.state?.hideSidebar),
      handleToggleDateMonthClick,
      DetailIcon1,
      userDetail,
      userPointDetail,
      getUserPointDetailFilter,
      //function
      handleGoBackToPreviousPage,
      handlePushDetailPointByCategory,
      getGiftIcon,
      calculateTotalPoint,
      imageError,
      //svg
      arrorwLeftIcon,
      //breadcrumb
      routes
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/css/views/_user_gift_table.scss';
@import '../../../../assets/css/views/ant_custom';
</style>