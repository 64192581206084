<template>
  <div
    class="reset-password-modal relative bg-white w-[600px] xs:mx-2 h-[324px] flex items-center flex-col rounded-[20px]"
  >
    <div class="closeButton absolute top-[1.2rem] right-6 cursor-pointer">
      <span v-html="CloseIcon" @click="handleOnClose"></span>
    </div>
    <div
      class="reset-header w-[160px] text-center notosans600 text-[20px] leading-[20px] mt-[40px] mb-[20px] whitespace-nowrap"
    >
      {{ $t('common.resetting_password') }}
    </div>
    <div
      class="reset-notification w-[480px] h-[48px] notosans300 text-[16px] text-center mb-[20px] xs:w-[280px]"
    >
      {{ $t('common.reset-password-notify') }}
    </div>
    <a-form :model="formState" @finish="onFinish">
      <a-form-item
        class="!mb-0"
        name="email"
        :rules="{
          required: true,
          message: $t('common.input_login_email_alert')
        }"
      >
        <div
          class="reset-input w-[480px] h-[66px] flex gap-1 flex-col items-start mb-[20px] xs:w-[280px] xs:mt-[10px]"
        >
          <div
            class="input-label notosans600 text-[14px] leading-[22px] text-black"
          >
            {{ $t('common.email_address') }}
          </div>
          <a-input
            class="w-full h-[40px] rounded-lg py-2 px-4 border-[1px] outline-0 border-[#D0D5DD]"
            type="email"
            :placeholder="inputPlaceholder"
            v-model:value="formState.email"
          />
        </div>
      </a-form-item>
      <a-form-item class="!mb-0">
        <button
          type="submit"
          class="notosans600 text-[14px] leading-[22px] submit-button mt-[20px] w-[480px] h-[40px] fill-primary-text-white bg-[#EF6820] cursor-pointer xs:w-[280px]"
        >
          {{ $t('common.send') }}
        </button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { CloseIcon } from '../../../assets/constant/constant';
import { addModal, passwordModal } from '../../../helpers';
import SuccessModalVue from './SuccessModal.vue';
import { reactive } from 'vue';
export default {
  name: 'ResetModal',
  props: {
    onClose: {
      type: Function,
      required: true
    }
  },
  components: {
    SuccessModalVue
  },
  setup(props) {
    const { t } = useI18n();
    const inputPlaceholder = `${t('common.example')}: user@mail.com`;
    const handleOnClose = () => {
      props.onClose();
    };
    const openModal = () => {
      handleOnClose();
      passwordModal(SuccessModalVue);
    };
    const formState = reactive({
      email: ''
    });
    const onFinish = (values) => {
      openModal();
    };
    return {
      inputPlaceholder,
      handleOnClose,
      openModal,
      CloseIcon,
      formState,
      onFinish
    };
  }
};
</script>
<style lang="scss" scoped>
.reset-input {
  margin-bottom: 0px !important;
}
input[type='email']:hover {
  border: 1px solid #d0d5dd;
  box-shadow: none !important;
  outline: none;
}
input[type='email']:focus {
  border: 1px solid #d0d5dd;
  box-shadow: none !important;
  outline-color: none;
}
input[type='email']:unfocus {
  outline: none;
}
</style>