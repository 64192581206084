<template>
  <div :class="styles.withdrawal">
    <div :class="`${styles.link} notosans400 h-[20px]`">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div :class="`${styles.title} w-full`">
      <span class="notosans700 text-[20px] text-black">出金</span>
    </div>
    <div :class="styles.infoWithdrawal">
      <div :class="styles.row1">
        <span class="notosans700 text-[20px]">{{ $t("common.withdrawal_info") }}</span>
        <div :class="`${btns} flex justify-center items-center gap-[8px]`">
          <button
            v-on:click="showFormDetails"
            class="min-w-[90px] h-[40px] rounded-[8px] px-[12px] py-[20px]"
          >
            <span class="whitespace-nowrap notosans500 text-[14px] leading-[18px]">{{
              $t("common.detail")
            }}</span>
          </button>
          <button
            v-on:click="showFormPaymentMethodSettings"
            class="min-w-[138px] h-[40px] flex justify-center items-center !gap-[6px] rounded-[8px] px-[12px] py-[20px] notosans500 text-[14px]"
          >
            <span v-html="SettingIcon"></span>
            <span class="whitespace-nowrap notosans500 text-[14px] leading-[18px]">{{
              $t("common.payment_method_settings")
            }}</span>
          </button>
        </div>
      </div>

      <div :class="styles.row2">
        <span :class="`${styles.title}`">{{ $t("common.amount_of_money") }}</span>
        <span :class="`${styles.info} notosans700 text-[16px] text-[#1D2939]`">{{
          `¥${formatDollar(totalPay / 1000)}`
        }}</span>
      </div>
      <span :class="styles.line"> </span>
      <div :class="styles.row3">
        <span :class="`${styles.title}`">{{ $t("common.next_withdrawal_date") }}</span>
        <span
          v-if="packageActive === 1"
          :class="`${styles.info} notosans400 text-[16px] text-[#1D2939]`"
        >
          {{ dateFormatForLanguage }}
        </span>
        <span
          v-if="packageActive === 2"
          :class="`${styles.info} notosans400 text-[16px] text-[#1D2939]`"
        >
          {{ dateFormatForLanguage + "  &  " + datePaymentSecond }} 
        </span>
      </div>
      <span :class="styles.line"> </span>
      <div :class="styles.row5">
        <span :class="`${styles.title}`">{{
          $t('common.payment_method')
        }}</span>
        <div :class="`${styles.info}`">
          <div :class="`${styles.notify}`">
            <div
              :class="`${styles.subtitle} notosans500 text-[16px] leading-[20px] text-[#1D2939] mb-[12px]`"
            >
              {{ $t("common.plan1_free") }}
            </div>
            <div :class="`${styles.notifyTypo} `">
              {{ $t("common.withdrawal_notify") }}
            </div>
            <div
              :class="`${styles.option} notosans400 text-[14px] text-[#667085] leading-[18px]`"
            >
              {{ $t("common.withdrawal_example_plan_1") }}
            </div>
            <div
              :class="`${styles.option} notosans400 text-[14px] text-[#667085] leading-[18px]`"
            >
              {{ $t("common.withdrawal_example_plan_2") }}
              {{ $t("common.withdrawal_total_amount") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="styles.historWithdrawal">
      <div :class="`${styles.title} w-full my-[20px] px-[20px]`">
        <span class="notosans700 text-[20px] text-black h-[28px]">{{
          $t("common.withdrawal_history")
        }}</span>
      </div>
      <div :class="`${styles.table}`">
        <div :class="`${styles.scrollBar} scroll-bar`">
          <table :class="`${styles.tableWithdrawalHistory}`">
            <thead>
              <tr>
                <th :class="`${styles.th}`">
                  {{ t("common.withdrawal_date") }}
                </th>
                <th :class="`${styles.th}`">
                  {{ t("common.number_of_users") }}
                </th>
                <th :class="`${styles.th}`">
                  {{ t("common.withdrawal_amount") }}
                </th>
                <th :class="`${styles.th}`"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in [1]" key="i">
                <td :class="`${styles.td}`">-</td>
                <td :class="`${styles.td}`">0</td>
                <td :class="`${styles.td}`">¥0</td>
                <td :class="`${styles.td}`">
                  <span v-html="DetailIcon1" v-on:click="showFormHistoryDetails"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        :class="`${styles.pagination} paginationWithDr border-solid border-t-[1px] border-[#D0D5DD]`"
      >
        <a-pagination
          v-model:currentPage="currentPage"
          :responsive="true"
          :total="10"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { DetailIcon1, SettingIcon } from "../../assets/constant/svg_exports";
import styles from "../../assets/css/views/_withdrawal.module.scss";
import { addModal, formatDateForEn, formatDateForJapanese, formatDollar } from "../../helpers";
import FormDetailWithdrawal from "./components/FormDetailWithdrawal.vue";
import FormHistoryWithdrawal from "./components/FormHistoryWithdrawal.vue";
import FormPaymentMethodSettings from "./components/FormPaymentMethodSettings.vue";
const { t } = useI18n();
const store = useStore();
const routes = ref([
  {
    path: "/",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/withdrawal",
    breadcrumbName: t("common.withdrawal"),
  },
]);
const selectedShop = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const renewPricings = computed(() => {
  return store.state.renewPricings ? store.state.renewPricings : [];
});
const packageActive = ref(localStorage.getItem("packageActive") ? Number(localStorage.getItem("packageActive")) : 1);
const currentPage = ref(1);
const totalPay = ref(0);
const dateInMonthNowAndBefore21 = (date) => {
  const dateToCheck = new Date(date);
  const now = new Date();
  const isSameMonth =
    dateToCheck.getUTCFullYear() === now.getUTCFullYear() &&
    dateToCheck.getUTCMonth() === now.getUTCMonth();
  const isBefore21st = dateToCheck.getUTCDate() < 21;
  return isSameMonth && isBefore21st;
};
watch(renewPricings, () => {
  const filterInMonthNow = renewPricings.value?.filter((item) => {
    return dateInMonthNowAndBefore21(item?.start_date);
  });
  totalPay.value = filterInMonthNow.reduce((acc, curr) => {
    console.log(curr.amount);
    return acc + curr.amount;
  }, 0);
});

const datePaymentOption = computed(() => {
  const now = new Date();
  const year = Number(now.getFullYear());
  const month = Number(now.getMonth());
  if (packageActive.value === 1) {
    return new Date(month === 11 ? year + 1 : year, month === 11 ? 1 : month + 2, 5);
  } else {
    return new Date(month === 11 ? year + 1 : year, month === 11 ? 0 : month + 1, 20);
  }
});

const datePaymentSecond =  computed(() => {
  const now = new Date();
  const year = Number(now.getFullYear());
  const month = Number(now.getMonth());
  const datePayment =  new Date(month === 11 ? year + 1 : year, month === 11 ? 1 : month + 2, 5);
  return localStorage.getItem("lang") === "en"
    ? formatDateForEn(datePayment)
    : formatDateForJapanese(datePayment);
})

const dateFormatForLanguage = computed(() => {
  return localStorage.getItem("lang") === "en"
    ? formatDateForEn(datePaymentOption.value)
    : formatDateForJapanese(datePaymentOption.value);
});

const showFormDetails = () => {
  addModal(FormDetailWithdrawal);
};
const showFormHistoryDetails = () => {
  addModal(FormHistoryWithdrawal);
};
const showFormPaymentMethodSettings = () => {
  addModal(FormPaymentMethodSettings, {
    packageActiveNow: packageActive.value,
    handleSavePackage: (value) => {
      packageActive.value = value;
      localStorage.setItem("packageActive", value);
    },
  });
};
onMounted(() => {
  {
    store.dispatch("getRenewPricing", {
      shop: selectedShop?.value?.store_id,
    });
  }
});
</script>
