<template>
  <div
    :class="{
      'user-detail-point-container rounded-lg bg-[#F9FAFB] pb-5': true,
      isCollapseSideBarUserDetailPoint: isCollaspseSideBar,
      isHideUserDetailPoint: isHideSidebar
    }"
  >
    <div class="user-detail-point-breadcrumb px-6 mt-[10px]">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div class="table-header-point flex justify-between items-center px-6 py-4">
      <div
        class="table-header-left text-title-info-text font-bold text-[24px] leading-[36px] notosans700 flex"
      >
        {{ $t('common.point') }}
        {{
          toggleDatetimeButton === 0
            ? dayValueHeader
            : toggleDatetimeButton == 1
            ? monthValueHeader
            : yearValueHeader
        }}
      </div>
      <div class="table-header-middle">
        <div
          class="point bg-[#EF6820] px-5 py-[11px] text-center text-white rounded-lg whitespace-nowrap"
        >
          <span class="font-[400] notosans400 text-[16px]">{{
            $t('common.store_point')
          }}</span>
          <span class="font-[700] notosans700 text-[16px]"
            >: {{ totalPoint }}</span
          >
        </div>
      </div>
      <div class="table-header-right flex gap-2 items-center">
        <div class="notify flex items-center gap-2">
          <span v-html="notifyIcon"></span>
          <span
            class="whitespace-nowrap notosans400 text-[#475467] text-[16px] leading-[20px]"
            >{{ $t('common.exchange_notify') }}</span
          >
        </div>
        <div
          class="label-time-picker flex items-center border-[1px] border-[#D0D5DD] rounded-lg"
        >
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('decrease')"
          >
            <span v-html="previousIcon"></span>
          </button>

          <a-date-picker
            class="point-time-picker"
            :format="
              toggleDatetimeButton === 0
                ? dayFormat
                : toggleDatetimeButton == 1
                ? monthFormat
                : yearFormat
            "
            :picker="
              toggleDatetimeButton === 0
                ? ''
                : toggleDatetimeButton == 1
                ? 'month'
                : 'year'
            "
            :allowClear="false"
            v-model:value="dayValue"
            @change="handleDatePickerChange"
            :locale="locale"
          >
            <template #suffixIcon>
              <div></div>
            </template>
          </a-date-picker>
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('increase')"
          >
            <span v-html="nextIcon"></span>
          </button>
        </div>
        <div
          class="time-button flex bg-white rounded-lg border-[1px] border-solid border-[#D0D5DD]"
        >
          <button
            :class="{
              'day px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] notosans400 whitespace-nowrap': true,
              isDayActive: toggleDatetimeButton === 0
            }"
            @click="toggleDatePickerClick(0)"
          >
            {{ $t('common.day') }}
          </button>
          <button
            :class="{
              'day px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] notosans400 whitespace-nowrap border-x-[1px] border-x-[#D0D5DD]': true,
              isPointMonthActive: toggleDatetimeButton === 1
            }"
            @click="toggleDatePickerClick(1)"
          >
            {{ $t('common.month') }}
          </button>
          <button
            :class="{
              'day px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] notosans400 whitespace-nowrap': true,
              isYearActive: toggleDatetimeButton === 2
            }"
            @click="toggleDatePickerClick(2)"
          >
            {{ $t('common.year') }}
          </button>
        </div>
      </div>
    </div>
    <PointTableByDay
      v-if="toggleDatetimeButton === 0"
      :onChangePoint="handleChangeTotalPoint"
    />
    <PointTableByMonth
      :onNaviSeeOfDate="handleChangeMonthToDay"
      v-if="toggleDatetimeButton === 1"
      :onChangePoint="handleChangeTotalPoint"
    />
    <PointTableByYear
      :onNaviSeeOfMonth="handleChangeYearToMonth"
      v-if="toggleDatetimeButton === 2"
      :onChangePoint="handleChangeTotalPoint"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import moment from 'moment';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  arrorwLeftIcon,
  nextIcon,
  notifyIcon,
  previousIcon
} from '../../assets/constant/constant';
import {
  CalendarFilledIcon,
  DetailIcon1
} from '../../assets/constant/svg_exports';
import { formatDate, getCurrentDate } from '../../helpers';
import PointTableByDay from './components/PointTableByDay.vue';
import PointTableByMonth from './components/PointTableByMonth.vue';
import PointTableByYear from './components/PointTableByYear.vue';
import 'dayjs/locale/ja';
dayjs.locale('ja');
export default {
  name: 'Point',
  components: { PointTableByDay, PointTableByMonth, PointTableByYear },
  setup() {
    const store = useStore();
    const router = useRouter();
    const totalPoint = ref(0);
    const userDetail = computed(() => {
      return store.state.userDetails;
    });
    const languageToggle = localStorage.getItem('lang') || 'ja';
    const toggleDatetimeButton = ref(
      Number(localStorage.getItem('toggleDatetimeButton')) || 0
    );
    const { t } = useI18n();
    const toggleDateMonth = ref(true);
    const dayFormat = languageToggle === 'ja' ? 'YYYY年M月D日' : 'YYYY/M/D';
    const monthFormat = languageToggle === 'ja' ? 'YYYY年M月' : 'YYYY/M';
    const yearFormat = languageToggle === 'ja' ? 'YYYY年' : 'YYYY';
    const currentDay = ref(
      dayjs(localStorage.getItem('datePoint') || getCurrentDate(), 'YYYY-MM-DD')
    );
    const dayValue = ref(currentDay);
    const isCollaspseSideBar = computed(() => store.state.collapseSidebar);
    const dayValueHeader = ref(
      languageToggle === 'ja'
        ? `(${moment(dayValue.value.$d).format('YYYY年M月D日')})`
        : `(${moment(dayValue.value.$d).format('YYYY/M/D')})`
    );
    const monthValueHeader = ref(
      languageToggle === 'ja'
        ? `(${moment(dayValue.value.$d).format('YYYY年M月')})`
        : `(${moment(dayValue.value.$d).format('YYYY/M')})`
    );
    const yearValueHeader = ref(
      `(${moment(dayValue.value.$d).format('YYYY年')})`
    );
    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
    };
    const toggleDatePickerClick = (index) => {
      toggleDatetimeButton.value = index;
      localStorage.setItem('toggleDatetimeButton', index);
    };
    const calculateItem = (item) => {
      return 100 + 100 * item;
    };
    const handlePushDetailPointByCategory = () => {
      router.push();
    };
    const handleChangeTotalPoint = (point) => {
      totalPoint.value = point;
    };
    //breadcrumb
    const routes = ref([
      {
        path: '/',
        breadcrumbName: t('common.home')
      },
      {
        path: '/point',
        breadcrumbName: t('common.point')
      }
    ]);

    const handleTypeDateChange = async () => {
      await router.isReady();
      if (toggleDatetimeButton.value === 0) {
        localStorage.setItem(
          'datePoint',
          moment(dayValue.value.$d).format('YYYY-MM-DD')
        );
        router.push(
          `/point?date=${moment(dayValue.value.$d).format('YYYY-MM-DD')}`
        );
      } else if (toggleDatetimeButton.value === 1) {
        localStorage.setItem(
          'datePoint',
          moment(dayValue.value.$d).format('YYYY-MM-DD')
        );
        router.push(
          `/point?month=${moment(dayValue.value.$d).format('YYYY-MM')}`
        );
      } else {
        localStorage.setItem(
          'datePoint',
          moment(dayValue.value.$d).format('YYYY-MM-DD')
        );
        router.push(`/point?year=${moment(dayValue.value.$d).format('YYYY')}`);
      }
    };

    watch([toggleDatetimeButton, dayValue], async () => {
      handleTypeDateChange();
      handleDatePickerChange();
    });

    //custom function
    const handleDatePickerChange = () => {
      switch (toggleDatetimeButton.value) {
        case 0:
          dayValueHeader.value =
            languageToggle === 'ja'
              ? `(${moment(dayValue.value.$d).format('YYYY年M月D日')})`
              : `(${moment(dayValue.value.$d).format('YYYY/M/D')})`;
          break;
        case 1:
          monthValueHeader.value =
            languageToggle === 'ja'
              ? `(${moment(dayValue.value.$d).format('YYYY年M月')})`
              : `(${moment(dayValue.value.$d).format('YYYY/M')})`;
          break;
        case 2:
          yearValueHeader.value =
            languageToggle === 'ja'
              ? `(${moment(dayValue.value.$d).format('YYYY年')})`
              : `(${moment(dayValue.value.$d).format('YYYY')})`;
          break;
      }
    };

    const handleChangeMonthToDay = (date) => {
      dayValue.value = dayjs(date, 'YYYY-MM-DD');
      toggleDatePickerClick(0);
    };

    const handleChangeYearToMonth = (date) => {
      dayValue.value = dayjs(date, 'YYYY-MM');
      toggleDatePickerClick(1);
    };
    const handleIncreaseOrDecreaseDateTime = (type) => {
      switch (toggleDatetimeButton.value) {
        case 0:
          type == 'increase'
            ? (dayValue.value = dayValue.value.add(1, 'day'))
            : (dayValue.value = dayValue.value.subtract(1, 'day'));
          break;
        case 1:
          dayValue.value =
            type == 'increase'
              ? (dayValue.value = dayValue.value.add(1, 'month'))
              : (dayValue.value = dayValue.value.subtract(1, 'month'));
          break;
        case 2:
          dayValue.value =
            type == 'increase'
              ? (dayValue.value = dayValue.value.add(1, 'year'))
              : (dayValue.value = dayValue.value.subtract(1, 'year'));
          break;
      }
    };
    onMounted(() => {
      handleTypeDateChange();
    });

    onUnmounted(() => {
      localStorage.removeItem('datePoint');
      localStorage.removeItem('toggleDatetimeButton');
    });

    return {
      toggleDateMonth,
      dayValueHeader,
      monthValueHeader,
      yearValueHeader,
      CalendarFilledIcon,
      languageToggle,
      dayValue,
      dayFormat,
      monthFormat,
      yearFormat,
      monthFormat,
      isCollaspseSideBar,
      isHideSidebar: computed(() => store.state?.hideSidebar),
      handleToggleDateMonthClick,
      DetailIcon1,
      calculateItem,
      userDetail,
      //const
      toggleDatetimeButton,
      totalPoint,
      //function
      handlePushDetailPointByCategory,
      toggleDatePickerClick,
      handleDatePickerChange,
      handleChangeMonthToDay,
      handleChangeYearToMonth,
      handleIncreaseOrDecreaseDateTime,
      handleChangeTotalPoint,
      //svg
      arrorwLeftIcon,
      notifyIcon,
      nextIcon,
      previousIcon,
      //breadcrumb
      routes,
      //locale
      locale
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/views/_user_gift_table.scss';
@import '../../assets/css/views/_ant_custom.scss';
</style>