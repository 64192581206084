<template>
  <table>
    <tr>
      <th v-for="item in thead" :key="item">
        {{ item }}
      </th>
    </tr>
    <!-- <tr v-if="!tbody">
      <td>0</td>
      <td>00:00:00</td>
      <td>0</td>
      <td>0</td>
      <td>0</td>
      <td>0</td>
      <td>0</td>
      <td>0</td>
      <td v-if="table_type == 'Day'">#</td>
    </tr> -->
    <tr v-for="(item, index) in tbody" :key="item">
      <td v-if="languageToggle === 'ja' && table_type == 'Day'">
        {{ index + 1 }} {{ date_type }}
      </td>
      <td v-else-if="languageToggle === 'en' && table_type == 'Day'">
        {{ date_type }} {{ index + 1 }}
      </td>
      <td v-else-if="languageToggle === 'ja' && table_type == 'Month'">
        {{ index + 1 }} {{ date_type }}
      </td>
      <td v-else-if="languageToggle === 'en' && table_type === 'Month'">
        {{ months[index] }}
      </td>
      <td>{{ item?.streaming_times || 0 }}</td>
      <td>{{ item?.livestream_duration || '00:00:00' }}</td>
      <td>{{ item?.gifting || 0 }}</td>
      <td>{{ item?.number_of_viewers || 0 }}</td>
      <td>{{ item?.comment || 0 }}</td>
      <td>{{ item?.new_follower || 0 }}</td>
      <td>{{ item?.share || 0 }}</td>
      <td
        v-if="item?.icon"
        class="cursor-pointer"
        v-html="item?.icon"
        @click="handleClickFunction"
      ></td>
    </tr>
  </table>
</template>
<script>
export default {
  name: 'DataTable',
  props: {
    thead: {
      type: Array,
      required: true
    },
    tbody: {
      type: Array,
      required: true
    },
    date_type: {
      type: String,
      required: true
    },
    table_type: {
      type: String,
      required: true
    },
    handleClickFunction: {
      type: Function
    }
  },
  setup(props) {
    const languageToggle = localStorage.getItem('lang') || 'ja';
    return {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      languageToggle
    };
  }
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

td,
th {
  border-bottom: 1px solid #d0d5dd;
  text-align: left;
  padding: 12px;
  color: white;
}
th {
  text-align: center;
  background-color: var(--brg-header-table);
  font-family: 'NotoSansJP_700';
  border-bottom: none;
  font-size: 14px;
  width: fit-content;
  white-space: nowrap;
  position: sticky;
  top: 0;
}
td {
  max-width: 100px;
  text-align: center;
  font-family: 'NotoSansJP_400';
  color: black;
  white-space: nowrap;
  border-bottom: 1px solid #d0d5dd;
}
td:nth-child(1) {
  font-weight: 700;
  color: #475467;
}
tr:nth-child(even) {
  background-color: #ffffff;
}
/* tr:nth-child(odd) {
  background-color: #f9fafb;
} */
</style>
