<template>
  <div class="confirm-modal">
    <div class="icon">
      <span v-html="ConfirmIcon"></span>
    </div>
    <div class="title">
      {{ modalInfo.title }}
    </div>
    <div class="question">
      {{ modalInfo.question }}
    </div>
    <div class="btns">
      <button class="outline-primary" v-on:click="handleClose">
        {{ modalInfo.textBtn1 }}
      </button>
      <button class="outline-primary" v-on:click="handleNext">
        {{ modalInfo.textBtn2 }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ConfirmIcon } from "../../assets/constant/svg_exports";

const { t } = useI18n();

const props = defineProps({
  modalInfo: {
    type: Object,
    default: () => ({}),
  },
  onClose: {
    type: Function,
    required: true,
  },
  onNext: {
    type: Function,
    required: true,
  },
});

const defaultModalInfo = computed(() => ({
  title: t("common.delete_account"),
  question: t("common.are_u_sure_delete"),
  textBtn1: t("common.cancel_delete"),
  textBtn2: t("common.delete"),
}));

const modalInfo = computed(() => ({
  ...defaultModalInfo.value,
  ...props.modalInfo,
}));

const handleClose = () => {
  props.onClose();
};

const handleNext = () => {
  props.onClose();
  props.onNext();
};
</script>

<style lang="scss">
.confirm-modal {
  background-color: white;
  border-radius: 8px;
  min-width: 550px;
  min-height: 188px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  .title {
    font-family: "NotoSansJP_700";
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    color: rgba(29, 41, 57, 1);
    margin: 4px 0px 12px 0px;
  }
  .question {
    font-family: "NotoSansJP_400";
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 20px;
  }
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    gap: 12px;
    padding: 12px 20px;
    border-top: 1px solid rgba(208, 213, 221, 1);
    button {
      width: 228px;
      height: 40px;
      padding: 12px 20px;
      font-family: "NotoSansJP_500";
    }
    button:first-child {
      color: rgba(71, 84, 103, 1);
      border-color: rgba(208, 213, 221, 1);
    }
    button:last-child {
      color: #f04438;
      border-color: #f04438;
    }
  }
}
</style>
