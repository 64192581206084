<template>
  <div class="wrapper bg-white max-w-[600px] h-fit rounded-lg">
    <div
      class="top text-center px-[220px] py-6 notosans700 text-[16px] text-[#1D2939] border-b-[1px] border-b-[#D0D5DD]"
    >
      {{ $t('common.would_you_like_to_renew_your_contract') }}
    </div>
    <div class="footer h-[50px] flex items-center">
      <div
        class="left px-28 h-full cursor-pointer rounded-lg flex items-center notosans500"
        @click="onClose"
      >
        {{ $t('common.cancel_delete') }}
      </div>
      <div class="divider h-full w-[1px] bg-[#D0D5DD]"></div>
      <div
        class="right px-28 h-full whitespace-nowrap cursor-pointer flex items-center notosans500 text-[#F38744]"
        @click="handleSubmit"
      >
        {{ $t('common.confirm') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RenewContractModal',
  props: {
    onClose: {
      type: Function,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const handleSubmit = () => {
      props.onClose();
      props.onSubmit();
    };
    return {
      handleSubmit
    };
  }
};
</script>
<style lang="scss" scoped>
</style>