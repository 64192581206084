import { showAlert } from "../../helpers";

export const storesMutations = {
    GET_SHOP_LIST(state, payload) {
        state.shopList = payload;
    },

    SET_SELECTED_SHOP(state, payload) {
        state.selectedShop = payload;
    },
    
    REGISTER_SHOP(state, payload) {
        state.userInfo = payload;
        showAlert(
            'success',
            localStorage.getItem('lang') === 'en'
             ? 'Sign up success'
              : 'サインアップの成功',
            'top-center'
          );
    },

    GET_AREA_LIST(state, payload) {
        state.areaList = payload;
    },

    REMOVE_STORE_LIST(state){
        state.shopList = [];
    }
}