<template lang="">
    <div>
        <a-spin :indicator="indicator" size="large" />
    </div>
</template>
<script>
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h } from 'vue';

export default {
  name: 'LoadingOverlay',
  setup() {
    //Loading
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px'
      },
      spin: true
    });

    return indicator;
  }
};
</script>
<style lang="">
</style>