<template>
  <div
    :class="{
      'user-detail-point-container rounded-lg bg-white pb-5': true,
      isCollapseSideBarUserDetailPoint: isCollaspseSideBar,
      isHideUserDetailPoint: isHideSidebar
    }"
  >
    <div class="user-detail-point-breadcrumb px-6 mt-[10px]">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div
      class="table-header-point-by-category flex justify-between items-center px-[20px] py-4"
    >
      <div
        class="table-header-left text-title-info-text font-bold text-[24px] leading-[36px] notosans700 flex"
      >
        <button
          class="mr-3"
          v-html="arrorwLeftIcon"
          @click="handleGoBackToPreviousPage"
        ></button>
        {{ monthValueHeader }}
      </div>
      <div class="table-header-right flex gap-2">
        <div
          class="point bg-[#EF6820] px-5 py-3 w-[180px] text-center text-white rounded-lg"
        >
          <span class="font-[400]">{{ $t('common.point') }}</span>
          <span class="font-[700]">: {{ calculateTotalPoint }}</span>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-body scroll-bar overflow-y-auto px-6">
        <table class="user-point-detail-table">
          <tr class="sticky top-0">
            <th>
              {{ $t('common.giver') }}
            </th>
            <th>
              {{ $t('common.quantity') }}
            </th>
            <th>{{ $t('common.point') }}</th>
          </tr>
          <tr
            v-for="(i, index) in userGiftPointDetailByCategory"
            :key="index"
          >
            <td v-if="i?.userSend">
              <div class="flex-thead">
                {{ (i?.userSend && i?.userSend[0]?.nickName) }}
              </div>
            </td>
            <td v-if="i?.userSend">{{ (i?.userSend && i?.countGift) }}</td>
            <td v-if="i?.userSend">{{ (i?.userSend && i?.totalPoints)  }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import moment from 'moment';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { arrorwLeftIcon } from '../../../../assets/constant/constant';
import {
  CalendarFilledIcon,
  DetailIcon1
} from '../../../../assets/constant/svg_exports';
import { getCurrentDate, getCurrentMonth } from '../../../../helpers';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
export default {
  name: 'UserPointDetailByCategoryTab',
  setup() {
    const store = useStore();
    const route = useRoute();
    const userDetail = computed(() => {
      return store.state.userDetails;
    });
    const giftDetail = computed(() => {
      return store.state.giftDetail;
    });
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const userGiftPointDetailByCategory = computed(
      () => store.state.userGiftPointDetailByCategory || []
    );

    const userId = ref('');
    const categoryType = ref('');
    const { t } = useI18n();
    const toggleDateMonth = ref(true);
    const currentDay = ref(dayjs(getCurrentDate(), 'YYYY-MM-DD'));
    const currentMonth = ref(dayjs(getCurrentMonth(), 'YYYY-MM'));
    const dayValue = ref(currentDay);
    const monthValue = ref(currentMonth);
    const monthFormat = 'YYYY/MM';
    const yearFormat = 'YYYY';
    const router = useRouter();
    const isCollaspseSideBar = computed(() => store.state?.collapseSidebar);
    const isHideSidebar = computed(() => store.state?.hideSidebar);
    const languageToggle = localStorage.getItem('lang') || 'ja';
    let monthValueHeader = ref(
      languageToggle === 'ja'
        ? `${userDetail.value?.nickName || '-'} (${
            giftDetail.value?.name || '-'
          } - ${moment(route.query.date).format('YYYY年MM月DD日')})`
        : `${giftDetail.value?.name}(${
            userDetail.value?.nickName || '-'
          } - ${moment(route.query.date).format('YYYY/M/D')})`
    );

    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
    };
    const calculateItem = (item) => {
      return 100 + 100 * item;
    };
    //custom function
    const handleGoBackToPreviousPage = () => {
      router.back();
    };
    const updateBreadcrumb = () => {
      routes.value.forEach((item, index) => {
        if (index === 2) {
          item.path = `/user/details?user_id=${userId.value}`;
          item.breadcrumbName = userDetail.value?.nickName || '-';
        }
        if (index === 3) {
          item.path = `/user/user_point_detail?user_id=${route.query.user_id}`;
          item.breadcrumbName = t('common.point_detail') || '-';
        }
        if (index === 4) {
          item.path = `/user/user_point_detail/category?user_id=${route.query.user_id}&category=${categoryType.value}`;
          item.breadcrumbName = giftDetail.value?.name || '-';
        }
      });
    };
    const updateTitle = () => {
      monthValueHeader.value =
        languageToggle === 'ja'
          ? `${giftDetail?.value?.name || ''} (${
              userDetail?.value?.nickName || '-'
            } - ${moment(route.query.date).format('YYYY年MM月DD日')})`
          : `${giftDetail.value?.name || '-'}(${
              userDetail.value?.nickName || '-'
            } - ${moment(route.query.date).format('YYYY/M/D')})`;
    };
    //onMounted
    onMounted(() => {
      userId.value = route.query.user_id;
      // categoryType.value = route.query.category;
      const data = {
        type: route.query.type,
        shop: route.query.shop,
        type_get: route.query.type_get,
        date: route.query.date,
        gift: route.query.gift,
        userReceive: route.query.user_id
      };
      store.dispatch('getUserPointDetail', data);
      store.dispatch('getGiftDetail', route.query.gift);
    });
    //onunmounted
    onUnmounted(() => {
      store.commit('RESET_USER_POINT_DETAIL_BY_CATEGORY');
    });
    //watch
    watch(userId, () => {
      store.dispatch('getUserDetails', userId.value);
    });
    //breadcrumb
    const routes = ref([
      {
        path: '/',
        breadcrumbName: t('common.home')
      },
      {
        path: '/user',
        breadcrumbName: t('common.user')
      },
      {
        path: `/details?user_id=${userId.value}`,
        breadcrumbName: userDetail.value?.nickName || '-'
      },
      {
        path: `/user_point_detail?user_id=${userId.value}`,
        breadcrumbName: t('common.point_detail')
      },
      {
        path: `/user_point_detail/category?user_id=${userId.value}&category=${categoryType}`,
        breadcrumbName: giftDetail.value?.name
      }
    ]);
    watch(() => {
      updateBreadcrumb();
      updateTitle();
    });
    const calculateTotalPoint = computed(() =>
      userGiftPointDetailByCategory?.value?.reduce(
        (acc, item) => acc + item.totalPoints,
        0
      )
    );
    return {
      months,
      toggleDateMonth,
      monthValueHeader,
      CalendarFilledIcon,
      languageToggle,
      monthValue,
      yearFormat,
      monthFormat,
      isCollaspseSideBar,
      isHideSidebar,
      handleToggleDateMonthClick,
      DetailIcon1,
      calculateItem,
      userDetail,
      //const
      categoryType,
      userGiftPointDetailByCategory,
      calculateTotalPoint,
      //function
      handleGoBackToPreviousPage,
      //svg
      arrorwLeftIcon,
      //breadcrumb
      routes
    };
  }
};
</script>
<style lang="scss">
@import '../../../../assets/css/views/_user_gift_table.scss';
@import '../../../../assets/css/views/ant_custom';
.isHideUserDetailPoint {
  width: 100vw !important;
}
.table-header-point-by-category {
  @media (max-width: 750px) {
    flex-direction: column;
    .table-header-left {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .table-header-right {
      margin-top: 10px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>