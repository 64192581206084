<template>
  <div class="user pt-[10px] pb-[50px] overflow-hidden px-[24px]">
    <div class="link notosans400 h-[20px]">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div class="title w-full flex justify-between items-center mt-[20px] h-[36px]">
      <span class="text-[24px] notosans600">{{ t("common.user") }}</span>
      <button class="fill-primary-text-white w-192-h-44" v-on:click="handleAddUser">
        <span class="iconUser" v-html="AddUserIcon"></span>
        <span class="text">{{ $t("common.create_user") }}</span>
      </button>
    </div>
    <div v-if="users && users.length > 0 && !loading" :class="{'userBox min-h-[65vh] flex justify-center items-center': true, 'pt-[35px]': users.length < 10}">
      <div
        class="user-list w-full ml-[24px] mr-[24px] gap-[24px] mt-[24px]"
      >
        <UserItem v-for="user in usersByPage" :key="user.user_id" :user="user" />
      </div>
    </div>
    <div
      class="w-full min-h-[65vh] flex justify-center items-center"
      v-if="users && users.length > 0 && loading"
    >
      <a-spin :indicator="indicator" />
    </div>
    <div v-if="users?.length === 0" :class="{'min-h-[65vh] flex justify-center items-start': true, 'pt-[35px]': true}">
      <div
        class="w-full flex justify-center items-center flex-wrap ml-[24px] mr-[24px] gap-[24px] mt-[24px]"
      >
        <a-skeleton-button
          v-if="loading === true"
          v-for="index in 10"
          :key="index"
          :active="active"
          :size="200"
          :shape="buttonShape"
          :block="block"
        />
        <div
          class="w-full h-[60vh] flex justify-center items-center flex-wrap"
          v-if="loading === false"
        >
          <EmptyData />
        </div>
      </div>
    </div>
    <div
      :class="{ 'nav-page mt-[35px] w-1/1 h-[40px] mx-[30px]': true }"
      v-if="users?.length > 0"
    >
      <a-pagination
        v-model:current="pageCurrent"
        :total="usersLen"
        :pageSize="pageSize"
        :showSizeChanger="false"
        :responsive="true"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'" class="btn-navi">
            <span v-html="ArrowLeft"></span>
            <span>{{ $t("common.previous") }}</span>
          </a>
          <a v-else-if="type === 'next'" class="btn-navi">
            <span>{{ $t("common.next") }}</span>
            <span v-html="ArrowRight"></span>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { computed, h, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { AddUserIcon, ArrowLeft, ArrowRight } from "../../assets/constant/svg_exports";
import { addModal, formatDate, isPositiveInteger } from "../../helpers";
import EmptyData from "../master/EmptyData.vue";
import FormAddUser from "./components/FormAddUser.vue";
import UserItem from "./components/UserItem.vue";

const store = useStore();
const { t } = useI18n();
const pageCurrent = ref(0);
const pageSize = ref(15);
const totalItem = ref(0);
const loading = ref(true);
const router = useRouter();
const route = useRoute();
const routes = ref([
  {
    path: "/",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/user",
    breadcrumbName: t("common.user"),
  },
]);
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "40px",
    color: "rgba(239, 104, 32, 1)",
  },
  spin: true,
});
const usersLen = computed(() => {
  return store.getters.userListLen() || 0;
});
const totalUserCanCreate = computed(() =>
  store.state.totalUserCanCreate ? store.state.totalUserCanCreate : 0
);
const users = computed(() => (store.state.userList ? store.state.userList :  []));
const selectedShop = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const usersByPage = computed(() => {
  return store.getters.userListByPageGetters({
    page: pageCurrent.value,
    pageSize: pageSize.value,
  });
});
const handleAddUser = () => {
    addModal(FormAddUser);
};
watch(pageCurrent, (newValue) => {
  if (isPositiveInteger(newValue)) {
    loading.value = true;
    localStorage.setItem("pageNumberUserActive", newValue);
    router.push(`/user?page=${newValue}`);
    setTimeout(() => {
      loading.value = false;
    }, 1000);
  }
});
onMounted(() => {
  if (selectedShop?.value?.store_id) {
    store.dispatch("getUserList", selectedShop?.value?.store_id);
    const pageNumberUserActive = Number(localStorage.getItem("pageNumberUserActive"));
    pageCurrent.value = route.query.page
      ? route.query.page
      : pageNumberUserActive
      ? pageNumberUserActive
      : 1;
    store.dispatch("getTotalUserCanCreate", {
      time: formatDate(new Date().setUTCDate(new Date().getUTCDate() + 1)),
      shopId: selectedShop?.value?.store_id,
    });
    setTimeout(() => {
      loading.value = false;
    }, 3000);
    localStorage.removeItem('userDetailTab');
  }
});
</script>
<style lang="scss">
@import "../../assets/css/views/_user.scss";
.user {
  min-height: calc(100vh - 60px);
}

.link-focus {
  color: var(--primary-color) !important;
}

.ant-pagination-item {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-align: center !important;
  border: none !important;
  font-family: "NotoSansJP_300" !important;
  font-size: 14px !important;

  a {
    color: var(--color-text-gray-dark) !important;
  }
}

.ant-pagination-item-active {
  a {
    color: var(--color-text-light) !important;
  }

  background-color: var(--primary-color) !important;
}

.ant-pagination-next,
.ant-pagination-prev {
  a {
    font-size: 14px;
    font-family: "NotoSansJP_600" !important;
    color: var(--primary-color);
  }

  path {
    stroke: var(--primary-color);
  }
}

.ant-pagination-disabled {
  a {
    color: var(--color-text-gray) !important;
  }

  path {
    stroke: var(--color-text-gray);
  }
}

.btn-navi {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
</style>
