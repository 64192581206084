<template>
  <div
    :class="`${styles.formBuyUser} flex flex-col justify-start items-center rounded-[12px] bg-white w-[1000px] h-[564px]`"
  >
    <div
      class="w-full title py-[12px] px-[20px] flex justify-between border-solid border-b-[1px] border-[#D0D5DD]"
    >
      <h2 class="form-name text-[20px] notosans600">
        {{ $t("common.add_a_user") }}
      </h2>
      <button class="btn-close-form notosans400 text-[13px]" v-on:click="onClose">
        {{ $t("common.close") }}
      </button>
    </div>
    <div
      :class="`${styles.container} w-full flex-1 flex flex-col justify-start items-center gap-[16px] p-[20px]`"
    >
      <div
        :class="`${styles.header} w-full h-[40px] flex justify-between items-center gap-[20px] px-[10px]`"
      >
        <h3
          :class="`${styles.title} notosans700 text-center text-[20px] leading-[30px] text-[#1d2939]`"
        >
          HOST COLLECTION
        </h3>
        <div
          :class="` ${styles.boxSearch} w-[228px] h-[40px] flex justify-center items-center rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[16px] py-[8px] bg-white`"
        >
          <label
            for="search"
            :class="`${styles.inputLabel} flex justify-center items-center gap-[8px] notosans-400 text-[16px] leading-[24px] text-[#1D2939]`"
          >
            {{ emptyString }}
            <input
              id="search"
              v-model="searchKey"
              type="text"
              :placeholder="`${t('common.search')}`"
              :class="`${styles.input} w-[168px] h-[24px] outline-none`"
            />
            <span class="cursor-pointer" v-html="SearchIcon" v-if="!searchLoading"></span>
            <span
              class="flex justify-center items-center w-[18px] h-[18px]"
              v-if="searchLoading"
            >
              <a-spin :indicator="indicator" />
            </span>
          </label>
        </div>
      </div>
      <div
        :class="`${styles.scrollBar} w-full flex-1 max-h-[348px] overflow-y-auto px-[10px]`"
      >
        <div
          :class="`${styles.users} w-full flex justify-start items-start flex-wrap gap-[16px] overflow-hidden`"
        >
          <UserItemFormBuy
            v-for="user in userListPendingRender"
            :key="user"
            :user="user"
            :splitUserInBuyList="splitUserInBuyList"
            :pushUserToBuyList="pushUserToBuyList"
          />
        </div>
      </div>
    </div>
    <div
      :class="`${styles.footer} w-full h-[66px] flex justify-between items-center py-[12px] px-[16px] border-solid border-t-[1px] border-[#D0D5DD]`"
    >
      <div :class="`${styles.price} flex justify-center items-center gap-[10px]`">
        <span class="title notosans500 text-[20px] leading-[24px] text-[#1D2939]"
          >{{ $t("common.amount_of_money") }}:</span
        >
        <div class="amount flex justify-center items-center">
          <span class="numberMoney notosans700 text-[20px] leading-[24px] text-[#1D2939]"
            >¥{{
              userBuyList.length !== 0
                ? formatDollar(price_of_pricing * userBuyList.length)
                : 0
            }}</span
          >
          <span class="taxInclude notosans400 text-[16px] leading-[24px] text-[#667085]"
            >({{ $t("common.tax_included") }})</span
          >
        </div>
      </div>
      <button
        v-on:click="handleBuy"
        :class="`flex justify-center w-[120px] h-[42px] rounded-[8px] py-[12px] px-[16px] text-white bg-[#F38744] leading-[18px] notosans500 text-[14px]`"
      >
        {{ $t("common.send") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { computed, h, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { SearchIcon } from "../../../assets/constant/svg_exports";
import styles from "../../../assets/css/views/_form_buy_user.module.scss";
import { emptyString, formatDollar, showAlert, useDebounce } from "../../../helpers";
import UserItemFormBuy from "./UserItemFormBuy.vue";
const store = useStore();
const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
  price_of_pricing: {
    type: Number,
    required: true,
  },
  package_info: {
    type: Object,
    required: true,
  },
});
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "18px",
    color: "rgba(239, 104, 32, 1)",
  },
  spin: true,
});
const { t } = useI18n();
const searchKey = ref("");
const searchLoading = ref(false);
const userBuyList = ref([]);

const selectedShop = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);

const userListPending = computed(() =>
  store.state.userListPending ? store.state.userListPending : []
);

const userListPendingRender = ref([]);

const handleFilterUsers = (searchKey) => {
  const lowerSearchKey = searchKey.toLowerCase();
  const users = userListPending.value?.filter((user) => {
    return (
      user?.nickName?.toLowerCase().includes(lowerSearchKey) ||
      user?.userName?.toLowerCase().includes(lowerSearchKey) ||
      lowerSearchKey.includes(user?.nickName?.toLowerCase()) ||
      lowerSearchKey.includes(user?.userName?.toLowerCase())
    );
  });
  searchLoading.value = false;
  userListPendingRender.value = users;
};

const searchDebounce = useDebounce(handleFilterUsers, 500);

watch([searchKey], () => {
  searchDebounce(searchKey.value);
  searchLoading.value = true;
});

watch([userListPending], () => {
  userListPendingRender.value = userListPending.value;
});

const pushUserToBuyList = (idUser) => {
  userBuyList.value.push(idUser);
};

const splitUserInBuyList = (idUser) => {
  console.log(userBuyList.value.filter((id) => id !== idUser));
  userBuyList.value = userBuyList.value.filter((id) => id !== idUser);
};

const handleBuy = () => {
  if (userBuyList.value?.length > 0) {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    store.dispatch("upgradePricing", {
      pricingInfo: {
        title: props.package_info.title || "",
        amount: (props.package_info.price * userBuyList.value.length) || 0,
        number_user: userBuyList.value.length || 0,
        shop: selectedShop.value?.store_id,
        note: props.package_info.note || "",
        status: props.package_info.status || "",
        type: props.package_info.type || "",
        days: props.package_info.days || 0,
        packagePrice: props.package_info._id || "",
        start_date: startDate,
        price: props.package_info.price,
        user: userBuyList.value,
      },
      onSuccess: () => {
        props.onClose();
      },
    });
  } else {
    showAlert(
      "error",
      localStorage.getItem("lang") === "en"
        ? "Please select the user you want to activate"
        : "有効化するユーザーを選択してください",
      "top-center"
    );
  }
};

onMounted(() => {
  store.dispatch("getUserListPending", selectedShop.value?.store_id);
});
</script>
