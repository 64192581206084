<template>
  <div class="bank-account-container">
    <div class="title notosans700">{{ $t('common.bank_account') }}</div>
    <a-form :model="formState" @finish="onFinish" autocomplete="off">
      <div class="input-row">
        <div class="input-row-left notosans500">
          {{ $t('common.bank_name') }}
        </div>
        <div class="input-row-right">
          <a-form-item name="bankName" :rules="rules.bankName">
            <a-input v-model:value="formState.bankName" :placeholder="$t('common.input_bank_name_placeholder')"/>
          </a-form-item>
        </div>
      </div>
      <div class="input-row">
        <div class="input-row-left notosans500">
          {{ $t('common.branch_name') }}
        </div>
        <div class="input-row-right">
          <a-form-item name="bankBranchName" :rules="rules.bankBranchName">
            <a-input v-model:value="formState.bankBranchName" :placeholder="$t('common.input_bank_branch_name_placeholder')"/>
          </a-form-item>
        </div>
      </div>
      <div class="input-row">
        <div class="input-row-left notosans500">
          {{ $t('common.account_number') }}
        </div>
        <div class="input-row-right">
          <a-form-item
            name="bankAccountNumber"
            :rules="rules.bankAccountNumber"
          >
            <a-input
              :type="number"
              v-model:value="formState.bankAccountNumber"
              @keypress="handleKeyPress"
              :placeholder="$t('common.input_bank_account_number_placeholder')"
            />
          </a-form-item>
        </div>
      </div>
      <div class="input-row">
        <div class="input-row-left notosans500">
          {{ $t('common.name_of_holder') }}
        </div>
        <div class="input-row-right">
          <a-form-item name="bankNameOfHolder" :rules="rules.bankNameOfHolder">
            <a-input v-model:value="formState.bankNameOfHolder" :placeholder="$t('common.input_bank_name_holder_placeholder')"/>
          </a-form-item>
        </div>
      </div>
      <div class="input-submit-row">
        <div class="input-row-left"></div>
        <div class="input-row-right">
          <a-form-item>
            <button class="button-submit" type="submit">
              <label
                v-html="SaveSettingFilled"
                class="mr-[6px] cursor-pointer"
              ></label>
              {{ $t('common.save') }}
            </button>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
import { computed, reactive, watch } from 'vue';
import { SaveSettingFilled } from '../../assets/constant/constant';
import { useStore } from 'vuex';
import { addSpinner } from '../../helpers';
import LoadingOverlayVue from './components/LoadingOverlay.vue';
import { useI18n } from 'vue-i18n';
export default {
  name: 'StoreAddBankAccountTab',
  setup() {
    //store vuex
    const store = useStore();
    //using i18n
    const { t } = useI18n();
    //computed
    const loading = computed(() => store.state.loading);
    //form state
    const formState = reactive({
      bankName: '',
      bankBranchName: '',
      bankAccountNumber: '',
      bankNameOfHolder: ''
    });
    //function
    //handle submit button click
    const onFinish = (values) => {
      store.dispatch('setLoading', true);
      console.log('submit success: ', values);
      addSpinner(LoadingOverlayVue);
      setTimeout(() => store.commit('UPDATE_LOADING', false), 1000);
    };
    const handleKeyPress = (event) => {
      const charCode = event.charCode || event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    };
    //watch if loading == false, hide it
    watch(loading, (newLoading) => {
      if (newLoading === false) {
        const modalElement = document.querySelector(
          '.container-spinner-model-custom'
        );
        if (modalElement) {
          document.body.removeChild(modalElement);
        }
      }
    });
    //form rules
    const rules = {
      bankName: [
        {
          required: true,
          message: t('common.input_bank_name_alert')
        }
      ],
      bankBranchName: [
        {
          required: true,
          message: t('common.input_bank_branch_name_alert')
        }
      ],
      bankAccountNumber: [
        {
          required: true,
          message: t('common.input_bank_account_number_alert')
        }
      ],
      bankNameOfHolder: [
        {
          required: true,
          message: t('common.input_bank_name_holder_alert')
        }
      ]
    };
    return {
      //icon
      SaveSettingFilled,
      //formState
      formState,
      //handle submit
      onFinish,
      //handle input number key press
      handleKeyPress,
      //form rules
      rules
    };
  }
};
</script>
<style lang="scss" scoped>
$white-primary: white;
$gray-300: #d0d5dd;
$gray-500: #667085;
$gray-700: #344054;
$orange-500: #f38744;
.bank-account-container {
  background-color: white;
  padding: 20px;
  .title {
    color: $gray-700;
    font-size: 20px;
  }
  .input-submit-row,
  .input-row {
    padding: 24px 12px 12px 0;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid $gray-300;
    @media (max-width: 800px) {
        flex-direction: column;
    }
    .input-row-left {
      width: 15%;
      color: $gray-500;
      font-size: 14px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    .input-row-right {
      width: 45%;
      @media (max-width: 800px) {
        width: 100%;
      }
      .button-submit {
        background-color: $orange-500;
        color: $white-primary;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 12px 16px;
        border-radius: 8px;
      }
    }
  }
  .input-submit-row {
    border-bottom: 0;
    .input-row-right {
        width: fit-content;
    }
    @media (max-width: 800px) {
      align-items: center !important;
      justify-content: center !important;
    }
  }
}
//ant custom
.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-input:focus,
.ant-input:hover {
  border-color: $gray-300;
  outline: none;
}
</style>