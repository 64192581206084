<template>
  <div
    id="sidebar"
    :class="{
      'sidebar-wrap': true,
      hidden: statusHide,
      'sidebar-collapse': statusCollapse,
    }"
  >
    <div
      class="logo w-full h-[60px] overflow-hidden border-b"
      style="border-color: var(--line-color)"
    >
      <img
        v-if="!statusCollapse"
        src="../../assets/images/svg/logo_default.svg"
        alt=""
        class="w-full h-[62px] object-cover"
      />
      <img
        v-if="statusCollapse"
        src="../../assets/images/svg/img_logo.svg"
        alt=""
        class="w-80 h-[62px] object-cover"
      />
    </div>
    <div
      class="btn-add-user flex justify-center items-center w-full h-[60px] mx-[24px]"
      style="border-bottom: 1px solid var(--line-color)"
      v-if="false"
    >
      <button class="fill-primary-text-white w-192-h-44" v-on:click="handleAddUser" >
        <span v-html="AddUserIcon"></span>
        <span class="text">{{ $t("common.create_user") }}</span>
      </button>
    </div>
    <div
      class="service-package flex flex-col justify-center items-center w-full h-[148px] border-b py-[10px]"
      style="border-color: var(--line-color)"
    >
      <div
        class="category-package w-52 mx-4 text-light mt-[10px]"
        style="color: var(--color-text-light)"
      >
        <h3 class="notosans700 text-[16px] leading-[24px]">
          {{ $t("common.basic_plan") }}
        </h3>
      </div>
      <div class="statistic-create-user w-52 h-[34px] my-[13px] mx-4">
        <div class="slider relative h-2">
          <span
            class="brg-100 absolute w-full h-2 bg-[rgba(52,64,84,1)] rounded-full"
          ></span>
          <span
            class="brg-statistic absolute h-2 rounded-full"
            :style="{
              width: `${percentageUserCreated}` + '%',
              backgroundColor: 'var(--primary-color-dark)',
            }"
          ></span>
        </div>
        <div
          class="text-statistic flex justify-start items-center h-5.5 mt-1 gap-[3px] text-light"
          style="color: var(--color-text-light)"
        >
          <h4 class="text-[14px] notosans400 text-white">
            {{ usersLen }}{{ $t("common.man") }}/{{ totalUserCanCreate
            }}{{ $t("common.man") }}
          </h4>
          <span class="text-[14px] notosans400 text-white">{{ $t("common.user") }} </span>
        </div>
      </div>
      <div class="increase-user-btn mb-[10px]">
        <button class="fill-white-text-primary w-52 h-11" v-on:click="handleNaviPricing">
          {{ $t("common.increase_user") }}
        </button>
      </div>
    </div>
    <div
      class="menu-links flex flex-col justify-start items-start pt-2.5 w-full flex-1 border-b"
      style="border-color: var(--line-color)"
    >
      <SideBarItem
        :icon="HomeIcon"
        :title="$t('common.dashboard')"
        :index="1"
        link="/dashboard"
      />
      <SideBarItem
        :icon="Diamond"
        :title="$t('common.pricing')"
        :index="2"
        link="/pricing"
      />
      <SideBarItem :icon="UserIcon" :title="$t('common.user')" :index="3" link="/user" />
      <SideBarItem
        :icon="PointIcon"
        :title="$t('common.point')"
        :index="4"
        link="/point"
      />
      <SideBarItem
        :icon="ReportIcon"
        :title="$t('common.data_analysis')"
        :index="5"
        link="/data_analysis"
      />
      <SideBarItem
        :icon="PaymentIcon"
        :title="$t('common.withdrawal')"
        :index="6"
        link="/withdrawal"
      />
      <SideBarItem
        :icon="SettingIcon"
        :title="$t('common.setting')"
        :index="7"
        link="/setting"
      />
    </div>
    <div
      class="relative info-account flex justify-center items-center px-[16px] gap-[8px] w-full h-[72px]"
    >
      <div class="relative">
        <img
          :src="getAvatarShopByName(selectedShop.store_avatar)"
          @error="(e) => handleImageError(e, 'shop')"
          @load="(e) => handleImageLoad(e, index)"
          alt=""
          style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px"
          class="w-[24px] h-[24px] rounded-full object-cover"
        />
        <a-spin class="absolute top-[0px] left-[0px]" v-if="loading" />
      </div>
      <h4
        class="w-37 flex-1 text-ellipsis uppercase overflow-hidden whitespace-nowrap"
        style="color: var(--color-text-light)"
      >
        {{ selectedShop?.store_name }}
      </h4>
      <button
        v-html="ChangeAccountIcon"
        v-on:click="handleChangeAccount"
        class="w-[30px] h-[30px] flex justify-center items-center"
      ></button>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  AddUserIcon,
  ChangeAccountIcon,
  Diamond,
  HomeIcon,
  PaymentIcon,
  PointIcon,
  ReportIcon,
  SettingIcon,
  UserIcon,
} from "../../assets/constant/svg_exports";
import SideBarItem from "../../components/master/components/SideBarItem.vue";
import {
  addModal,
  formatDate,
  getAvatarShopByName,
  handleImageError,
} from "../../helpers";
import FormAddUser from "../views/components/FormAddUser.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const statusCollapse = computed(() => store.state.collapseSidebar);
const statusHide = computed(() => store.state.hideSidebar);
const selectedShop = computed(() => store.state.selectedShop);
const usersLen = computed(() => {
  return store.getters.userListLen() || 0;
});
const percentageUserCreated = ref(0);
const totalUserCanCreate = computed(() =>
  store.state.totalUserCanCreate ? store.state.totalUserCanCreate : 0
);
const calculatedUserCanCreate = () => {
  const width = (usersLen.value / totalUserCanCreate.value) * 100;
  if (width <= 100 && totalUserCanCreate.value !== 0) {
    percentageUserCreated.value = width;
  } else {
    percentageUserCreated.value = 100;
  }
};
watch([totalUserCanCreate, usersLen], () => {
  calculatedUserCanCreate();
});
const loading = ref(true);
const handleChangeAccount = () => {
  router.replace("/stores");
};
const handleAddUser = () => {
  return;
  router.push("/user");
  addModal(FormAddUser);
};
const handleImageLoad = (e, index) => {
  loading.value = false;
};
const handleNaviPricing = () => {
  store.dispatch("setSidebarFocus", 2);
  router.push("/pricing");
};
watch(
  () => route.path,
  (newPath) => {
    if (newPath === "/dashboard") {
      store.dispatch("setSidebarFocus", 1);
    } else if (newPath.includes("/pricing")) {
      store.dispatch("setSidebarFocus", 2);
    } else if (newPath.includes("/user")) {
      store.dispatch("setSidebarFocus", 3);
    } else if (newPath.includes("/point")) {
      store.dispatch("setSidebarFocus", 4);
    } else if (newPath.includes("/data_analysis")) {
      store.dispatch("setSidebarFocus", 5);
    } else if (newPath.includes("/withdrawal")) {
      store.dispatch("setSidebarFocus", 6);
    } else if (newPath.includes("/setting")) {
      store.dispatch("setSidebarFocus", 7);
    } else {
      store.dispatch("setSidebarFocus", 1);
    }
  }
);
onMounted(() => {
  store.dispatch("getUserList", selectedShop?.value?.store_id);
  store.dispatch("getTotalUserCanCreate", {
    time: formatDate(new Date().setUTCDate(new Date().getUTCDate() + 1)),
    shopId: selectedShop?.value?.store_id,
  });
  calculatedUserCanCreate();
});
</script>
<style lang="scss">
.sidebar-wrap {
  // transition: all 0s !important;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: var(--brg-sidebar);
  overflow: hidden;
  z-index: 100;
}
.sidebar-collapse {
  width: 80px;
  .btn-add-user {
    .fill-primary-text-white {
      width: 40px;
      height: 40px;
      margin-left: 0px;
      margin-right: 0px;
      svg {
        width: 24px;
        height: 24px;
      }
      .text {
        display: none;
      }
    }
  }
  .service-package {
    display: none;
  }
  .menu-links {
    .sidebar-item {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        .name {
          display: none;
        }
      }
    }
  }
  .info-account {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 116px;
    gap: 16px;
    h4 {
      display: none;
    }
  }
}
</style>
