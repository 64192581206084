<template>
  <div
    :class="{
      'user-gift-tab-container rounded-lg bg-white pb-5 h-screen': true,
      collapseSidebar: isCollaspseSideBar,
      hideSidebar: isHideSidebar
    }"
  >
    <div
      class="table-header-gift-tab flex justify-between items-center px-[20px] py-4"
    >
      <div
        class="table-header-left text-title-info-text font-bold text-[18px] leading-[24px] notosans700"
      >
        {{ $t('common.point') }}
        {{ toggleDateMonth ? monthValueHeader : yearValueHeader }}
      </div>
      <div class="table-header-middle flex items-center gap-2">
        <div
          class="point bg-[#EF6820] px-5 py-3 w-[180px] text-center text-white rounded-lg"
        >
          <span class="font-[400]">{{ $t('common.point') }}</span>
          <span class="font-[700]">: {{ totalPoint }}</span>
        </div>
      </div>
      <div class="table-header-right flex gap-2">
        <div
          class="gift-tab-picker flex border-[1px] border-[#D0D5DD] rounded-lg"
        >
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('decrease')"
          >
            <span v-html="previousIcon"></span>
          </button>

          <div v-if="toggleDateMonth" class="label-time-picker cursor-pointer">
            <a-date-picker
              :format="monthFormat"
              picker="month"
              :allowClear="false"
              v-model:value="monthValue"
              @change="handleMonthPickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <div v-else class="cursor-pointer">
            <a-date-picker
              :format="yearFormat"
              picker="year"
              :allowClear="false"
              v-model:value="yearValue"
              @change="handleYearPickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('increase')"
          >
            <span v-html="nextIcon"></span>
          </button>
        </div>
        <div
          class="time-button flex bg-white rounded-lg border-[1px] border-solid border-[#D0D5DD]"
        >
          <button
            class="day px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] notosans400 whitespace-nowrap"
            :class="toggleDateMonth ? 'isDayActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.month') }}
          </button>
          <button
            class="month px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] notosans400 whitespace-nowrap"
            :class="!toggleDateMonth ? 'isMonthActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.year') }}
          </button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-body scroll-bar overflow-y-auto h-[370px]">
        <table>
          <tr class="sticky top-0">
            <th>
              {{ $t('common.date') }}
            </th>
            <th>
              {{ $t('common.point') }}
            </th>
            <th v-if="toggleDateMonth"></th>
          </tr>
          <tr
            v-for="(i, index) in toggleDateMonth
              ? userGiftPointByMonthData
              : userGiftPointByYearData"
            :key="index"
          >
            <td v-if="toggleDateMonth">
              {{
                languageToggle === 'ja'
                  ? i.day + $t('common.day_')
                  : `${$t('common.day_')} ${i.day}`
              }}
            </td>
            <td v-else>
              <!-- {{ currentYearTable }}{{ i.month + $t('common.month_') }} -->
              {{
                languageToggle === 'ja'
                  ? `${currentYearTable + i.month + $t('common.month_')}`
                  : `${months[i.month - 1] + ' - ' + currentYearTable}`
              }}
            </td>
            <td>{{ i.totalGiftPoint }}</td>
            <td class="w-[20px] !pr-6" v-if="toggleDateMonth">
              <button
                v-html="DetailIcon1"
                @click="handlePushPointDetail(i.day)"
              ></button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import moment from 'moment';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  CalendarFilledIcon,
  DetailIcon1
} from '../../../assets/constant/svg_exports';
import { getCurrentDate, getCurrentMonth } from '../../../helpers';
import { nextIcon, previousIcon } from '../../../assets/constant/constant';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import 'dayjs/locale/ja';
dayjs.locale('ja');
export default {
  name: 'UserGiftingTab',
  setup() {
    const route = useRoute();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const toggleDateMonth = ref(true);
    const currentMonth = ref(dayjs(getCurrentMonth(), 'YYYY-MM'));
    const currentYear = ref(dayjs(getCurrentDate(), 'YYYY'));
    const monthValue = ref(currentMonth);
    const yearValue = ref(currentYear);
    const monthFormat = 'YYYY年M月';
    const yearFormat = 'YYYY年';
    const selectedShop = computed(() => store.state.selectedShop);
    const store = useStore();
    const router = useRouter();
    const isCollaspseSideBar = computed(() => store.state.collapseSidebar);
    const languageToggle = localStorage.getItem('lang') || 'ja';
    const data = ref({
      userId: route.query.user_id,
      type: toggleDateMonth ? 'month' : 'year',
      shopId: store.state.selectedShop.store_id,
      date: moment(monthValue.value.$d).format('YYYY-MM')
    });
    const dispatchFunction = () => {
      store.dispatch('getUserPoint', data.value);
    };
    const userGiftPointByMonthData = computed(
      () => store.getters.userGiftPointByMonthGetters
    );
    const userGiftPointByYearData = computed(
      () => store.getters.userGiftPointByYearGetters
    );
    let monthValueHeader = ref(
      languageToggle === 'ja'
        ? `(${moment(monthValue.value.$d).format('YYYY年M月')})`
        : `(${months[moment(monthValue).format('M') - 1]})`
    );
    let yearValueHeader = ref(
      languageToggle === 'ja'
        ? `(${moment(yearValue).format('YYYY年')})`
        : `(${moment(yearValue).format('YYYY')})`
    );
    let currentYearTable = ref(
      languageToggle === 'ja'
        ? `${moment(yearValue).format('YYYY年')}`
        : `${moment(yearValue).format('YYYY')}`
    );
    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
      if (
        toggleDateMonth.value == true &&
        userGiftPointByMonthData.value.length == 0
      ) {
        store.dispatch('getUserPoint', {
          ...data.value,
          type: 'month',
          date: moment(monthValue.value.$d).format('YYYY/MM')
        });
      } else if (
        toggleDateMonth.value == false &&
        userGiftPointByYearData.value.length == 0
      ) {
        store.dispatch('getUserPoint', {
          ...data.value,
          type: 'year',
          date: moment(yearValue.value.$d).format('YYYY')
        });
      }
    };

    const handleMonthPickerChange = () => {
      languageToggle === 'ja'
        ? (monthValueHeader.value = `(${moment(monthValue.value.$d).format(
            'YYYY年M月'
          )})`)
        : (monthValueHeader.value = `(${
            months[moment(monthValue.value.$d).format('M') - 1]
          })`);
      store.dispatch('getUserPoint', {
        ...data.value,
        date: moment(monthValue.value.$d).format('YYYY-MM')
      });
    };
    const handleYearPickerChange = () => {
      yearValueHeader.value = `(${moment(yearValue.value.$d).format(
        'YYYY年'
      )})`;
      store.dispatch('getUserPoint', {
        ...data.value,
        type: 'year',
        date: moment(yearValue.value.$d).format('YYYY')
      });
    };
    // const monthValueHeader = computed(() =>
    //   languageToggle === 'ja'
    //     ? `(${moment(monthValue).format('M月')})`
    //     : `(${months[moment(monthValue).format('M') - 1]})`
    // );

    onMounted(() => {
      dispatchFunction();
    });
    onUnmounted(() => {
      store.commit('RESET_USER_POINT_ALL_TIME')
    })
    //custom function
    const handleIncreaseOrDecreaseDateTime = (type) => {
      switch (toggleDateMonth.value) {
        case true:
          type == 'increase'
            ? (monthValue.value = monthValue.value.add(1, 'month'))
            : (monthValue.value = monthValue.value.subtract(1, 'month'));
          handleMonthPickerChange();
          break;
        case false:
          monthValue.value =
            type == 'increase'
              ? (yearValue.value = yearValue.value.add(1, 'year'))
              : (yearValue.value = yearValue.value.subtract(1, 'year'));
          handleYearPickerChange();
          break;
      }
    };
    const totalPoint = computed(() => {
      return toggleDateMonth.value == true
        ? userGiftPointByMonthData.value.reduce(
            (acc, item) => acc + item.totalGiftPoint,
            0
          )
        : userGiftPointByYearData.value.reduce(
            (acc, item) => acc + item.totalGiftPoint,
            0
          );
    });
    const handlePushPointDetail = (day) => {
      const date = `${moment(monthValue.value.$d).format('YYYY-MM')}-${day}`;
      router.push(
        `/user/user_point_detail?user_id=${route.query.user_id}&type=date&type_get=gift&shop=${selectedShop.value.store_id}&date=${date}`
      );
    };
    return {
      totalPoint,
      months,
      handleMonthPickerChange,
      handleYearPickerChange,
      toggleDateMonth,
      monthValueHeader,
      yearValueHeader,
      CalendarFilledIcon,
      languageToggle,
      monthValue,
      yearValue,
      yearFormat,
      monthFormat,
      isCollaspseSideBar,
      isHideSidebar: computed(() => store.state?.hideSidebar),
      handleToggleDateMonthClick,
      DetailIcon1,
      handlePushPointDetail,
      userGiftPointByMonthData,
      userGiftPointByYearData,
      currentYearTable,
      //custom function
      handleIncreaseOrDecreaseDateTime,
      //icon
      previousIcon,
      nextIcon,
      //locale
      locale
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/views/_user_gift_table.scss';
@import '../../../assets/css/views/ant_custom';
.table-header-gift-tab {
  @media (max-width: 1100px) {
    display: block;
    .table-header-left {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .table-header-middle {
      display: flex;
      justify-content: center;
    }
    .table-header-right {
      margin-top: 10px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>