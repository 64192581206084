<template>
  <div
    class="store-change-password-container rounded-[12px] overflow-hidden flex flex-col gap-[10px] w-[700px] min-h-[450px] overflow-auto"
  >
    <div class="title flex justify-between h-[54px] w-full py-[12px] px-[20px] border-b-[1px] border-solid border-[#d0d5dd]">
      <h2 class="form-name text-[20px] notosans600">
        {{ $t("common.change_password") }}
      </h2>
      <button class="btn-close-form notosans400 text-[13px]" v-on:click="handleClose()">
        {{ $t("common.close") }}
      </button>
    </div>
    <div class="store-change-body p-[20px]">
      <a-form class="flex flex-col gap-[5px]" :model="formState" @finish="onFinish">
        <div class="row">
          <div class="col-25">
            {{ $t('common.old_password') }}
          </div>
          <div class="col-75 flex-1">
            <a-form-item name="oldPassword" :rules="rules.oldPassword">
              <a-input
                :type="isShowOldPassword ? 'text' : 'password'"
                :placeholder="$t('common.old_password_placeholder')"
                v-model:value="formState.oldPassword"
              />
              <div class="icon-container" @click="handleShowOldPassword">
                <span
                  :class="['icon', { 'icon-active': isShowOldPassword }]"
                  v-html="PasswordShowIcon"
                ></span>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-25">
            {{ $t("common.new_password") }}
          </div>
          <div class="col-75 flex-1">
            <a-form-item name="newPassword" :rules="rules.newPassword">
              <a-input
                :type="isShowNewPassword ? 'text' : 'password'"
                :placeholder="$t('common.new_password_placeholder')"
                v-model:value="formState.newPassword"
              />
              <div class="icon-container" @click="handleShowNewPassword">
                <span
                  :class="['icon', { 'icon-active': isShowNewPassword }]"
                  v-html="PasswordShowIcon"
                ></span>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-25">
            {{ $t("common.new_password_confirm") }}
          </div>
          <div class="flex-1">
            <a-form-item name="newPasswordConfirm" :rules="rules.newPasswordConfirm">
              <a-input
                :type="isShowNewPasswordConfirm ? 'text' : 'password'"
                :placeholder="$t('common.new_password_placeholder')"
                v-model:value="formState.newPasswordConfirm"
              />
              <div class="icon-container" @click="handleShowNewPasswordConfirm">
                <span
                  :class="['icon', { 'icon-active': isShowNewPasswordConfirm }]"
                  v-html="PasswordShowIcon"
                ></span>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="row-submit-button w-full flex justify-center items-center">
          <div class="flex justify-center items-center">
            <a-form-item>
              <button
                type="submit"
                class="bg-[#EF6820] w-full min-w-[300px] h-[44px] text-white notosans500 text-[13px] leading-[24px] px-4 py-2 rounded-lg"
              >
                {{ $t("common.change_password") }}
              </button>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { PasswordShowIcon } from "../../../assets/constant/svg_exports";
// import ResetModalVue from './components/ResetModal.vue';

export default {
  name: "StoreChangePasswordTab",
  // components: { ResetModalVue },
  props : {
    onClose: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const isShowOldPassword = ref(false);
    const isShowNewPassword = ref(false);
    const isShowNewPasswordConfirm = ref(false);
    const handleShowOldPassword = () => {
      isShowOldPassword.value = !isShowOldPassword.value;
    };
    const handleShowNewPassword = () => {
      isShowNewPassword.value = !isShowNewPassword.value;
    };
    const handleShowNewPasswordConfirm = () => {
      isShowNewPasswordConfirm.value = !isShowNewPasswordConfirm.value;
    };
    const formState = reactive({
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    });
    const onFinish = (values) => {
      console.log(values);
      showNotifyDialog();
    };
    //   const showNotifyDialog = () => {
    //     passwordModal(ResetModalVue);
    //   };
    const confirmPasswordValidator = (rule, value) => {
      if (value !== formState.newPassword) {
        return Promise.reject(t("common.input_new_password_alert_confirm"));
      } else {
        return Promise.resolve();
      }
    };

    const handleClose = () => {
      props.onClose();
    };
    return {
      handleClose,
      formState,
      isShowOldPassword,
      PasswordShowIcon,
      onFinish,
      rules: {
        oldPassword: [{ required: true, message: t("common.input_old_password_alert") }],
        newPassword: [{ required: true, message: t("common.input_new_password_alert") }],
        newPasswordConfirm: [
          {
            required: true,
            message: t("common.input_new_password_alert"),
          },
          { validator: confirmPasswordValidator },
        ],
      },
      // showNotifyDialog,
      handleShowOldPassword,
      handleShowNewPassword,
      handleShowNewPasswordConfirm,
      isShowNewPassword,
      isShowNewPasswordConfirm,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/views/store_password_tab";
.store-change-password-container{
  padding: 0px;
  .row {
    border-bottom: 1px solid transparent !important;
  }
}
</style>
