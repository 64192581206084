import axiosConfig from '../../config/axiosConfig';

export const pointsAction = {
    async getPointDayList(context, data) {
        try {
            const res = await axiosConfig.get(`/gift-points?shop=${data?.shopId}&type=date&date=${data?.date}`);
            if (res.status === 200 || res.status === 201){
                context.commit('GET_POINT_DATES', res.data);
            }
        } catch (error) {
            throw new Error(error);
        }
    },
    async getPointMonthList(context, data){
        try {
            const res = await axiosConfig.get(`/gift-points?shop=${data?.shopId}&type=month&date=${data?.month}`);
            if (res.status === 200 || res.status === 201){
                context.commit('GET_POINT_MONTHS', res.data);
            }
        } catch (error) {
            throw new Error(error);
        }
    },
    async getPointYearList(context, data){
        try {
            const res = await axiosConfig.get(`/gift-points?shop=${data?.shopId}&type=year&date=${data?.year}`);
            console.log(res.data);
            
            if (res.status === 200 || res.status === 201){
                context.commit('GET_POINT_YEARS', res.data);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}
