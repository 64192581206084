<template>
  <div
    class="dashboard-analytical-item bg-white px-3 py-3 rounded-xl border-[1px] border-[#EAECF0]"
  >
    <div class="dashboard-analytical-data flex justify-between items-center">
      <div
        class="dashboard-analytical-data-number font-bold text-black p-3 text-center leading-none text-[32px] md:text-[27px] overflow-hidden text-ellipsis sm:text-[28px]"
      >
        {{ data?.number }}
      </div>
      <div
        :class="[
          'dashboard-analytical-data-icon h-fit w-fit p-3 rounded-lg sm:p-1 xs:p-1',
          data?.iconBackground
        ]"
      >
        <div v-html="data?.icon"></div>
      </div>
    </div>
    <div
      class="dashboard-analytical-sub-title notosans500 text-[#667085] pb-2 px-3 text-[18px] leading-[30px] font-medium sm:tracking-tighter whitespace-nowrap"
    >
      {{ data?.subtitle }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'AnalyticalItem',
  props: {
    data: {
      type: Object
    }
  }
};
</script>
<style lang="scss">
</style>