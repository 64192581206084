import axiosConfig from '../../config/axiosConfig';

import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies';
import { ENCODE_SECRET_KEY } from '../../constants';
import { getToken } from '../../helpers/token';

export const authActions = {
  async login(context, data) {
    try {
      const res = await axiosConfig.post('/auth/login', data.data);
      if (res?.status != 401 && res?.data?.user?.role === 'admin') {
        VueCookies.set(
          'access_token',
          CryptoJS.AES.encrypt(
            res.data.access_token,
            ENCODE_SECRET_KEY
          ).toString(),
          data?.data?.rememberMe ? '30d' : '1d'
        );
        if (res?.data?.access_token && res?.data?.user?.firstLogin === 1) {
          setTimeout(() => data.onFirstLogin(), 1000);
        } else if (
          res?.data?.access_token &&
          res?.data?.user?.role === 'admin'
        ) {
          context.commit('LOGIN', res.data);
          setTimeout(() => data.onLoginSuccess(), 1000);
        } else if (res?.data?.user?.role !== 'admin') {
          context.commit('NOT_ADMIN_MODAL');
        }
      } else {
        context.commit('UNAUTHORIZED_USER_MODAL');
      }
    } catch (errors) {
      console.log(errors.message);
    } finally {
      context.commit('UPDATE_LOADING', false);
    }
  },
  async changePasswordFirstLogin(context, data) {
    try {
      const token = getToken();
      if (token) {
        const res = await axiosConfig({
          url: `/user/change-password`,
          method: 'post',
          token,
          data: data.dataPassword
        });
        res.status === 200 || res.status === 201
          ? context.commit('UPDATE_PASSWORD', {
              onSuccessUpdate: data?.onSuccessUpdate
            })
          : context.commit('UPDATE_PASSWORD_ERROR', res.data);
      }
    } catch (error) {
      console.log('change password: ', error);
    }
  },
  async userChangePasswordSetting(context, data) {
    try {
      const token = getToken();
      if (token) {
        const res = await axiosConfig({
          url: `/user/change-password`,
          method: 'post',
          token,
          data: data.dataPassword
        });
        res.status === 200 || res.status === 201
          ? context.commit('UPDATE_SETTING_PASSWORD_SUCCESS', true)
          : context.commit('UPDATE_SETTING_PASSWORD_ERROR', false);
      }
    } catch (error) {
      console.log('change password: ', error);
    }
  }
};
