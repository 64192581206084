export const usersState = {
  totalUsers: 0,
  userInfo: {},  
  userDeletedList: [],
  userList: [],
  userListPending: [],
  userDetails: {},
  pricingList: [],
  renewPricings: [],
  totalUserCanCreate: 0,
};
