<template>
    <div :class="`${styles.payment_method_settings} w-[700px] min-h-[318px] flex justify-start items-start flex-col  bg-white rounded-[12px]`">
        <div :class="`${styles.header} w-full min-h-[54px] flex justify-between items-center px-[20px] py-[12px] border-solid border-b-[1px] border-b-[#D0D5DD]`">
            <h3 :class="`${styles.title} notosans700 text-[20px] leading-[30px] text-[#1D2939]`">
                {{ $t("common.payment_method_settings") }}
            </h3>
            <span v-on:click="onClose" class="notosans500 cursor-pointer text-[13px] text-[#667085] leading-[24px]">{{ $t("common.close") }}</span>
        </div>
        <div :class="`${styles.content} w-full flex-1 px-[20px] py-[12px]`">
            <span :class="`${styles.notify} w-full text-left text-[16px] text-[#1D2939] notosans400 leading-[20px]`">
                {{ $t("common.withdrawal_notify") }}
            </span>
            <div :class="`${styles.packages}  w-full mt-[20px] flex justify-center items-center gap-[12px] `">
                <div :class="`${styles.package} ${packageActiveNew === 1 && styles.packageActive} ${lang === 'en' && '!h-[210px]'}`" v-on:click="() => handleChangePackage(1)">
                    <span
                      :class="styles.checkBox"
                      v-html="packageActiveNew === 1 ? CheckBoxTrue : CheckBoxFalse"
                    ></span>
                    <div :class="styles.content">
                      <span :class="styles.titlePackage">{{ $t("common.plan1_free")}}</span>
                      <span :class="styles.note">{{ $t("common.1withdrawal_free")}}</span>
                      <span :class="styles.note">{{ $t("common.withdrawals_from_the_1st_to_the_end_of_the_month_on_the_5th_of_the_following_month")}}</span>
                    </div>
                  </div>
                  <div :class="`${styles.package} ${packageActiveNew === 2 && styles.packageActive} ${lang === 'en' && '!h-[210px]'}`" v-on:click="() => handleChangePackage(2)">
                    <span
                      :class="styles.checkBox"
                      v-html="packageActiveNew === 2 ? CheckBoxTrue : CheckBoxFalse"
                    ></span>
                    <div :class="styles.content">
                      <span :class="styles.titlePackage">{{ $t("common.plan2")}}</span>
                      <span :class="styles.note">{{ $t("common.2_withdrawals_fee")}}1,100{{ $t("common.yen")}}</span>
                      <span :class="styles.note">{{ $t("common.withdrawals_made_from_the_1st_to_the_15th_of_the_month_on_the_20th_of_the_month")}}</span>
                      <span :class="styles.note">{{ $t("common.withdrawals_made_from_the_16th_to_the_end_of_the_month_on_the_5th_of_the_following_month")}}</span>
                    </div>
                  </div>
            </div>
        </div>
        <div :class="`${styles.button} w-full h-[66px] border-solid border-t-[1px] border-t-[#D0D5DD] py-[12px] px-[20px]`">
            <button v-on:click="handleSaveOption" class="w-full h-[42px] fill-primary-text-white">
                <span>{{$t("common.keep")}}</span>
            </button>
        </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { CheckBoxFalse, CheckBoxTrue } from "../../../assets/constant/svg_exports";
import styles from "../../../assets/css/views/_payment_method_settings.module.scss";
const props = defineProps({
    onClose: {
        type: Function,
        required: true,
    },
    packageActiveNow: {
        type: Number,
        required: true,
    },
    handleSavePackage: {
        type: Function,
        required: true,
    }
})
const lang = localStorage.getItem("lang");
const packageActiveNew = ref(props?.packageActiveNow || 2);
const handleChangePackage = (packageActive) => {
    packageActiveNew.value = packageActive;
};
const handleSaveOption = () => {
    props.handleSavePackage(packageActiveNew.value)
    props.onClose();
}
</script>