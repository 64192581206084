<template>
    <div v-on:click="handleChecked" :class="`${styles.userItem} ${userRequestActived ? 'cursor-not-allowed bg-[#F2F4F7]' : 'cursor-pointer'} h-[72px] flex justify-start items-center gap-[12px] rounded-[8px] border-solid border-[1px] border-[#EAECF0] p-[12px]`">
        <img 
        alt="" 
        :src="getAvatarUserByName(props?.user?.avatar)"
        @load="handleImageLoad"
        @error="handleImageError" 
        :class="`w-[48px] h-[48px] rounded-full object-cover`"
        />
        <div :class="`${styles.userInfo} flex-1`">
            <div :class="`${styles.nickName} notosans700 text-[16px] leading-[24px] text-[#182230]`">{{ props.user?.nickName }}</div>
            <div :class="`${styles.userName} notosans400 text-[14px] leading-[22px] text-[#667085]`">{{ props.user?.userName }}</div>
        </div>
        <button :class="`${userRequestActived ? 'cursor-not-allowed' : 'cursor-pointer'}`">
            <span v-if="checked && !userRequestActived" v-html="CheckedCircle"></span>
            <span v-if="!checked && !userRequestActived" v-html="CheckCircle"></span>
            <span v-if="userRequestActived" v-html="CheckedRequest"></span>
        </button>
    </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { CheckCircle, CheckedCircle, CheckedRequest } from '../../../assets/constant/svg_exports';
import styles from '../../../assets/css/views/_user_item_form_buy.module.scss';
import { getAvatarUserByName, handleImageError, isObjectEmpty } from '../../../helpers';
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  pushUserToBuyList: {
    type: Function,
    required: true,
  },
  splitUserInBuyList: {
    type: Function,
    required: true,
  }
});
const checked = ref(false);
const loading = ref(true);
const handleChecked = () => {
  if(!userRequestActived.value){
    checked.value =!checked.value;
  }
}
const userRequestActived = computed(() => {
  if(props.user?.packageUser?.length > 0 && !isObjectEmpty(props.user?.packageUser[0]?.package)){
    return true;
  }else{
    return false;
  }
})
const handleImageLoad = () => {
  loading.value = false;
};
watch(checked, () => {
  if(!userRequestActived.value){
    if (checked.value === true) {
      props.pushUserToBuyList(props.user?.id);
    }else {
      props.splitUserInBuyList(props.user?.id);
    }
  }
})
</script>
<style lang="scss">
</style>