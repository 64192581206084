
<template>
  <div class="table-container mx-6 h-[calc(100vh-200px)]">
    <!-- Table Header -->
    <div class="overflow-hidden h-[50px]">
      <table class="user-point-detail-table w-full">
        <thead class="sticky top-0 bg-white">
          <tr>
            <th class="p-[14px]">
              {{ $t('common.date') }}
            </th>
            <th class="p-[14px]">
              {{ $t('common.point') }}
            </th>
            <th class="p-[14px]"></th>
          </tr>
        </thead>
      </table>
    </div>
    <!-- Table Body -->
    <div class="overflow-y-auto h-[calc(100vh-240px)] scroll-bar">
      <table class="user-point-detail-table w-full !rounded-t-none">
        <tbody>
          <tr v-for="(i, index) in dataRender" :key="i">
            <td :key="index" class="p-2 border-b border-gray-200">
              {{ i.day }}
            </td>
            <td class="p-2 border-b border-gray-200">{{ i.point }}</td>
            <td class="w-[20px] !pr-6 border-b border-gray-200">
              <button
                v-on:click="() => handleChangeMonthToDay(i?.dayString)"
                v-html="DetailIcon1"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DetailIcon1 } from '../../../assets/constant/svg_exports';
import {
  formatDateForEn,
  formatDateForJapanese,
  formatDateFromDate,
  getCurrentDateYYYYMM,
  getDaysInMonth
} from '../../../helpers';

const props = defineProps({
  onNaviSeeOfDate: {
    type: Function,
    required: true,
  },
  onChangePoint: {
    type: Function,
    required: true,
  },
});
const handleChangeMonthToDay = (date) => {
  props.onNaviSeeOfDate(date);
};
const handleChangePoint = (point) => {
  props.onChangePoint(point);
};
const store = useStore();
const router = useRouter();
const route = useRoute();
const dataRender = ref([]);
const selectedShop = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const pointMonthList = computed(() => store.state.pointMonths || []);

watch([pointMonthList], () => {
  const month = router.currentRoute.value?.query?.month;
  const [y, m] = month?.split("-");
  dataRender.value = pointMonthList.value?.map((item) => {
    const d = item.day;
    return {
      ...item,
      dayString: formatDateFromDate(new Date(y, m - 1, d)),
      day:
        localStorage.getItem('lang') === 'en'
          ? formatDateForEn(new Date(y, m - 1, d))
          : formatDateForJapanese(new Date(y, m - 1, d)),
      point:
        item.totalGiftPoint || 0,
    };
  });
});

watch(dataRender, () => {
  const totalPoint = dataRender.value.reduce((acc, curr) => acc + curr.point, 0);
  handleChangePoint(totalPoint)
});

const handleDateUndefined = () => {
  const date = localStorage.getItem('datePoint');
  const [y, m, d] = date?.split('-');
  const monthNow = getCurrentDateYYYYMM();
  router.push(`/point?month=${`${y}-${m}` || monthNow}`);
};
const handleFetchingData = async () => {
  await router.isReady();
  const month = router.currentRoute.value?.query?.month;
  if (month) {
    store.dispatch('getPointMonthList', {
      shopId: selectedShop.value?.store_id,
      month: month + "-01",
    });
  } else {
    handleDateUndefined();
  }
};
watch(
  () => route.query.month,
  () => {
    handleFetchingData();
  }
);
onMounted(() => {
  handleFetchingData();
});
</script>
<style scoped lang="scss">
.table-container {
  display: grid;
  grid-template-rows: auto 1fr;
}
.user-point-detail-table th,
.user-point-detail-table td {
  width: calc(100% / 3);
}
.user-point-detail-table th:last-child,
.user-point-detail-table td:last-child {
  width: 5%;
}
</style>
