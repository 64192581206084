<template>
  <div class="table-container mx-6 h-[calc(100vh-200px)]">
    <!-- Table Header -->
    <div class="overflow-hidden h-[50px]">
      <table class="user-point-detail-table w-full">
        <thead class="sticky top-0 bg-white">
          <tr>
            <th class="p-[14px]">
              {{ $t('common.user') }}
            </th>
            <th class="p-[14px]">
              {{ $t('common.point') }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <!-- Table Body -->
    <div class="overflow-y-auto h-[calc(100vh-240px)] scroll-bar">
      <table class="user-point-detail-table !rounded-t-none w-full">
        <tbody>
          <tr v-for="(i, index) in dataRender" :key="i">
            <td :key="index">
              <div class="flex-thead">
                {{ i.userReceive || '-' }}
              </div>
            </td>
            <td>{{ i.points || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getCurrentDate } from "../../../helpers";
const props = defineProps({
  onChangePoint: {
    type: Function,
    required: true,
  },
});
const handleChangePoint = (point) => {
  props.onChangePoint(point);
};
const store = useStore();
const router = useRouter();
const route = useRoute();
const selectedShop = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const pointDayList = computed(() => store.state.pointDates);
const dataRender = computed(() => {
  return Object.values(
    pointDayList?.value?.reduce((newItem, nowItem) => {
      if (!newItem[nowItem.userReceive._id]) {
        newItem[nowItem.userReceive._id] = {
          userId: nowItem.userReceive._id,
          userReceive: nowItem.userReceive.nickName,
          points: 0,
          dateRecieved: nowItem.createdAt
        };
      }
      newItem[nowItem.userReceive._id].points += nowItem?.points;
      return newItem;
    }, {})
  );
});
watch(dataRender, () => {
  const totalPoint = dataRender.value.reduce((acc, curr) => acc + curr.points, 0);
  handleChangePoint(totalPoint)
});
const handleDateUndefined = () => {
  router.push(
    `/point?date=${localStorage.getItem('datePoint') || getCurrentDate()}`
  );
};
const handleFetchingData = async () => {
  await router.isReady();
  const date = router.currentRoute.value?.query?.date;
  if (date) {
    store.dispatch('getPointDayList', {
      shopId: selectedShop.value?.store_id,
      date: date
    });
  } else {
    handleDateUndefined();
  }
};
watch(
  () => route.query.date,
  () => {
    handleFetchingData();
  }
);
onMounted(() => {
  handleFetchingData();
});
</script>
<style scoped lang="scss">
.table-container {
  display: grid;
  grid-template-rows: auto 1fr;
}
.user-point-detail-table th,
.user-point-detail-table td {
  width: calc(100% / 3);
}
</style>
