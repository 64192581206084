import { showAlert } from '../../helpers';

export const authsMutation = {
  NOT_ADMIN_MODAL() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Could not log in \nPlease check your login information'
        : 'ログインできませんでした\n ログイン情報をご確認ください',
      'top-center'
    );
  },
  UNAUTHORIZED_USER_MODAL() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Your username or password is incorrect.'
        : 'ユーザー名またはパスワードが違います。',
      'top-center'
    );
  },
  LOGIN(state, payload) {
    state.userInfo = payload;
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Login successfully'
        : 'ログインできました。',
      'top-center'
    );
  },
  UPDATE_PASSWORD(state, payload) {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Updated password successfully'
        : 'パスワードが正常に更新されました',
      'top-center'
    );
    setTimeout(() => {
      payload.onSuccessUpdate();
    }, 1500);
  },
  UPDATE_PASSWORD_ERROR(state, payload) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Old password (OTP) incorrect'
        : '古いパスワード（OTP）が間違っています',
      'top-center'
    );
  },
  UPDATE_SETTING_PASSWORD_SUCCESS(state, payload) {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Password changed'
        : 'パスワードを変更しました',
      'top-center'
    );
    state.updatePasswordSetting = payload;
  },
  UPDATE_SETTING_PASSWORD_ERROR(state, payload) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Could not change password! Please reconfirm'
        : 'パスワードを変更できませんでした再確認お願い致します',
      'top-center'
    );
    state.updatePasswordSetting = payload;
  }
};
