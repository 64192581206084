<template>
  <div class="pricing pt-[10px]">
    <div class="link notosans400 w-full h-[20px]">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div class="w-full title flex justify-start mt-[20px]">
      <h2 class="title-h2 notosans700 text-[28px] leading-[36px] text-center">
        {{ $t('common.pricing') }}
      </h2>
    </div>
    <div
      :class="{
        'renew-fee-note flex justify-start w-full mt-4 notosans400 text-[16px] text-[#475467]': true,
        'collapse-fee-note': statusCollapse
      }"
    >
      <div class="about-new-reward whitespace-nowrap">
        {{ $t('common.about_new_reward') }}
      </div>
      <div class="accoung-renew-fee text-center">
        {{ $t('common.account_renew_fee') }}
      </div>
    </div>
    <div class="container">
      <div class="item item-1 flex justify-center items-center bg-white">
        <div class="price" v-if="plansPackage?.length > 0">
          <span class="num notosans700 text-[28px] leading-[40px]"
            >¥{{ formatDollar(plansPackage[0]?.price / 1000) }}</span
          >
          <span class="time notosans400 text-[16px] leading-[24px]">{{
            $t('common.yen_per_user_per_month')
          }}</span>
        </div>
        <div class="desc w-full" v-if="plansPackage?.length > 0">
          <div
            class="sub_1 w-full text-center notosans400 text-[14px] leading-[22px]"
          >
            {{ t('common.to_add_a_user') }}
          </div>
          <div
            class="sub_2 w-full text-center notosans400 text-[14px] leading-[22px]"
          >
            {{ t('common.click_on_the_contact_us_button') }}
          </div>
        </div>
        <button
          v-if="plansPackage?.length > 0"
          class="fill-primary-text-white notosans700 w-[300px] h-[46px]"
          v-on:click="handleContact"
        >
          {{ t('common.contact_us') }}
        </button>
        <div
          v-if="plansPackage?.length <= 0 && !loading"
          class="flex justify-center items-center"
        >
          <EmptyData :showText="false" />
          <span class="uppercase notosans500">{{ $t('common.no_data') }}</span>
        </div>
        <div
          v-if="plansPackage?.length <= 0 && loading"
          class="w-[300px] flex justify-start items-center flex-col gap-[20px]"
        >
          <a-skeleton-input
            style="width: 250px"
            :active="active"
            :size="large"
          />
          <div
            class="w-full flex justify-center items-center flex-col max-h-[80px] overflow-hidden"
          >
            <a-skeleton :paragraph="{ rows: 1 }" style="width: 250px" />
          </div>
          <a-skeleton-input
            style="width: 300px"
            :active="active"
            :size="large"
          />
        </div>
      </div>
      <div class="item item-2 px-[20px] py-[24px] bg-white">
        <span class="title notosans700 text-[20px] text-[#1D2939]">{{
          $t('common.current_plan')
        }}</span>
        <span class="lineSpace"></span>
        <div
          class="item2-container detail flex justify-start items-center w-full min-h-[60px] gap-[12px]"
        >
          <div
            class="col1 flex-1 flex flex-col justify-start items-center gap-[8px]"
          >
            <span class="subtitle text-[#667085] text-[16px] notosans400">
              {{ $t('common.current_total_account') }}
            </span>
            <span class="notosans700 text-[20px] text-[#1D2939]">
              {{ totalUserCanCreate }}
            </span>
          </div>
          <span class="line relative w-[1px] h-[40px] bg-[#D0D5DD]"></span>
          <div
            class="col2 flex-1 flex flex-col justify-start items-center gap-[8px]"
          >
            <span class="subtitle text-[#667085] text-[16px] notosans400">
              {{ $t('common.active_accounts') }}
            </span>
            <span class="notosans700 text-[20px] text-[#1D2939]">
              {{ usersLen }}
            </span>
          </div>
          <span class="line relative w-[1px] h-[40px] bg-[#D0D5DD]"></span>
          <div
            class="col3 flex-1 flex flex-col justify-start items-center gap-[8px]"
          >
            <span class="subtitle text-[#667085] text-[16px] notosans400">
              {{ $t('common.remaining_accounts') }}
            </span>
            <span class="notosans700 text-[20px] text-[#1D2939]">
              {{ totalUserCanCreate - usersLen }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- NgocNa -->
    <div
      class="list-account-container border-[1px] border-[#D0D5DD] bg-white mt-6 mb-4 rounded-lg w-full"
    >
      <div
        class="list-account-header pt-5 pb-6 text-[#1D2939] text-[20px] notosans700 text-center border-b-[1px] border-b-[#D0D5DD]"
      >
        {{ $t('common.account_update') }}
      </div>
      <div
        class="check-note-account-renew px-5 text-[16px] text-[#1D2939] notosans400 py-6"
      >
        {{ $t('common.note_check_account_renew') }}
      </div>
      <div
        class="list-account-table min-h-[250px] max-h-[500px] px-5 overflow-auto scroll-bar"
      >
        <div class="flex gap-x-4 gap-y-6 flex-wrap pb-5" v-if="!loading">
          <!-- <AccountRenewItem
            v-for="item in listActiveAccount"
            :key="item._id"
            :accountInfo="item"
            :onPushSelectedUser="updateSelectedList"
            :isExpired="isExpired"
          /> -->
          <AccountRenewItemV1
            v-for="item in listActiveAccount"
            :key="item._id"
            :accountInfo="item"
            :onPushSelectedUser="updateSelectedList"
            :isExpired="isExpired"
            :isFirstDayOfNextMonth="isFirstDayOfNextMonth"
          />
        </div>
        <div
          class="w-full flex items-center justify-center h-full"
          v-else-if="loading"
        >
          <a-spin :indicator="indicator" size="large" />
        </div>
        <div
          class="flex items-center justify-center min-h-[200px]"
          v-if="listActiveAccount?.length == 0 && !loading"
        >
          <EmptyDataV2 />
        </div>
      </div>
      <div
        class="list-account-footer p-5 border-t-[1px] border-t-[#D0D5DD] flex justify-end"
        v-if="!loading"
      >
        <div
          :class="{
            'cursor-pointer px-[44px] py-[10px] bg-[#F38744] notosans500 text-[16px] text-white rounded-lg': true,
            '!bg-[#D0D5DD] !cursor-not-allowed':
              isExpired || listActiveAccount?.length === 0,
            '!bg-[#fdb182]': renewLoading
          }"
          @click="showRenewContract"
        >
          {{ $t('common.keep') }}
        </div>
      </div>
    </div>
    <!-- end NgocNa -->
  </div>
</template>
<script setup>
import moment from 'moment';
import { computed, h, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {
  addFormBuyUser,
  formatDate,
  formatDollar,
  renewModal
} from '../../helpers';
import EmptyData from '../master/EmptyData.vue';
import AccountRenewItem from './components/AccountRenewItem.vue';
import FormBuyUser from './components/FormBuyUser.vue';
import RenewContractModal from './components/RenewContractModal.vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import EmptyDataV2 from '../master/EmptyDataV2.vue';
import AccountRenewItemV1 from './components/AccountRenewItemV1.vue';
const { t } = useI18n();
const store = useStore();
const loading = ref(true);
const renewLoading = ref(false);
const routes = ref([
  {
    path: '/',
    breadcrumbName: t('common.home')
  },
  {
    path: '/pricing',
    breadcrumbName: t('common.pricing')
  }
]);
const selectedUserIds = ref([]);
const unSelectedPackageIds = ref([]);
//compare expired day
const isExpired = computed(() => {
  const currentDay = moment().date();
  const currentMonth = moment().month();
  const firstDayOfNextMonth = moment()
    .month(currentMonth + 1)
    .startOf('month');

  return currentDay > 20 && moment().isBefore(firstDayOfNextMonth);
  // return true;
});
const isFirstDayOfNextMonth = computed(() => {
  const today = moment();
  const firstDayOfNextMonth = moment().add(1, 'month').startOf('month');
  return today.isSame(firstDayOfNextMonth, 'day');
});

const statusCollapse = computed(() => store.state.collapseSidebar);
const plansPackage = computed(() =>
  store.state.pricingList ? store.state.pricingList : []
);
const selectedStore = computed(() =>
  store.state.selectedShop ? store.state.selectedShop : null
);
const percentageUserCreated = ref(0);
const usersLen = computed(() => {
  return store.getters.userListLen();
});
const totalUserCanCreate = computed(() =>
  store.state.totalUserCanCreate ? store.state.totalUserCanCreate : 0
);
const listActiveAccount = computed(
  () => store.getters.filterActiveAccounts || []
);
const calculatedUserCanCreate = () => {
  const width = (usersLen.value / totalUserCanCreate.value) * 100;
  if (width <= 100 && totalUserCanCreate.value !== 0) {
    percentageUserCreated.value = width;
  } else {
    percentageUserCreated.value = 100;
  }
};
watch([totalUserCanCreate, usersLen], () => {
  calculatedUserCanCreate();
});
const handleContact = () => {
  addFormBuyUser(
    FormBuyUser,
    plansPackage[0]?.price ? plansPackage[0]?.price : 11,
    plansPackage.value[0]
  );
};
//NgocNa
const showRenewContract = () => {
  if (isExpired.value) {
    return;
  }
  const startDate = new Date();
  isExpired.value || renewLoading.value
    ? ''
    : renewModal(RenewContractModal, () => {
        localStorage.setItem('userChange', true);
        renewLoading.value = true;
        setTimeout(
          () =>
            selectedUserIds.value?.length > 0 ||
            unSelectedPackageIds.value?.length > 0
              ? store.dispatch('renewUserAccount', {
                  title: plansPackage?.value[0]?.title || '',
                  price: plansPackage?.value[0]?.price || 0,
                  number_user: 1,
                  shop: selectedStore.value?.store_id,
                  note: plansPackage?.value[0]?.note || '',
                  status: plansPackage?.value[0]?.status || '',
                  type: plansPackage?.value[0]?.type || '',
                  days: plansPackage?.value[0]?.days || 0,
                  packagePrice: plansPackage?.value[0]?._id || '',
                  start_date: startDate,
                  user: selectedUserIds.value,
                  arrDelete: unSelectedPackageIds.value
                })
              : store.commit('NO_ACCOUNT_SELECTED'),
          500
        );
        setTimeout(() => (unSelectedPackageIds.value = []), 550);
        setTimeout(() => (renewLoading.value = false), 600);
        setTimeout(() => window.location.reload(), 1100);
      });
};

const updateSelectedList = (userId, packageId, isSelected) => {
  if (isSelected) {
    selectedUserIds.value.push(userId);
    unSelectedPackageIds.value = unSelectedPackageIds.value.filter(
      (id) => id !== packageId
    );
  } else {
    unSelectedPackageIds.value.push(packageId);
    selectedUserIds.value = selectedUserIds.value.filter((id) => id !== userId);
  }
};
const checkFirstDayOfNextMonth = () => {
  if (
    isFirstDayOfNextMonth.value ||
    localStorage.getItem('userChange') === 'false' ||
    localStorage.getItem('userChange') === null
  ) {
    localStorage.setItem('userChange', 'false');
  }
};

const handleOnFirstDayOfNextMonth = () => {
  if (
    localStorage.getItem('userChange') == 'false'
  ) {
    listActiveAccount.value?.map((item) => {
      console.log("update");
      updateSelectedList(item.id, '', true);
    });
  }
};
onMounted(() => {
  if (selectedStore.value?.store_id) {
    store.dispatch('getUserList', selectedStore?.value?.store_id);
  }
  handleOnFirstDayOfNextMonth();
  calculatedUserCanCreate();
  store.dispatch('getTotalUserCanCreate', {
    time: formatDate(new Date().setUTCDate(new Date().getUTCDate() + 1)),
    shopId: selectedStore?.value?.store_id
  });
  store.dispatch('getPricingList', selectedStore.value?.store_id);
  checkFirstDayOfNextMonth();
  setTimeout(() => {
    loading.value = false;
  }, 500);
});
watch(totalUserCanCreate, () => {
  handleOnFirstDayOfNextMonth();
});
//spin indicator
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '60px',
    color: 'rgba(239, 104, 32, 1)'
  },
  spin: true
});
</script>
<style scoped lang="scss">
.pricing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  // height: calc(100vh - 60px);
  padding-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--brg-gray-color);
  .container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
    min-height: 211px;
    // max-height: 30vh;
    .item-1 {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-width: 400px;
      gap: 20px;
      height: 100%;
      border-radius: 12px;
      padding: 20px 24px;
      border: 1px solid rgba(208, 213, 221, 1);
      .num {
        color: rgba(29, 41, 57, 1);
      }
      .time {
        color: rgba(102, 112, 133, 1);
      }
      .desc {
        .sub_1,
        .sub_2 {
          color: rgba(102, 112, 133, 1);
        }
      }
    }
    .item-2 {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      min-width: 400px;
      min-height: 85px;
      gap: 24px;
      width: 100%;
      border-radius: 12px;
      border: 1px solid rgba(208, 213, 221, 1);
      .lineSpace {
        position: relative;
        width: 100%;
        border-bottom: 1px solid rgba(208, 213, 221, 1);
      }
      .line {
        transition: all 0s !important;
      }
      .col1,
      .col2,
      .col3 {
        min-height: 85px;
        .subtitle {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          max-width: 200px;
          max-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          white-space: nowrap;
          + span {
            height: 25px;
          }
        }
      }
    }
  }
}
.renew-fee-note {
  @media only screen and (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .item-2 {
    .item2-container {
      flex-direction: column;
      .line {
        width: 60% !important;
        height: 1px !important;
        transition: all 0s !important;
      }
      .col1,
      .col2,
      .col3 {
        min-height: 0px !important;
      }
    }
  }
  .item-1,
  .item-2 {
    min-width: 70% !important;
    height: max-content !important;
    .col1,
    .col2,
    .col3 {
      height: 130px;
    }
    button {
      width: 100%;
    }
  }
}
</style>
