import axiosConfig from '../../config/axiosConfig';
import { getToken } from '../../helpers/token';

export const usersAction = {
  async getUserList(context, shopId) {
    try {
      const res = await axiosConfig.get(`/user-of-shop?shop=${shopId}&status[]=active&status[]=pending`);
      context.commit('GET_USER_LIST', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getUserListPending(context, shopId) {
    try {
      const res = await axiosConfig.get(`/user-of-shop?shop=${shopId}&status[]=pending&status[]=disable`);
      context.commit('GET_USER_LIST_PENDING', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveUserInfo(context, data) {
    try {
      const res = await axiosConfig.post('/user-of-shop', data?.userData);
      if (
        (res.status === 200 || res.status === 201) &&
        !res.data.errorResponse && !res.data.response
      ) {
        context.commit('SAVE_USER_INFO', res.data);
        data.onTurnOffLoad();
        data.onCloseForm();
      } else {
        context.commit('SAVE_USER_INFO_ERROR', res.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      data.onTurnOffLoad();
    }
  },

  async getUserDetails(context, userId) {
    try {
      const res = await axiosConfig.get(`/user-of-shop/${userId}`);
      context.commit('GET_USER_DETAILS', res.data);
    } catch (error) {
      context.commit('GET_USER_DETAILS', {});
      throw new Error(error);
    }
  },

  async updateUserInfo(context, data) {
    try {
      const res = await axiosConfig.put(
        `/user-of-shop/${data.userId}`,
        data.userInfo
      );
      console.log(res.status);
      if ((res.status === 200 || res.status === 201) && res.status !== 500 && !res.data.errorResponse && !res.data.response){
        context.commit('UPDATE_USER_INFO', res.data)
      }else{
        context.commit('UPDATE_USER_INFO_ERROR');
      }
    } catch (error) {
      context.commit('UPDATE_USER_INFO_ERROR');
    } finally {
      data.onTurnOffLoad();
    }
  },

  async updateUserAvatar(context, data) {
    try {
      const res = await axiosConfig.put(
        `/user-of-shop/${data.userId}`,
        data.userInfo
      );
      res.status === 200
        ? context.commit('UPDATE_USER_INFO', res.data)
        : context.commit('UPDATE_USER_INFO_ERROR', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteUserAvatar(context, data) {
    try {
      const res = await axiosConfig.put(
        `/user-of-shop/${data.userId}`,
        data.userInfo
      );
      res.status === 200
        ? context.commit('DELETE_USER_AVATAR', res.data)
        : context.commit('DELETE_USER_AVATAR_ERROR', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteUser(context, userId) {
    try {
      const res = await axiosConfig.delete(`/user-of-shop/${userId}`);
      res.status === 200
        ? context.commit('DELETE_USER', { _id: userId })
        : context.commit('DELETE_USER_ERROR');
    } catch (error) {
      throw new Error(error);
    }
  },

  async getTotalUserCanCreate(context, data) {
    try {
      const res = await axiosConfig.get(
        `/user-of-shop?status[]=active&status[]=pending&shop=${data.shopId}`
      );
      if (res.status === 200 | res.status === 201 | res.data) {
        context.commit('GET_TOTAL_SLOT_USERS', res.data?.length);
      } else {
        context.commit('GET_TOTAL_SLOT_USERS', 0);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async deletePermanentlyUsers(context, data) {
    try {
      const res = await axiosConfig.delete('/user-of-shop/delete-forever', {
        data
      });
      res.status === 200 || res.status === 201
        ? context.commit('DELETE_PERMANENTLY_USERS', data.ids)
        : context.commit('DELETE_PERMANENTLY_USERS_ERROR');
    } catch (error) {
      throw new Error(error);
    }
  },

  async getUserInfo(context, userId) {
    try {
      const token = getToken();
      if (token) {
        const res = await axiosConfig({
          url: `/auth/profile`,
          method: 'get',
          token
        });
        context.commit('GET_USER_INFO', res.data);
      }
    } catch (error) {
      context.commit('GET_USER_INFO', {});
      throw new Error(error);
    }
  },

  async getUserDeleted(context, shopId) {
    try {
      const res = await axiosConfig.get(
        `/user-of-shop?shop=${shopId}&status=disable`
      );
      res.status === 200 || res.status === 201
        ? context.commit('GET_USER_DELETED', res.data)
        : null;
    } catch (error) {
      throw new Error(error);
    }
  },
};
