import 'ant-design-vue/dist/reset.css';
import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/bases/_base.css';
import i18n from './i18n';
import store from './store';

import {
  Breadcrumb,
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Pagination,
  Popconfirm,
  Radio,
  RadioButton,
  Select,
  Skeleton,
  Spin,
  Table,
  Tag
} from 'ant-design-vue';
import router from './router/router';

const app = createApp(App);
app.use(ConfigProvider);
app.use(i18n);
app.config.productionTip = false;
app.use(store);

app.use(Select);
app.use(Button);
app.use(Input);
app.use(Form);
app.use(Table);
app.use(Tag);
app.use(Popconfirm);
app.use(InputNumber);
app.use(RadioButton);
app.use(Radio);
app.use(DatePicker);
app.use(Breadcrumb);
app.use(Pagination);
app.use(Skeleton);
app.use(Spin);

app.use(router).mount('#app');
