<template>
  <div class="wrap" v-if="selectedShop">
    <div class="left" v-if="selectedShop">
      <SideBar />
    </div>
    <div class="right">
      <div class="box-header" v-if="selectedShop">
        <Header />
      </div>
      <div
        :class="{
          main: true,
          'pl-[0px]': statusHide,
          'pl-[80px]': statusCollapse && !statusHide,
        }"
        id="main"
      >
        <Main />
      </div>
    </div>
  </div>
  <div class="wrap-two" v-else>
    <keep-alive :include="cachedComponents">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, onUnmounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Header from "./components/master/Header";
import Main from "./components/master/Main";
import SideBar from "./components/master/SideBar";
import { getToken } from "./helpers/token";

const store = useStore();
const route = useRoute();
const router = useRouter();

const MAX_WIDTH_COLLAPSE_SIDEBAR = 1000;
const MAX_WIDTH_HIDE_SIDEBAR = 500;
const statusCollapse = computed(() => store.state.collapseSidebar);
const statusHide = computed(() => store.state.hideSidebar);
const selectedShop = computed(() => store.state.selectedShop);

const updateWindowWidth = () => {
  const screenW = window.innerWidth;
  if (screenW < MAX_WIDTH_HIDE_SIDEBAR) {
    if (statusCollapse.value === false) {
      handleUpdateStatusCollapseSideBar(true);
      localStorage.setItem("responsive_sidebar", "true");
    }
    if (
      statusHide.value === false &&
      localStorage.getItem("hide_sidebar_action_open") === "false"
    ) {
      handleUpdateStatusHideSideBar(true);
    }
  } else {
    if (statusHide.value === true) {
      handleUpdateStatusHideSideBar(false);
    }
    localStorage.setItem("hide_sidebar_action_open", "false");
    if (screenW < MAX_WIDTH_COLLAPSE_SIDEBAR && statusCollapse.value === false) {
      handleUpdateStatusCollapseSideBar(true);
      localStorage.setItem("responsive_sidebar", "true");
    } else if (
      screenW >= MAX_WIDTH_COLLAPSE_SIDEBAR &&
      localStorage.getItem("responsive_sidebar") === "true"
    ) {
      handleUpdateStatusCollapseSideBar(false);
      localStorage.setItem("responsive_sidebar", "false");
    }
  }
};

const handleUpdateStatusCollapseSideBar = (status) => {
  store.dispatch("setStatusCollapseSidebar", status);
};

const handleUpdateStatusHideSideBar = (status) => {
  store.dispatch("setStatusHideSidebar", status);
};

onBeforeMount(() => {
  store.dispatch("getUserInfo");
});

const handleRouteChange = (path) => {
  if (path === "/stores" || path === "/login" || path === "/login/change_password") {
    store.dispatch("setSelectedShop", null);
  }
  if (!path.includes("/user")) {
    localStorage.removeItem("pageNumberUserActive");
    localStorage.removeItem("pageNumberUserDeletedActive");
  }
  flowLoginAccess();
};

watch(
  () => route.path,
  (newPath) => {
    handleRouteChange(newPath);
  }
);

const flowLoginAccess = async () => {
  await router.isReady();
  let currentPathObject = router.currentRoute.value;
  if (!getToken()) {
    router.push("/login");
  } else {
    const shopSelected = localStorage.getItem("selectedShop");
      if (shopSelected) {
        if (shopSelected !== "null") {
          if (currentPathObject?.fullPath !== "/") {
            router.push(currentPathObject?.fullPath);
          }
        } else if (!currentPathObject?.fullPath.includes("/login/change_password")) {
          router.push("/stores");
        }
      } else {
        router.push("/stores");
      }
  }
};

onMounted(() => {
  updateWindowWidth();
  window.addEventListener("resize", updateWindowWidth);
  flowLoginAccess();
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});
</script>
<style>
@import "./assets/css/views/_main.scss";
</style>
