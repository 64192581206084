<template>
  <div
    :class="{
      'dashboard-container  px-6 pt-[10px] bg-[#F9FAFB] pb-3 scroll-bar h-[calc(100vh-60px)]': true,
      collapseSidebar: isCollaspseSideBar,
      hideSidebar: isHideSidebar
    }"
  >
    <div class="dashboard-breadcrumb">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes?.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div
      class="dashboard-label flex justify-between mt-5 gap-2 items-center flex-wrap"
    >
      <div
        class="label-left text-[#101828] font-bold text-[24px] leading-[34px] xs:w-[100vw] xs:text-center sm:text-[18px] notosans700"
      >
        {{ $t('common.dashboard') }}
      </div>
      <div
        class="label-right flex items-center flex-wrap xs:w-[100vw] xs:justify-center gap-2"
      >
        <div
          class="dashboard-picker flex border-[1px] border-[#D0D5DD] rounded-lg"
        >
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('decrease')"
          >
            <span v-html="previousIcon"></span>
          </button>

          <div v-if="toggleDateMonth" class="label-time-picker cursor-pointer">
            <a-date-picker
              :format="dateFormat"
              :allowClear="false"
              v-model:value="dayValue"
              @change="handleDatePickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <div v-else class="cursor-pointer">
            <a-date-picker
              :format="monthFormat"
              picker="month"
              :allowClear="false"
              v-model:value="monthValue"
              @change="handleDatePickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('increase')"
          >
            <span v-html="nextIcon"></span>
          </button>
        </div>
        <div
          class="time-button flex bg-white rounded-lg border-[1px] border-solid border-[#D0D5DD]"
        >
          <button
            class="day px-[19px] py-[10px] transition-all duration-300 text-[#F38744]"
            :class="toggleDateMonth ? 'isDayActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.day') }}
          </button>
          <button
            class="month px-[19px] py-[10px] transition-all duration-300 text-[#F38744]"
            :class="!toggleDateMonth ? 'isMonthActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.month') }}
          </button>
        </div>
      </div>
    </div>
    <div
      :class="{
        'dashboard-analytical-data-by-month grid grid-cols-2 md:grid-cols-4 gap-6 mt-5 xs:grid-cols-1': true
      }"
    >
      <AnalyticalItem
        v-for="item in dataAnalytical.value"
        :key="item"
        :data="item"
      />
    </div>
    <div class="dashboard-livestream flex mt-6 gap-6">
      <div
        class="dashboard-livestream w-[50%] xs:w-full sm:w-[100%] h-[500px] bg-white rounded-lg border-[1px] border-[#EAECF0]"
      >
        <div
          class="dashboard-livestream-table-view-title text-[#98A2B3] py-3 px-6 text-[14px] leading-[22px] font-normal xs:text-[12px] xs:py-2 overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <strong
            class="text-[#475467] font-bold text-[20px] leading-8 mr-2 xs:text-[15px]"
            >{{ $t('common.live_time') }}</strong
          >({{ $t('common.update_daily') }})
        </div>
        <LiveTableItem :data="numberOfViewerState" />
      </div>
      <div
        class="dashboard-livestream w-[50%] xs:w-full sm:w-[100%] h-[500px] bg-white rounded-lg border-[1px] border-[#EAECF0]"
      >
        <div
          class="dashboard-livestream-table-view-title text-[#98A2B3] py-3 px-6 text-[14px] leading-[22px] font-normal xs:text-[12px] xs:py-2 overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <strong
            class="text-[#475467] font-bold text-[20px] leading-8 mr-2 xs:text-[15px]"
            >{{ $t('common.point') }}</strong
          >({{ $t('common.update_daily') }})
        </div>
        <LiveTableItem :data="numberOfGiftingState" />
      </div>
    </div>
    <!-- <div
      class="dashboard-user-ranking mt-6 px-6 bg-white rounded-lg border-[#EAECF0] border-[1px] mb-6 xs:mb-6 w-full"
    >
      <div
        class="dashboard-user-ranking-title text-[#98A2B3] py-4 text-[14px] leading-[22px] xs:text-[12px]"
      >
        <strong
          class="text-[#475467] font-bold text-[20px] leading-8 xs:text-[15px]"
          >{{ $t('common.new_user') }}</strong
        >
        ({{ $t('common.update_weekly') }})
      </div>
      <div class="dashboard-user-ranking-carousel flex pb-6 xs:pb-3">
        <div
          class="dashboard-user-ranking-left-btn py-[45px] cursor-pointer mr-2"
          v-html="ArrowLeftIcon"
          @click="prevSlide"
        ></div>
        <div
          class="dashboard-user-ranking-middle-btn w-full overflow-hidden text-black px-2 flex"
          ref="carouselRef"
        >
          <NewUserCarouselItem :rankingUser="newUsersView" />
        </div>
        <div
          class="dashboard-user-ranking-right-btn py-[45px] cursor-pointer ml-2"
          @click="nextSlide"
          v-html="ArrowRightIcon"
        ></div>
      </div>
    </div> -->
  </div>
</template>
<script>
import dayjs from 'dayjs';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import "dayjs/locale/ja"
import moment from 'moment';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarFilledIcon,
  LiveCoinIcon,
  LiveViewerIcon,
  LivestreamIcon,
  PasswordShowIcon,
  UserProfileIcon,
  nextIcon,
  previousIcon
} from '../../assets/constant/constant';
import { formatPoint, getCurrentDate, getCurrentMonth } from '../../helpers';
import AnalyticalItem from './components/AnalyticalItem.vue';
import LiveTableItem from './components/LiveTableItem.vue';
import NewUserCarouselItem from './components/NewUserCarouselItem.vue';
dayjs.locale('ja')
export default {
  components: { AnalyticalItem, LiveTableItem, NewUserCarouselItem },
  name: 'Dashboard',
  setup(props) {
    const toggleDateMonth = ref(true);
    const store = useStore();
    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
    };
    const { t } = useI18n();
    const dateFormat = 'YYYY年M月D日';
    const monthFormat = 'YYYY年M月';
    const currentDay = ref(dayjs(getCurrentDate(), 'YYYY-MM-DD'));
    const currentMonth = ref(dayjs(getCurrentMonth(), 'YYYY-MM'));
    const dayValue = ref(currentDay);
    const monthValue = ref(currentMonth);
    const totalUser = computed(() => {
      return Number(store?.state?.userList?.length);
    });
    const numberOfGifting = computed(() => {
      return store?.getters?.giftingNumberSortedGetters;
    });
    const handleDatePickerChange = () => {
      localStorage.setItem(
        'current-day',
        moment(dayValue.value.$d).format('YYYY-MM-DD')
      );
      store.dispatch(
        'getDashboardViewList',
        moment(dayValue.value.$d).format('YYYY-MM-DD')
      );
    };
    watch([dayValue, monthValue], async () => {
      handleDatePickerChange();
    });
    onMounted(() => {
      let localCurrentDay = localStorage.getItem('current-day');
      if (localCurrentDay && !store?.state?.numberOfGifting) {
        store.dispatch('getDashboardViewList', localCurrentDay);
      } else if (!localCurrentDay && !store?.state.numberOfGifting) {
        store.dispatch('getDashboardViewList', currentDay.value);
      }
      if (
        store?.state.selectedShop?.store_id &&
        (!store?.state.userList || store?.state?.userList?.length == 0)
      ) {
        store.dispatch('getUserList', store?.state.selectedShop?.store_id);
      }
    });
    const newUsersView = computed(() => {
      return store?.getters?.newUserSortedGetters?.map((item) => {
        return {
          ...item,
          avatar: require('../../assets/images/other/avatar-1.jpg')
        };
      });
    });
    const numberOfGiftingState = computed(() =>
      numberOfGifting?.value?.map((item) => {
        return {
          ...item,
          icon: LiveCoinIcon,
          avatar: require('../../assets/images/other/avatar-1.jpg')
        };
      })
    );
    const numberOfViewerState = computed(() =>
      numberOfGifting?.value?.map((item) => {
        return {
          ...item,
          icon: PasswordShowIcon,
          avatar: require('../../assets/images/other/avatar-1.jpg')
        };
      })
    );
    //calculate
    const sumUserCoin = computed(() => {
      let sum = 0;
      store.state.numberOfGifting?.forEach((element) => {
        sum += element.totalPoints;
      });
      return formatPoint(sum);
    });
    const sumUserViewer = computed(() => {
      let sum = 0;
      store.state.numberOfGifting?.forEach((element) => {
        sum += element.totalPoints;
      });
      return formatPoint(sum);
    });
    //Top analytical item
    const AnalyticalDataByDay = ref([
      {
        number: totalUser,
        icon: UserProfileIcon,
        iconBackground: 'bg-[#EFF8FF]',
        subtitle: t('common.user_person')
      },
      {
        number: 0,
        icon: LivestreamIcon,
        iconBackground: 'bg-[#ECFDF3]',
        subtitle: t('common.live_time')
      },
      {
        number: sumUserViewer,
        icon: LiveViewerIcon,
        iconBackground: 'bg-[#F9F5FF]',
        subtitle: t('common.number_of_viewers')
      },
      {
        number: sumUserCoin,
        icon: LiveCoinIcon,
        iconBackground: 'bg-[#FEFBE8]',
        subtitle: t('common.point')
      }
    ]);
    const AnalyticalDataByMonth = ref([
      {
        number: totalUser.value,
        icon: UserProfileIcon,
        iconBackground: 'bg-[#EFF8FF]',
        subtitle: t('common.user_person')
      },
      {
        number: 0,
        icon: LivestreamIcon,
        iconBackground: 'bg-[#ECFDF3]',
        subtitle: t('common.live_time')
      },
      {
        number: sumUserViewer,
        icon: LiveViewerIcon,
        iconBackground: 'bg-[#F9F5FF]',
        subtitle: t('common.number_of_viewers')
      },
      {
        number: sumUserCoin,
        icon: LiveCoinIcon,
        iconBackground: 'bg-[#FEFBE8]',
        subtitle: t('common.point')
      }
    ]);
    //carousel
    const carouselRef = ref(null);
    const currentIndex = ref(0);
    const scrollToCurrentIndex = () => {
      const container = carouselRef.value;
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth * currentIndex.value;
      container.scrollTo({
        left: scrollAmount,
        behavior: 'smooth'
      });
    };
    const prevSlide = () => {
      if (currentIndex.value > 0) {
        currentIndex.value--;
        scrollToCurrentIndex();
      }
    };

    const nextSlide = () => {
      if (currentIndex.value < store.getters.newUserSortedGetters?.length - 1) {
        currentIndex.value++;
        scrollToCurrentIndex();
      }
    };
    const routes = ref([
      {
        path: '/',
        breadcrumbName: t('common.home')
      },
      {
        path: '/dashboard',
        breadcrumbName: t('common.dashboard')
      }
    ]);
    //custom function
    const handleIncreaseOrDecreaseDateTime = (type) => {
      switch (toggleDateMonth.value) {
        case true:
          type == 'increase'
            ? (dayValue.value = dayValue.value.add(1, 'day'))
            : (dayValue.value = dayValue.value.subtract(1, 'day'));
          break;
        case false:
          monthValue.value =
            type == 'increase'
              ? (monthValue.value = monthValue.value.add(1, 'month'))
              : (monthValue.value = monthValue.value.subtract(1, 'month'));
          break;
      }
    };
    return {
      carouselRef,
      routes,
      currentIndex,
      handleDatePickerChange,
      prevSlide,
      nextSlide,
      dateFormat,
      monthFormat,
      dayValue,
      monthValue,
      CalendarFilledIcon,
      numberOfViewerState,
      dataAnalytical: computed(() => {
        return toggleDateMonth.value
          ? AnalyticalDataByDay
          : AnalyticalDataByMonth;
      }),
      numberOfGiftingState,
      isCollaspseSideBar: computed(() => store.state.collapseSidebar),
      isHideSidebar: computed(() => store.state?.hideSidebar),
      newUsersView,
      AnalyticalDataByDay,
      AnalyticalDataByMonth,
      toggleDateMonth,
      handleToggleDateMonthClick,
      //svg
      ArrowLeftIcon,
      ArrowRightIcon,
      PasswordShowIcon,
      UserProfileIcon,
      LivestreamIcon,
      LiveViewerIcon,
      LiveCoinIcon,
      nextIcon,
      previousIcon,
      //custom function
      handleIncreaseOrDecreaseDateTime,
      //date locale
      locale
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/views/dashboard';
@import '../../assets/css/views/ant_custom';
</style>
