<template>
  <div
    class="success-modal-container relative bg-white w-[600px] gap-5 h-[192px] xs:h-[230px] flex items-center flex-col rounded-[20px] py-[40px] xs:mx-2"
  >
    <div class="closeButton absolute top-6 right-6 cursor-pointer">
      <span v-html="CloseIcon" @click="handleOnClose"> </span>
    </div>
    <div
      class="reset-header w-full text-center notosans600 text-[20px] leading-[20px]"
    >
      {{ $t('common.resetting_password') }}
    </div>
    <div class="success-modal-message xs:w-[250px]">
      user@gmail.com
      {{ $t('common.send_message') }}
    </div>
    <div
      class="success-modal-warning text-[#101828] xs:w-[250px] xs:flex xs:flex-col xs:gap-2"
    >
      {{ $t('common.not_recieve') }}
      <span
        class="text-[#EF6820] notosans700 text-[15px] leading-[20px] cursor-pointer"
        @click="handleResend"
      >
        {{ $t('common.click_to_resend') }}
      </span>
    </div>
  </div>
</template>
<script>
import { CloseIcon } from '../../../assets/constant/constant';
export default {
  name: 'SuccessModal',
  props: {
    onClose: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const handleOnClose = () => {
      props.onClose();
    };
    const handleResend = () => {
      console.log('Click to resend email');
    };
    return {
      CloseIcon,
      handleOnClose,
      handleResend
    };
  }
};
</script>
<style lang="">
</style>