<template>
  <div
    :class="{
      'report-data-detail-container px-6 py-[10px] h-[100vh]': true,
      collapseSidebar: isCollaspseSideBar,
      hideSidebar: isHideSidebar
    }"
  >
    <div class="report-data-detail-breadcrumb">
      <a-breadcrumb :routes="routes" separator=">">
        <template #itemRender="{ route, routes }">
          <span
            class="notosans400 link-focus"
            v-if="routes.indexOf(route) === routes.length - 1"
          >
            {{ route.breadcrumbName }}
          </span>
          <router-link class="notosans400" v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>
    <div
      class="report-data-detail-header flex justify-between items-center mt-[20px]"
    >
      <div
        class="report-header-left text-[#101828] text-[24px] leading-[36px] font-bold"
      >
        {{ $t('common.data_analysis') }}
        {{ toggleDateMonth ? monthValueHeader : yearValueHeader }}
      </div>
      <div class="report-header-right flex gap-2">
        <div v-if="toggleDateMonth" class="label-time-picker cursor-pointer">
          <a-date-picker
            :format="monthFormat"
            :allowClear="false"
            picker="month"
            v-model:value="monthValue"
            @change="handleMonthPickerChange"
            :locale="locale"
          >
            <template #suffixIcon>
              <span v-html="CalendarFilledIcon"></span>
            </template>
          </a-date-picker>
        </div>
        <div v-else class="cursor-pointer">
          <a-date-picker
            picker="year"
            :allowClear="false"
            v-model:value="yearValue"
            @change="handleYearPickerChange"
            :locale="locale"
          >
            <template #suffixIcon>
              <span v-html="CalendarFilledIcon"></span>
            </template>
          </a-date-picker>
        </div>
        <div
          class="time-button flex bg-white rounded-lg border-[1px] border-solid border-[#D0D5DD]"
        >
          <button
            class="day px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD]"
            :class="toggleDateMonth ? 'isDayActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.month') }}
          </button>
          <button
            class="month px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD]"
            :class="!toggleDateMonth ? 'isMonthActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.year') }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="report-data-detail-day notosans700 text-[#182230] text-[18px] leading-6 mt-6"
    >
      {{ chosenDay }}
    </div>
    <div class="report-data-detail-body overflow-auto mt-4">
      <table>
        <tr>
          <th>{{ $t('common.user') }}</th>
          <th>{{ $t('common.time') }}</th>
          <th>{{ $t('common.live_time') }}</th>
          <th>{{ $t('common.gifting_number') }}</th>
          <th>{{ $t('common.number_of_viewers') }}</th>
          <th>{{ $t('common.new_follower') }}</th>
          <th>{{ $t('common.comment') }}</th>
          <th>{{ $t('common.number_of_shares') }}</th>
        </tr>
        <tr v-for="item in detailData" :key="item">
          <td>{{ item.user }}</td>
          <td>{{ item.time || 0 }}</td>
          <td>{{ item.livestream_duration || '00:00:00' }}</td>
          <td>{{ item.gifting_number || 0 }}</td>
          <td>{{ item.number_of_viewers || 0 }}</td>
          <td>{{ item.new_follower || 0 }}</td>
          <td>{{ item.comment || 0 }}</td>
          <td>{{ item.number_of_shares || 0 }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import DataTable from './components/DataTable.vue';
import { CalendarFilledIcon } from '../../assets/constant/constant';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import { getCurrentDate, getCurrentMonth } from '../../helpers';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import 'dayjs/locale/ja';
dayjs.locale('ja');
export default {
  components: { DataTable },
  name: 'ReportDataDetail',
  setup() {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const route = useRoute();
    const id = ref(route.params.id);
    const { t } = useI18n();
    const toggleDateMonth = ref(true);
    const dayFormat = 'YYYY/MM/DD';
    const monthFormat = 'YYYY/MM';
    const currentMonth = ref(dayjs(getCurrentMonth(), 'YYYY-MM'));
    const currentYear = ref(dayjs(getCurrentDate(), 'YYYY'));
    const dayValue = ref('');
    const monthValue = ref(currentMonth);
    const yearValue = ref(currentYear);
    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
    };
    const dateFormatJA = (dayValue) => {
      return moment(dayValue).format('YYYY年MM月DD日');
    };
    const dateFormatEN = (dayValue) => {
      return moment(dayValue).format('YYYY/MM/DD');
    };
    const languageToggle = localStorage.getItem('lang') || 'ja';
    const detailData = [
      {
        user: 'ハルト',
        time: '',
        livestream_duration: '',
        gifting_number: '',
        number_of_viewers: '',
        new_follower: '',
        comment: '',
        number_of_shares: ''
      },
      {
        user: 'ポチ',
        time: '',
        livestream_duration: '',
        gifting_number: '',
        number_of_viewers: '',
        new_follower: '',
        comment: '',
        number_of_shares: ''
      },
      {
        user: 'タマ',
        time: '',
        livestream_duration: '',
        gifting_number: '',
        number_of_viewers: '',
        new_follower: '',
        comment: '',
        number_of_shares: ''
      },
      {
        user: 'Tony',
        time: '',
        livestream_duration: '',
        gifting_number: '',
        number_of_viewers: '',
        new_follower: '',
        comment: '',
        number_of_shares: ''
      },
      {
        user: 'Mikasa',
        time: '',
        livestream_duration: '',
        gifting_number: '',
        number_of_viewers: '',
        new_follower: '',
        comment: '',
        number_of_shares: ''
      },
      {
        user: 'KIZARU',
        time: '',
        livestream_duration: '',
        gifting_number: '',
        number_of_viewers: '',
        new_follower: '',
        comment: '',
        number_of_shares: ''
      }
    ];
    let monthValueHeader = ref(
      languageToggle === 'ja'
        ? `(${moment(monthValue.value.$d).format('M月')})`
        : `(${months[moment(monthValue).format('M') - 1]})`
    );
    let yearValueHeader = ref(`(${moment(yearValue).format('YYYY年')})`);
    const handleMonthPickerChange = () => {
      languageToggle === 'ja'
        ? (monthValueHeader.value = `(${moment(monthValue.value.$d).format(
            'M月'
          )})`)
        : (monthValueHeader.value = `(${
            months[moment(monthValue.value.$d).format('M') - 1]
          })`);
    };
    const handleYearPickerChange = () => {
      yearValueHeader.value = `(${moment(yearValue.value.$d).format(
        'YYYY年'
      )})`;
    };
    const routes = ref([
      {
        path: '/',
        breadcrumbName: t('common.home')
      },
      {
        path: '/data_analysis',
        breadcrumbName: t('common.data_analysis')
      },
      {
        path: '/data_analysis',
        breadcrumbName:
          languageToggle === 'ja'
            ? dateFormatJA(id.value)
            : dateFormatEN(id.value)
      }
    ]);
    const store = useStore();
    const isCollaspseSideBar = computed(() => store.state.collapseSidebar);
    return {
      chosenDay: computed(() =>
        languageToggle === 'ja'
          ? dateFormatJA(id.value)
          : dateFormatEN(id.value)
      ),
      routes,
      isCollaspseSideBar,
      isHideSidebar: computed(() => store.state?.hideSidebar),
      dayFormat,
      monthFormat,
      dayValue,
      monthValue,
      yearValue,
      detailData,
      toggleDateMonth,
      CalendarFilledIcon,
      monthValueHeader,
      yearValueHeader,
      handleMonthPickerChange,
      handleYearPickerChange,
      handleToggleDateMonthClick,
      //locale
      locale
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/views/_ant_custom.scss';

.link-focus {
  color: var(--primary-color) !important;
}
.report-data-detail-container {
  font-family: 'NotoSansJP_600';
  width: calc(100vw - 240px);
}
.collapseSidebar {
  width: calc(100vw - 80px);
}
.hideSidebar {
  width: 100vw !important;
}
.isDayActive {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  background-color: #f38744;
  color: white;
}
.isMonthActive {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #f38744;
  color: white;
}
.report-data-detail-body {
  border-radius: 8px;
}
table {
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;
}

td,
th {
  border-bottom: 1px solid #d0d5dd;
  text-align: left;
  padding: 12px;
  color: #475467;
}
th {
  text-align: center;
  background-color: #eaecf0;
  font-family: 'NotoSansJP_700';
  border-bottom: none;
  white-space: nowrap;
  font-size: 14px;
  @media (min-width: 720px) and (max-width: 980px) {
    min-width: 100px;
  }
}
td {
  max-width: 100px;
  text-align: center;
  font-family: 'NotoSansJP_400';
  color: black;
  white-space: nowrap;
  border-bottom: 1px solid #d0d5dd;
}
td:nth-child(1) {
  font-weight: 700;
  color: #475467;
}
tr:nth-child(even) {
  background-color: #ffffff;
}
// tr:nth-child(odd) {
//   background-color: #f9fafb;
// }
.report-data-detail-header {
  @media (max-width: 550px) {
    display: block;
    .report-header-left {
      text-align: center;
    }
    .report-header-right {
      justify-content: center;
      margin-top: 10px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>
