import { showAlert } from '../../helpers';

export const pricingsMutation = {
  GET_PRICING_LIST(state, payload) {
    state.pricingList = payload;
  },

  UPGRATE_PRICING(state, payload) {
    state.userListPending.filter((item) => {
      return !payload?.userIds?.some((i) => item.id === i.id);
    });
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'User have been successfully activated'
        : 'ユーザーは正常に有効化されました',
      'top-center'
    );
    setTimeout(() => {
      payload?.onSuccess();
    }, 1000);
  },

  UPGRATE_PRICING_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Users activation failed'
        : 'ユーザーのアクティベーションに失敗しました',
      'top-center'
    );
  },
  RENEW_ACCOUNT_SUCCESS() {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Account renewal successful'
        : 'アカウントの更新が成功しました',
      'top-center'
    );
  },
  RENEW_ACCOUNT_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Account renewal failed'
        : 'アカウントの更新に失敗しました',
      'top-center'
    );
  },
  NO_ACCOUNT_SELECTED() {
    showAlert(
      'warning',
      localStorage.getItem('lang') === 'en'
        ? 'No account selected'
        : 'アカウントが選択されていません',
      'top-center'
    );
    localStorage.setItem('userChange', false);
  },
  GET_RENEW_PRICING(state, payload) {
    state.renewPricings = payload;
  }
};
