<template>
  <div
    :class="{
      'user-report-tab-container rounded-lg bg-white pb-5 h-screen': true,
      collapseSidebar: isCollaspseSideBar,
      hideSidebar: isHideSidebar
    }"
  >
    <div class="table-header flex justify-between items-center px-[20px] py-4">
      <div
        class="table-header-left text-title-info-text font-bold text-[18px] leading-[24px] notosans700"
      >
        {{ $t('common.report') }}
        {{ toggleDateMonth ? monthValueHeader : yearValueHeader }}
      </div>
      <div class="table-header-right flex gap-2">
        <div
          class="report-tab-picker flex border-[1px] border-[#D0D5DD] rounded-lg"
        >
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('decrease')"
          >
            <span v-html="previousIcon"></span>
          </button>

          <div v-if="toggleDateMonth" class="label-time-picker cursor-pointer">
            <a-date-picker
              :format="monthFormat"
              picker="month"
              :allowClear="false"
              v-model:value="monthValue"
              @change="handleMonthPickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <div v-else class="cursor-pointer">
            <a-date-picker
              :format="yearFormat"
              picker="year"
              :allowClear="false"
              v-model:value="yearValue"
              @change="handleYearPickerChange"
              :locale="locale"
            >
              <template #suffixIcon>
                <span></span>
              </template>
            </a-date-picker>
          </div>
          <button
            class="p-3 rounded-lg scale-125"
            @click="handleIncreaseOrDecreaseDateTime('increase')"
          >
            <span v-html="nextIcon"></span>
          </button>
        </div>

        <div
          class="time-button flex bg-white rounded-lg border-[1px] border-solid border-[#D0D5DD]"
        >
          <button
            class="day px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] notosans400 whitespace-nowrap"
            :class="toggleDateMonth ? 'isDayActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.month') }}
          </button>
          <button
            class="month px-[19px] py-[10px] transition-all duration-300 text-[#D0D5DD] notosans400 whitespace-nowrap"
            :class="!toggleDateMonth ? 'isMonthActive' : ''"
            @click="handleToggleDateMonthClick"
          >
            {{ $t('common.year') }}
          </button>
        </div>
      </div>
    </div>
    <div class="table-body overflow-auto">
      <table>
        <tr>
          <th>{{ $t('common.date') }}</th>
          <th v-if="!toggleDateMonth">{{ $t('common.streaming_times') }}</th>
          <th>{{ $t('common.live_time') }}</th>
          <th>{{ $t('common.gifting_number') }}</th>
          <th>{{ $t('common.number_of_viewers') }}</th>
          <th>{{ $t('common.comment') }}</th>
          <th>{{ $t('common.new_follower') }}</th>
          <th>{{ $t('common.number_of_shares') }}</th>
        </tr>
        <!-- <tr
          v-if="toggleDateMonth"
          v-for="(item, index) in reportDataByDay"
          :key="item"
        >
          <td v-if="languageToggle === 'ja'">0</td>
          <td v-else>0</td>
          <td>{{ item.livestream_duration || '00:00:00' }}</td>
          <td>{{ item.gifting || 0 }}</td>
          <td>{{ item.number_of_viewers || 0 }}</td>
          <td>{{ item.comment || 0 }}</td>
          <td>{{ item.new_follower || 0 }}</td>
          <td>{{ item.share || 0 }}</td>
        </tr>
        <tr
          v-else
          v-for="(item, index) in reportDataByMonth"
          :key="item.streaming_times"
        >
          <td v-if="languageToggle === 'ja'">0</td>
          <td v-else>0</td>
          <td>{{ item.streaming_times || 0 }}</td>
          <td>{{ item.livestream_duration || '00:00:00' }}</td>
          <td>{{ item.gifting || 0 }}</td>
          <td>{{ item.number_of_viewers || 0 }}</td>
          <td>{{ item.comment || 0 }}</td>
          <td>{{ item.new_follower || 0 }}</td>
          <td>{{ item.share || 0 }}</td>
        </tr> -->
      </table>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import moment from 'moment';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { CalendarFilledIcon } from '../../../assets/constant/svg_exports';
import { getCurrentDate, getCurrentMonth } from '../../../helpers';
import { nextIcon, previousIcon } from '../../../assets/constant/constant';
import 'dayjs/locale/ja';
dayjs.locale('ja');
export default {
  name: 'UserReportTab',
  setup() {
    const toggleDateMonth = ref(true);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const currentMonth = ref(dayjs(getCurrentMonth(), 'YYYY-MM'));
    const currentYear = ref(dayjs(getCurrentDate(), 'YYYY'));
    const monthValue = ref(currentMonth);
    const yearValue = ref(currentYear);
    const monthFormat = 'YYYY年MM月';
    const yearFormat = 'YYYY年';
    const handleToggleDateMonthClick = () => {
      toggleDateMonth.value = !toggleDateMonth.value;
    };
    const languageToggle = localStorage.getItem('lang') || 'ja';
    let monthValueHeader = ref(
      languageToggle === 'ja'
        ? `(${moment(monthValue.value.$d).format('YYYY年M月')})`
        : `(${months[moment(monthValue).format('M') - 1]})`
    );
    let yearValueHeader = ref(`(${moment(yearValue).format('YYYY年')})`);
    // const formatTime = (time) => {
    //   if (time < 6) return time;
    //   const hour = time.slice(0, 2);
    //   const minutes = time.slice(2, 2);
    //   const second = time.slice(4, 2);
    //   return `${hour}:${minutes}:${second}`;
    // };
    // const formatNumber = (number) => {
    //   if (number < 3) return number;
    //   if (number >= 3 && number <= 6) {
    //   }
    // };
    const handleMonthPickerChange = () => {
      languageToggle === 'ja'
        ? (monthValueHeader.value = `(${moment(monthValue.value.$d).format(
            'YYYY年M月'
          )})`)
        : (monthValueHeader.value = `(${
            months[moment(monthValue.value.$d).format('M') - 1]
          })`);
    };
    const handleYearPickerChange = () => {
      yearValueHeader.value = `(${moment(yearValue.value.$d).format(
        'YYYY年'
      )})`;
    };
    const reportDataByDay = [];
    const reportDataByMonth = [];
    for (let i = 0; i < 1; i++) {
      reportDataByDay.push({
        livestream_duration: '',
        gifting: '',
        number_of_viewers: '',
        comment: '',
        new_follower: '',
        share: ''
      });
    }
    for (let i = 0; i < 1; i++) {
      reportDataByMonth.push({
        streaming_times: '',
        livestream_duration: '',
        gifting: '',
        number_of_viewers: '',
        comment: '',
        new_follower: '',
        share: ''
      });
    }
    const store = useStore();
    const isCollaspseSideBar = computed(() => store.state.collapseSidebar);
    //custom function
    const handleIncreaseOrDecreaseDateTime = (type) => {
      switch (toggleDateMonth.value) {
        case true:
          type == 'increase'
            ? (monthValue.value = monthValue.value.add(1, 'month'))
            : (monthValue.value = monthValue.value.subtract(1, 'month'));
          handleMonthPickerChange();
          break;
        case false:
          monthValue.value =
            type == 'increase'
              ? (yearValue.value = yearValue.value.add(1, 'year'))
              : (yearValue.value = yearValue.value.subtract(1, 'year'));
          handleYearPickerChange();
          break;
      }
    };
    return {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      toggleDateMonth,
      CalendarFilledIcon,
      languageToggle,
      monthValue,
      yearValue,
      yearFormat,
      monthFormat,
      isCollaspseSideBar,
      isHideSidebar: computed(() => store.state?.hideSidebar),
      handleToggleDateMonthClick,
      reportDataByDay,
      reportDataByMonth,
      monthValueHeader,
      yearValueHeader,
      //custom function
      handleMonthPickerChange,
      handleYearPickerChange,
      handleIncreaseOrDecreaseDateTime,
      //svg
      previousIcon,
      nextIcon,
      //locale
      locale
    };
  }
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/views/_user_report_table.scss';
</style>