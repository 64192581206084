import { createRouter, createWebHistory } from 'vue-router';
import ChangePassLogin from '../components/views/ChangePassLogin.vue';
import Dashboard from '../components/views/Dashboard.vue';
import Login from '../components/views/Login.vue';
import Point from '../components/views/Point.vue';
import Pricing from '../components/views/Pricing.vue';
import ReportData from '../components/views/ReportData.vue';
import ReportDataDetail from '../components/views/ReportDataDetail.vue';
import Setting from '../components/views/Setting.vue';
import StoreChangePasswordTab from '../components/views/StoreChangePasswordTab.vue';
import StoreDetailSettingTab from '../components/views/StoreDetailSettingTab.vue';
import User from '../components/views/User.vue';
import UserPointDetailByCategoryTab from '../components/views/UserDetailComponent/Point/UserPointDetailByCategoryTab.vue';
import UserPointDetailTab from '../components/views/UserDetailComponent/Point/UserPointDetailTab.vue';
import UserGiftTab from '../components/views/UserDetailComponent/UserGiftTab.vue';
import UserReportTab from '../components/views/UserDetailComponent/UserReportTab.vue';
import UserDetailPending from '../components/views/UserDetailPending.vue';
import UserDetails from '../components/views/UserDetails.vue';
import WelcomeStore from '../components/views/WelcomeStore.vue';
import Withdrawal from '../components/views/Withdrawal.vue';
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login/change_password',
    name: 'ChangePassLogin',
    component: ChangePassLogin
  },
  {
    path: '/:pathMatch(.*)*',
    component: Dashboard
  },
  {
    path: '/stores',
    name: 'WelcomeStore',
    component: WelcomeStore
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/user/details/active',
    name: 'UserDetailsActive',
    component: UserDetails
  },
  {
    path: '/user/details/pending',
    name: 'UserDetailsPending',
    component: UserDetailPending
  },
  {
    path: '/report',
    name: 'UserReportTab',
    component: UserReportTab
  },
  {
    path: '/gifting',
    name: 'UserGiftingTab',
    component: UserGiftTab
  },
  {
    path: '/data_analysis',
    name: 'ReportData',
    component: ReportData
  },
  {
    path: '/data_analysis_detail/:id',
    name: 'ReportDataDetail',
    component: ReportDataDetail
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting
  },
  {
    path: '/setting/store_detail_setting',
    name: 'StoreDetailSettingTab',
    component: StoreDetailSettingTab
  },
  {
    path: '/store_password_setting',
    name: 'StoreChangePasswordTab',
    component: StoreChangePasswordTab
  },
  {
    path: '/user/user_point_detail',
    name: 'UserPointDetailTab',
    component: UserPointDetailTab
  },
  {
    path: '/user/user_point_detail/category',
    name: 'UserPointDetailByCategoryTab',
    component: UserPointDetailByCategoryTab
  },
  {
    path: '/point',
    name: 'Point',
    component: Point
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash'
});

export default router;

// router version 3
// {
//   path: '/user/history-deleted',
//   name: 'UserDeleted',
//   component: UserDeleted
// },
// {
//   path: '/user/history-deleted/details',
//   name: 'UserDeletedDetails',
//   component: UserDeletedDetails
// },