<template>
    <div :class="styles.detailWithdrawal">
      <div :class="styles.title">
        <span class="h-[35px] text-[24px] notosans700 text-[#1D2939]">{{ $t("common.withdrawal_application_information") }}</span>
      </div>
      <div :class="styles.infoBank">
        <span :class="`${styles.subTitle} text-[18px] notosans700 text-[#1D2939]`">
          {{ $t("common.withdrawal_bank_account") }}
        </span>
        <div :class="styles.item">
          <span :class="styles.key">{{ $t("common.bank_name") }}</span>
          <span :class="styles.value">-</span>
        </div>
        <div :class="styles.item">
          <span :class="styles.key">{{ $t("common.branch_name") }}</span>
          <span :class="styles.value">-</span>
        </div>
        <div :class="styles.item">
          <span :class="styles.key">{{ $t("common.account_number") }}</span>
          <span :class="styles.value">-</span>
        </div>
        <div :class="styles.item">
          <span :class="styles.key">{{ $t("common.name_of_holder") }}</span>
          <span :class="styles.value">-</span>
        </div>
      </div>
      <span :class="styles.line"></span>
      <div :class="styles.infoPayment">
        <span :class="`${styles.subTitle} text-[18px] notosans700 text-[#1D2939]`">
          {{ $t("common.withdrawal_details") }}
        </span>
        <div :class="styles.item">
          <span :class="styles.key">{{ $t("common.withdrawal_amount") }}</span>
          <span :class="styles.value">¥0</span>
        </div>
        <div :class="styles.item">
          <span :class="styles.key">{{ $t("common.fee") }}</span>
          <span :class="styles.value">¥0</span>
        </div>
        <div :class="styles.item">
            <span :class="styles.key">{{ $t("common.withdrawal_date") }}</span>
            <span :class="styles.value">-</span>
          </div>
      </div>
      <span :class="styles.line"></span>
      <div :class="`${styles.totalPayment} ${styles.item}`">
        <span :class="styles.key">{{ $t("common.total") }}</span>
        <span :class="`${styles.value} notosans700 text-[16px] text-[#EF6820]`">¥0</span>
      </div>
      <div :class="styles.buttons">
        <button :class="`${styles.btnCancel}`" v-on:click="handleClose">{{ $t("common.cancel_delete") }}</button>
      </div>
    </div>
  </template>
  <script setup>
  import styles from "../../../assets/css/views/_history_withdrawal.module.scss";
  const props = defineProps({
      onClose: {
      type: Function,
      required: true,
    },
  })
  const handleClose = () => {
      props.onClose();
  }
  </script>  
  <style lang="scss" scoped></style>
  